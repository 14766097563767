import ParagraphBlock from "./src/ParagraphBlock";
import ParagraphBlockTransforms from "./src/ParagraphBlockTransforms";
import BoldLeafRendererComponent from "../CoreStyles/BoldLeafRendererComponent";
import ItalicLeafRendererComponent from "../CoreStyles/ItalicLeafRendererComponent";
import UnderlineLeafRendererComponent from "../CoreStyles/UnderlineLeafRendererComponent";
import CodeLeafRendererComponent from "../CoreStyles/CodeLeafRendererComponent";

const SlateParagraphBlockComponent = {
    author: 'Rob Reinhard',
    block: ParagraphBlock,
    decorators: undefined,
    default: true,
    defaultResetHandler: ParagraphBlockTransforms.resetToParagraphBlock,
    dependencyTypes: undefined,
    leafRenderers: [BoldLeafRendererComponent.type, ItalicLeafRendererComponent.type, UnderlineLeafRendererComponent.type, CodeLeafRendererComponent.type],
    name: 'Paragraph Block',
    onDrop: false,
    onKeyDown: (editor, event) => {
        if (event.key === "Enter" && event.shiftKey) {
            ParagraphBlockTransforms.insertParagraphBlock(editor, event);
        }
    },
    plugins: undefined,
    resetToDefault: undefined,
    toolbar: undefined,
    transforms: undefined,
    type: 'paragraph'
};

export default SlateParagraphBlockComponent;