import SlateBitJokeBlockComponent from "./SlateJokeComponents/SlateBitJokeBlockComponent";
import BoldLeafRendererComponent
    from "../../../rob-horse/reusable-components/Slate-Editors/Custom-Components/CoreStyles/BoldLeafRendererComponent";
import ItalicLeafRendererComponent
    from "../../../rob-horse/reusable-components/Slate-Editors/Custom-Components/CoreStyles/ItalicLeafRendererComponent";
import UnderlineLeafRendererComponent
    from "../../../rob-horse/reusable-components/Slate-Editors/Custom-Components/CoreStyles/UnderlineLeafRendererComponent";
import SlateJokeEditorBlockComponent from "./SlateJokeComponents/SlateJokeEditorBlockComponent";

const BitSlateComponents = [
    BoldLeafRendererComponent,
    ItalicLeafRendererComponent,
    UnderlineLeafRendererComponent,
    SlateJokeEditorBlockComponent,
    SlateBitJokeBlockComponent,
];

export default BitSlateComponents;