import React, {useState, useCallback, useEffect} from 'react';
import {useHistory} from 'react-router-dom';
import {Transforms} from 'slate';
import AddPostBase from '../../../rob-horse/reusable-components/Add-Edit-View-Default-Bases/AddPostBase';
import {Box, Button, Step, Typography} from '@mui/material';
import SetlistDto from '../../includes/models/SetlistDto';
import PostDto from '../../../includes/models/PostDto';
import {getSetlistDtoByHashId} from '../../includes/api-functions/SetlistDtoCalls';
import setlistService from '../Setlist/setlistService';
import Stepper from '@mui/material/Stepper';
import StepLabel from '@mui/material/StepLabel';
import FileUploaderViewer from "../../../rob-horse/reusable-components/Material-File-Viewers/FileUploaderViewer";
import PerformanceDto from "../../includes/models/PerformanceDto";
import DefinePerformance from "./components/DefinePerformance";
import StagingSetlistDto from "../../includes/models/StagingSetlistDto";
import PerformanceSlateComponents from "../slate-js/PerformanceSlateComponents";
import performanceService from "./performanceService";
import PerformanceAdditionalProperties from "./components/PerformanceAdditionalProperties";
import FileDto from "../../../includes/models/FileDto";
import {postPerformanceDto} from "../../includes/api-functions/PerformanceDtoCalls";
import {INewPostProps} from "../../../rob-horse/contentTypeMapper";
import useSlateEditor from "../../../rob-horse/reusable-components/Slate-Editors/useSlateEditorState";

const AddPerformance = (props: INewPostProps) => {
  const {contentTypeSlug} = props;
  const [performanceDto, setPerformanceDto] = useState<PerformanceDto>(new PerformanceDto());
  const [slateContent, setSlateContent] = useState<any>([]);
  const history = useHistory();
  const [allowSubmission, setAllowSubmission] = useState<boolean>(false);
  const [stepIndex, setStepIndex] = useState<number>(0);

  const ADD_PERFORMANCE_STEPS: Step[] = [
    {
      key: 'ADD_SETLIST',
      label: 'Add Setlist',
      disabled: () => performanceDto.setlistDto === null
    },
    {
      key: 'ADD_FILE',
      label: 'Add File',
      optional: true
    },
    {
      key: 'WRAP_UP',
      label: 'Wrap Up'
    }
  ];

  const [editor] = useSlateEditor(PerformanceSlateComponents);

  useEffect(() => {
    setPerformanceDto(new PerformanceDto({contentTypeSlug}));
  }, [contentTypeSlug]);

  const onContentChange = (newEditorStateValue: any) => {
    if (performanceDto.setlistDto) {
      const newSetlistDto = performanceService.updateJokePerformances(performanceDto.setlistDto, newEditorStateValue);

      setPerformanceDto((prevState) => ({
        ...prevState,
        setlistDto: newSetlistDto
      }));
    }
  };

  const goToEditPage = useCallback((setlistDto:SetlistDto) =>
    history.push(`/edit/${setlistDto.hashId}`),
  [history],
  );

  const handleChange = (e:any) => {
    const {name, value} = e.target;
    setPerformanceDto((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const publishSetlistButtonClicked = async () => {
    setAllowSubmission(true);
  };

  useEffect(() => {
    if (allowSubmission && performanceDto.setlistDto !== null) {
      postPerformanceDto(performanceDto, goToEditPage, handleError);
    }
  }, [allowSubmission, performanceDto]);

  const handleError = (e: Response) => {
    console.log(e.status);
  };

  const addPost = async (hashId: string, contentTypeSlug: string) => {
    if (contentTypeSlug === 'setlist') {
      await getSetlistDtoByHashId(hashId, onPostAddCallback, handleError);
    }
  };

  const clearPost = () => {
    setPerformanceDto((prevState) => ({
      ...prevState,
      setlistDto: null,
    }));
  }

  const onPostAddCallback = (postDto: PostDto) => {
    if (postDto.contentTypeSlug === 'setlist') {
      const setlistDto = setlistService.convertStagingSetlistDtoToSetlistDto(postDto as StagingSetlistDto);

      setPerformanceDto((prevState) => ({
        ...prevState,
        setlistDto: setlistDto,
      }));
    }
  };

  useEffect(() => {
    let content: any[];

    if (performanceDto.setlistDto !== null) {
      content = setlistService.createSetlistContent(performanceDto.setlistDto);
    }
    else {
      content = [];
    }

    setSlateContent(content);
  }, [performanceDto]);

  const isBackButtonAvailable = stepIndex > 0;
  const isNextButtonAvailable = stepIndex < ADD_PERFORMANCE_STEPS.length - 1;

  const onBackButtonClicked = () => {
    if (isBackButtonAvailable) {
      Transforms.deselect(editor);
      setStepIndex(stepIndex - 1)
    }
  };

  const onNextButtonClicked = () => {
    if (isNextButtonAvailable) {
      Transforms.deselect(editor);
      setStepIndex(stepIndex + 1)
    }
  };

  const onDrop = (acceptedFiles: File[]) => {
    if (acceptedFiles.length == 0) {
      return;
    }

    setPerformanceDto((prevState) => ({
      ...prevState,
      fileDto: new FileDto({
        file: acceptedFiles[0]
      })
    }));
  }

  useEffect(() => {
    if (performanceDto.setlistDto) {
      const content = setlistService.createSetlistContent(performanceDto.setlistDto, undefined, true);

      setSlateContent(content);
    }
  }, [performanceDto]);

  let disabled: boolean;
  if (ADD_PERFORMANCE_STEPS[stepIndex].disabled !== undefined) {
    disabled = ADD_PERFORMANCE_STEPS[stepIndex].disabled();
  } else {
    disabled = false;
  }

  return (
    <AddPostBase
      allowSubmission={allowSubmission}
      contentTypeName="Performance"
      handleChange={handleChange}
      postDto={performanceDto}
      setPostDto={setPerformanceDto}
      publishPostButtonClicked={publishSetlistButtonClicked}
    >
      <Stepper
        activeStep={stepIndex}
        sx={{
          mt: 1,
          mb: 2
        }}
      >
        {ADD_PERFORMANCE_STEPS.map((step) => {
          const labelProps: {
            optional?: React.ReactNode;
          } = {};

          if (step.optional) {
            labelProps.optional = (
              <Typography variant="caption">Optional</Typography>
            );
          }

          return (
            <Step key={step.key}>
              <StepLabel {...labelProps}>{step.label}</StepLabel>
            </Step>
          )
        })}
      </Stepper>
      <Typography
        variant="h5"
        component="h3"
        sx={{
          mb: 2
        }}
      >
        {`Step ${stepIndex + 1}: ${ADD_PERFORMANCE_STEPS[stepIndex].label}`}
      </Typography>
      {ADD_PERFORMANCE_STEPS[stepIndex].key === 'ADD_SETLIST' &&
        <DefinePerformance
          addPost={addPost}
          clearPost={clearPost}
          content={slateContent}
          editor={editor}
          onContentChange={onContentChange}
        />
      }
      {ADD_PERFORMANCE_STEPS[stepIndex].key === 'ADD_FILE' &&
      <FileUploaderViewer
          accept={["audio/*", "video/*"]}
          multiple={false}
          file={performanceDto.fileDto !== null ? performanceDto.fileDto.file : null}
          initialUpload
          placeholder="Drag 'n' Drop or click to upload an audio/video file"
          onDropCallback={onDrop}
      />
      }
      {ADD_PERFORMANCE_STEPS[stepIndex].key === 'WRAP_UP' &&
        <PerformanceAdditionalProperties
            handleChange={handleChange}
            location={performanceDto.location}
            comments={performanceDto.comments}
        />
      }
      <Box
        sx={{
          mt: 2,
          display: 'block',
          alignItems: "center",
          justifyContent: "space-between"
        }}
      >
        {isBackButtonAvailable &&
          <Button
            sx={{
              float: 'left'
            }}
            variant="contained"
            onClick={onBackButtonClicked}
          >
            Back
          </Button>
        }
        {isNextButtonAvailable &&
          <Button
            disabled={disabled}
            sx={{
              float: 'right'
            }}
            variant="contained"
            onClick={onNextButtonClicked}
          >
            Next
          </Button>
        }
      </Box>
    </AddPostBase>
  );
};

export default AddPerformance;
