import React, {useCallback} from 'react';
import Chip from '@mui/material/Chip';
import {alpha, ChipProps} from '@mui/material';
import {useHistory} from 'react-router-dom';
import TermGroupingDto from "../../../includes/models/TermGroupingDto";
import {useTheme} from "@mui/material/styles";

interface TermGroupingChipProps extends ChipProps {
    readonly?: boolean,
    onTermDelete: Function,
    size?: 'medium' | 'small'
    termGroupingDto: TermGroupingDto
};

export const TermGroupingChip = (props: TermGroupingChipProps) => {
  const {
    termGroupingDto
  } = props;

  const theme = useTheme();

  const history = useHistory();

  const onDelete = () => {
    props.onTermDelete(termGroupingDto);
  };


  const clickPostRow = useCallback(() =>
    history.push(`/groupings/${termGroupingDto.slug}`),
  [history],
  );

  const chipRef = (ref: HTMLDivElement | null) => {
    if (!ref) {
      return;
    }

    if (!props.readonly) {
      return;
    }

    ref.onclick = (event) => {
      event.stopPropagation(); // you use a ref here to prevent onCellClicked from bubbling up
      clickPostRow();
    };
  }

  return (
    <Chip
      {...props}
      ref={chipRef}
      label={termGroupingDto.name}
      onClick={props.readonly ? clickPostRow : undefined}
      onDelete={props.readonly ? undefined : onDelete}
      sx={{
        margin: '5px',
        borderRadius: 0,
        color: termGroupingDto.color ? theme.palette.getContrastText(termGroupingDto.color) : theme.palette.text.primary,
        backgroundColor: termGroupingDto.color || theme.palette.action.selected,
        ':hover': {
          backgroundColor: alpha(termGroupingDto.color || theme.palette.action.selected, .8)
        },
        ':active': {
          backgroundColor: alpha(termGroupingDto.color || theme.palette.action.selected, .6)
        },
        zIndex: 1000
      }}
    />);
};

export default TermGroupingChip;
