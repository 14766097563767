import React from "react";

import { fetchWrapper } from "../api-authorization/helpers";
import TermGroupingDto from "../models/TermGroupingDto";
import TermGroupingQueryParameters from "../data-transfer-objects/TermGroupingQueryParameters";
import TermDto from "../models/TermDto";
import qs from "qs";

const getTermGroupingDtos = async (termGroupingQueryParameters: TermGroupingQueryParameters, onSuccessSetData: (value: React.SetStateAction<TermDto[]>) => void, onErrorCallback: (error: Response) => void) => {
    const searchTermParametersString = qs.stringify(termGroupingQueryParameters, { allowDots: true, encode: false });

    await fetchWrapper.getWithHeaders(`api/termGrouping?${searchTermParametersString}`).then(onSuccessSetData, onErrorCallback);
}

const getTermGroupingDto = async (termGroupingSlug: string, onSuccessSetData: (termGroupingDto: TermGroupingDto) => void, onErrorCallback: (error: Response) => void) => {
    await fetchWrapper.get(`api/termGrouping/${termGroupingSlug}`).then(onSuccessSetData,onErrorCallback);
}

const postTermGroupingDto = async (termGroupingDto: TermGroupingDto | undefined, onSuccessSetData: (value: React.SetStateAction<TermGroupingDto | undefined>) => void, onErrorCallback: (error: Response) => void) => {
    await fetchWrapper.post('api/termGrouping/', termGroupingDto).then(onSuccessSetData,onErrorCallback);
}

const putTermGroupingDto = async (termGroupingDto: TermGroupingDto | undefined, onErrorCallback: (error: Response) => void) => {
    await fetchWrapper.put('api/termGrouping/', termGroupingDto).then(undefined, onErrorCallback);
}

export {
    getTermGroupingDto,
    postTermGroupingDto,
    putTermGroupingDto,
    getTermGroupingDtos
}
