import {AddPostGeneric} from "./GenericPost/AddPostGeneric";
import {EditPostGeneric} from "./GenericPost/EditPostGeneric";
import {ViewPostGeneric} from "./GenericPost/ViewPostGeneric";
import AddFile from "./File/AddFile";
import EditFile from "./File/EditFile";
import ViewFile from "./File/ViewFile";
import AddJoke from "../Comedy/theme/Joke/AddJoke";
import {EditJoke} from "../Comedy/theme/Joke/EditJoke";
import {ViewJoke} from "../Comedy/theme/Joke/ViewJoke";
import AddBit from "../Comedy/theme/Bit/AddBit";
import {EditBit} from "../Comedy/theme/Bit/EditBit";
import {ViewBit} from "../Comedy/theme/Bit/ViewBit";
import AddSetlist from "../Comedy/theme/Setlist/AddSetlist";
import {EditSetlist} from "../Comedy/theme/Setlist/EditSetlist";
import {ViewSetlist} from "../Comedy/theme/Setlist/ViewSetlist";
import AddPerformance from "../Comedy/theme/Performance/AddPerformance";
import EditPerformance from "../Comedy/theme/Performance/EditPerformance";
import ViewPerformance from "../Comedy/theme/Performance/ViewPerformance";
import {FC} from "react";
import EditUserProfile from "./UserProfile/EditUserProfile";

export interface INewPostProps {
  contentTypeSlug: string;
}

export interface IExistingPageProps {
  hashId: string
};

interface AddEditView {
  add?: FC<INewPostProps>,
  edit?: FC<IExistingPageProps>,
  view?: FC<IExistingPageProps>
}

interface ContentTypeMapper {
  [contentTypeSlug: string] : AddEditView
}

const contentTypeMapper : ContentTypeMapper = {
  "richtext": {
    add: AddPostGeneric,
    edit: EditPostGeneric,
    view: ViewPostGeneric
  },
  "file": {
    add: AddFile,
    edit: EditFile,
    view: ViewFile
  },
  "joke": {
    add: AddJoke,
    edit: EditJoke,
    view: ViewJoke
  },
  "bit": {
    add: AddBit,
    edit: EditBit,
    view: ViewBit
  },
  "setlist": {
    add: AddSetlist,
    edit: EditSetlist,
    view: ViewSetlist
  },
  "performance": {
    add: AddPerformance,
    edit: EditPerformance,
    view: ViewPerformance
  },
  "user-profile": {
    edit: EditUserProfile
  }
};

export default contentTypeMapper;