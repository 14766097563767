import BoldLeafRendererComponent from "../CoreStyles/BoldLeafRendererComponent";
import ItalicLeafRendererComponent from "../CoreStyles/ItalicLeafRendererComponent";
import UnderlineLeafRendererComponent from "../CoreStyles/UnderlineLeafRendererComponent";
import CodeLeafRendererComponent from "../CoreStyles/CodeLeafRendererComponent";
import BlockQuoteLine from "./src/BlockQuoteLine";
import BlockQuoteTransforms from "./src/BlockQuoteTransforms";

const SlateBlockQuoteLineComponent = {
    author: 'Rob Reinhard',
    block: BlockQuoteLine,
    decorators: undefined,
    default: undefined,
    dependencyTypes: undefined,
    leafRenderers: [BoldLeafRendererComponent.type, ItalicLeafRendererComponent.type, UnderlineLeafRendererComponent.type, CodeLeafRendererComponent.type],
    name: 'List Item',
    onDrop: false,
    onKeyDown: undefined,
    plugins: undefined,
    resetToDefault: (editor, elementPath, defaultBlock) => {
        const blockQuotePath = elementPath.slice(0,-1);

        BlockQuoteTransforms.removeAndReplaceBlockQuote(editor, blockQuotePath, defaultBlock);
    },
    toolbar: undefined,
    transforms: undefined,
    type: 'block-quote-line'
};

export default SlateBlockQuoteLineComponent;