import { fetchWrapper } from "../../../includes/api-authorization/helpers";
import { cloneDeep } from 'lodash';
import JokeDto from "../models/JokeDto";
import SlateJSON from "../../../rob-horse/reusable-components/Slate-Editors/Custom-Components/SlateJSON";

export const postJokeDto = async (jokeDto: JokeDto, onSuccessToEditCallback: (jokeDto: JokeDto) => void, onErrorCallback: (error: Response) => void) => {
    const postToPublish = cloneDeep(jokeDto);

    postToPublish.legacyJSONSerializedSlateContent = JSON.stringify(jokeDto.slateContent);

    await fetchWrapper.post('api/comedy/joke', postToPublish, SlateJSON.stringify).then(onSuccessToEditCallback,onErrorCallback);
};

export const getJokeDtoByHashId = async (hashId: string, onSuccessCallback: (jokeDto: JokeDto) => void, onErrorCallback: (error: Response) => void) => {
    await fetchWrapper.get('api/comedy/joke/'+hashId).then(onSuccessCallback,onErrorCallback);
}

export const getJokeRevisionDtosByHashId = async (hashId: string, onSuccessCallback: (value: JokeDto[]) => void, onErrorCallback: (error: Response) => void) => {
    await fetchWrapper.get('api/comedy/joke/revisions/'+hashId).then(onSuccessCallback,onErrorCallback);
}