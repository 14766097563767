import React from 'react';
import Typography from '@mui/material/Typography';
import InputAdornment from '@mui/material/InputAdornment';
import {IconButton} from '@mui/material';
import KeyboardReturnIcon from '@mui/icons-material/KeyboardReturn';
import {PostTermsBlock} from './PostTermsBlock';
import TermDto from '../../../includes/models/TermDto';
import TermSearch from "../Websocket-Components/TermSearch";
import PostDto from "../../../includes/models/PostDto";

interface ITermSidebarProps {
    readonly?: boolean,
    setPostDto: (value: React.SetStateAction<any>) => void,
    termDtos: TermDto[],
}

export const TermSidebar = (props: ITermSidebarProps) => {
  const {
    readonly,
    setPostDto,
    termDtos,
  } = props;

  const onTermDeleted = (name: string) => {
    const termDtosMinusDeletedTerm = termDtos.filter((termDto) => termDto.name !== name);

    setPostDto((prevState: PostDto) => ({
      ...prevState,
      termDtos: termDtosMinusDeletedTerm,
    }));
  };

  const addTerm = (termDto: TermDto) => {
    setPostDto((prevState: PostDto) => ({
      ...prevState,
      termDtos: [
        ...prevState.termDtos,
        termDto,
      ],
    }));
  };

  return (
    <div key="placeholder-key">
      <Typography
        variant="h5"
        component="h2"
      >
        Tags
      </Typography>
      {!readonly &&
        <TermSearch
          addTerm={addTerm}
          searchLabel="Add Tag"
          textFieldProps={{
            InputProps: {
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    size="large">
                    <KeyboardReturnIcon/>
                  </IconButton>
                </InputAdornment>),
            }
          }}
        />
      }
      <PostTermsBlock
        readonly={readonly}
        onTermDeleted={onTermDeleted}
        value={termDtos}
        sx={{
          width: '100%',
          marginTop: '10px',
          marginBottom: '10px',
          minHeight: '75px'
        }}
      />
    </div>
  );
};

export default TermSidebar;
