import GenericPostDto from "../../includes/models/GenericPostDto";

const onSuccessfulGenericPost = (hashId: string, setGenericPostDto: (value: GenericPostDto) => void) => (newGenericPostDto: GenericPostDto)  => {
    const processedGenericPostDto = newGenericPostDto;

    if (processedGenericPostDto.hashId) {
        processedGenericPostDto.hashedParent = hashId;
        processedGenericPostDto.hashId = '';
    }

    if (processedGenericPostDto.slateContent === undefined) {
        processedGenericPostDto.slateContent = {
            type: "editor",
            children: JSON.parse(processedGenericPostDto.legacyJSONSerializedSlateContent)
        };
    }

    setGenericPostDto(new GenericPostDto(processedGenericPostDto));
};

const onSuccessfulGetPostRevisions = (setGenericPostDtoRevisions: (value: GenericPostDto[]) => void, setRevisionIndex: (value: number) => void) => (revisions: GenericPostDto[]) => {
    const processedRevisions = revisions;

    for (let revision of processedRevisions) {

        if (revision.slateContent === undefined) {
            revision.slateContent = {
                type: "editor",
                children: JSON.parse(revision.legacyJSONSerializedSlateContent)
            };
        }

        revision = new GenericPostDto(revision);
    }

    setGenericPostDtoRevisions(processedRevisions);
    setRevisionIndex(processedRevisions.length-1);
}

export {
    onSuccessfulGenericPost,
    onSuccessfulGetPostRevisions
}