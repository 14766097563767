import {Editor, Node, Path, Transforms} from "slate";
import SlateBlockQuoteLineComponent from "../SlateBlockQuoteLineComponent";

const defaultBlockQuoteLine = (textNode) => {
    return {
        type: "block-quote-line",
        children: textNode ? [textNode] : [
            {
                text: '',
            }
        ],
    }
}

const defaultBlockQuote = (textArrayToInsert) => {
    return {
        type: "block-quote",
        children: textArrayToInsert?.length > 0 ? textArrayToInsert.map((textNode) => defaultBlockQuoteLine(textNode)) : [
            defaultBlockQuoteLine()
        ],
    };
};

const insertBlockQuote = (editor) => {
    if (!editor.selection) {
        Transforms.select(editor, [editor.children.length-1]);
    }
    const leafNode = Node.get(editor, editor.selection.anchor.path);

    if (editor.selection.anchor.path.toString() === editor.selection.focus.path.toString()
        && editor.selection.anchor.offset === 0
        && editor.selection.focus.offset === 0
        && leafNode.text === '') {
        Transforms.setNodes(
            editor,
            defaultBlockQuote(),
            {
                match: n => Editor.isBlock(editor, n) && n.type !== SlateBlockQuoteLineComponent.type
            }
        );
    }
    else {
        const isSelectionFocusBeforeAnchor = Path.compare(editor.selection.focus.path, editor.selection.anchor.path) < 0;

        const options = {
            from: isSelectionFocusBeforeAnchor ? editor.selection.focus.path : editor.selection.anchor.path,
            to: isSelectionFocusBeforeAnchor ? editor.selection.anchor.path : editor.selection.focus.path
        };

        const selectedTextArray = Array.from(Node.texts(editor, options)).map(entry => entry[0]);

        Transforms.removeNodes(editor, options);

        Transforms.insertNodes(
            editor,
            defaultBlockQuote(selectedTextArray),
            {
                match: n => Editor.isBlock(editor, n) && n.type !== SlateBlockQuoteLineComponent.type
            }
        );
    }
}

const exitBlockQuote = (editor, path, escapedElement) => {
    const listPath = path.slice(0,-1);
    listPath[0] = listPath[0] + 1;
    Transforms.insertNodes(editor, escapedElement, {
        at: listPath
    });

    Transforms.select(editor, listPath);
}

const removeAndReplaceBlockQuote = (editor, path, escapedElement) => {
    Transforms.removeNodes(editor, {
        at: path
    });
    Transforms.insertNodes(editor, escapedElement, {at: path});
    Transforms.select(editor, path);
};

export default {
    insertBlockQuote,
    defaultBlockQuote,
    defaultBlockQuoteLine,
    removeAndReplaceBlockQuote,
    exitBlockQuote
};