import React, {useCallback} from 'react'
import Typography from "@mui/material/Typography";
import {Box} from "@mui/material";
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import { useDrag, useDrop } from 'react-dnd'
import {ItemTypes} from "../../BitWriter";
import { Divider } from '@mui/material';
import {ReactEditor, useSlate, useReadOnly} from "slate-react";
import {Transforms} from "slate";

const BitJokeBlock = (props) => {
    const editor = useSlate();
    const path = ReactEditor.findPath(editor, props.element);

    const onDrop = useCallback((item) => {
        const newPath = [...ReactEditor.findPath(editor, props.element)];

        if (newPath[newPath.length-1] <= item.path[newPath.length-1]) {
            newPath[newPath.length-1] += 1;
        }

        Transforms.moveNodes(editor, {
            at: item.path,
            to: newPath
        })
    }, [props.element]);

    const [, drag, preview] = useDrag(() => ({
        collect: monitor => ({
            isDragging: !!monitor.isDragging(),
        }),
        item: {
            path: ReactEditor.findPath(editor, props.element),
        },
        type: ItemTypes.JOKE,
    }), [path, props.element]);

    const [{ isOver }, drop] = useDrop(() => ({
        accept: ItemTypes.JOKE,
        drop: onDrop,
        collect: (monitor) => ({
            isOver: !!monitor.isOver(),
        }),
    }), [onDrop]);

    const readOnly = useReadOnly();

    return (
        <Box
            {...props.attributes}
        >
            <Box
                ref={preview}
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    direction: 'row'
                }}
            >
                <div
                    contentEditable={false}
                    ref={drag}
                    style={{
                        userSelect: 'none',
                        display: readOnly ? 'none' : 'inherit'
                    }}
                >
                    <Box
                        sx={{
                            alignItems: 'center',
                            display: 'flex',
                            justifyContent: 'center',
                            marginRight: 1,
                            cursor: 'grab',
                            opacity: .25
                        }}
                    >
                        <DragIndicatorIcon/>
                    </Box>
                </div>
                <Typography
                    sx={{
                        display: 'inline-block'
                    }}
                >
                    {props.children}
                </Typography>
            </Box>
            <div
                contentEditable={false}
                ref={drop}
                style={{
                    paddingTop: '5px',
                    paddingBottom: '5px',
                    userSelect: 'none',
                    display: readOnly ? 'none' : 'inherit'
                }}
            >
                <span devLabel="forChromiumBugRelatedToTyping"></span>
                {isOver &&
                    <Divider
                        contentEditable={false}
                        sx={{userSelect: "none"}}
                    />
                }
            </div>
        </Box>
    )
};

export default BitJokeBlock;

