const replacer = (key, value) =>
    value instanceof Object && !(value instanceof Array) ?
        Object.keys(value)
            .sort(customSort)
            .reduce((sorted, key) => {
                sorted[key] = value[key];
                return sorted
            }, {}) : value;

const customSort = (a, b) => {
    if (a === 'type' && b !== 'type') {
        return -2;
    }
    else if (b === 'type' && a !== 'type') {
        return 2;
    }
    else if (a === 'type' && b === 'type') {
        return 0;
    }

    if (a < b) {
        return -1;
    }
    else if (a > b) {
        return 1;
    }
    // a must be equal to b
    return 0;
}

export default {
    stringify: (data) => JSON.stringify(data, replacer)
}