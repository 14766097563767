import React from "react";

const inputSlugify = (e: React.ChangeEvent<HTMLInputElement>, key: string, callbackSetState: (value: React.SetStateAction<any>) => void) => {
    const value = slugify(e.target.value);

    callbackSetState((prevState: any) => ({
        ...prevState,
        [key]: value
    }));
};

const slugify = (stringToSlugify: string) => {
    return stringToSlugify.toLowerCase()
        .replace(/[\s_]/g,'-')
        .replace(/^[-]/g,'')
        .replace(/[-]+/g, '-')
        .replace(/[^\w-]+/g,'');
}

export default {
    slugify,
    inputSlugify
};