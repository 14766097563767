import PostDto from "../../../includes/models/PostDto";
import FileDto from "../../../includes/models/FileDto";
import SetlistDto from "./SetlistDto";
import StagingSetlistDto from "./StagingSetlistDto";

export default class StagingPerformanceDto extends PostDto {
    filePostDto: FileDto | null = null;
    file: File | null = null;
    stagingSetlistDto: StagingSetlistDto = new StagingSetlistDto();
    performanceHashId: string = "";
    location: string = "";
    comments: string = "";

    constructor(setlistDto?: Partial<StagingPerformanceDto>) {
        super();
        Object.assign(this, setlistDto);
    };
}