import React, {ChangeEvent, useMemo} from 'react';
import {AgMaterialDiv, StandardMaterialTable} from "./StandardMaterialTable";
import {
  Box,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  LinearProgress,
  MenuItem,
  Select, SelectChangeEvent,
  TextField, Typography
} from "@mui/material";
import {SxProps} from "@mui/system";
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import SkipNextIcon from '@mui/icons-material/SkipNext';
import SkipPreviousIcon from '@mui/icons-material/SkipPrevious';
import CircularProgress from '@mui/material/CircularProgress';
import {GridOptions} from "ag-grid-community";
import PaginationParameters from "../../../includes/data-transfer-objects/PaginationParameters";
import {AgGridReactProps} from "ag-grid-react";

interface PagingRowProps {
  sx?: SxProps,
  recordsCount: number,
  paginationParameters: PaginationParameters
  onFirstButtonClicked: () => void,
  onBackButtonClicked: () => void,
  onNextButtonClicked: () => void,
  onLastButtonClicked: () => void,
  onPageSizeChanged: (event: ChangeEvent<HTMLInputElement>) => void
}

const PagingRow = (props: PagingRowProps) => {
  const {
    sx,
    recordsCount,
    paginationParameters: {
      pageSize,
      pageNumber
    },
    onPageSizeChanged,
    onFirstButtonClicked,
    onBackButtonClicked,
    onNextButtonClicked,
    onLastButtonClicked
  } = props;

  let recordRange, pageInfo;

  if (recordsCount === 0) {
    recordRange = 'No Records';
    pageInfo = 'Page 1 of 1';
  }
  else {
    recordRange = `${pageSize*(pageNumber-1)+1} to ${Math.min(pageSize*pageNumber, recordsCount)} of ${recordsCount}`;
    pageInfo = `Page ${pageNumber} of ${Math.ceil(recordsCount/pageSize)}`
  }

  return (
    <AgMaterialDiv className="ag-theme-material">
      <Grid
        className="ag-root-wrapper ag-layout-auto-height ag-ltr"
      >
        <Grid
          container
          direction="row"
          alignItems="center"
          justifyContent="flex-end"
          spacing={3}
          sx={{
            pt: 3,
            pb: 3,
            backgroundColor: 'background.paper',
            backgroundImage: 'linear-gradient(rgba(255, 255, 255, 0.05), rgba(255, 255, 255, 0.05))',
            ...sx,
          }}
        >
          <Grid
            item
            xs={12}
            md="auto"
          >
              <TextField
                label="Page Size"
                id="page-size"
                select
                defaultValue={10}
                sx={{
                  width: '125px',
                  float: 'right',
                  pr: 1
                }}
                onChange={onPageSizeChanged}
                value={pageSize}
              >
                <MenuItem value={10}>10</MenuItem>
                <MenuItem value={25}>25</MenuItem>
                <MenuItem value={50}>50</MenuItem>
                <MenuItem value={100}>100</MenuItem>
              </TextField>
          </Grid>
          <Grid item>
              <Typography
                sx={{
                  minWidth: '100px',
                  textAlign: 'center',
                  pr: 1
                }}
              >
                {recordRange}
              </Typography>
          </Grid>
          <Grid
            item
            sx={{
              minWidth: '250px',
              pr: 1
            }}
          >
            <Grid
              container
              direction="row"
              alignItems="center"
            >
              <Grid item>
                <IconButton aria-label="last" size="small" onClick={onFirstButtonClicked}>
                  <SkipPreviousIcon fontSize="small" />
                </IconButton>
              </Grid>
              <Grid item>
                <IconButton aria-label="back" size="small" onClick={onBackButtonClicked}>
                  <ArrowBackIosNewIcon fontSize="small" />
                </IconButton>
              </Grid>
              <Grid item>
                <Typography
                  sx={{
                    width: '100px',
                    textAlign: 'center'
                  }}
                >
                  {pageInfo}
                </Typography>
              </Grid>
              <Grid item>
                <IconButton aria-label="forward" size="small" onClick={onNextButtonClicked}>
                  <ArrowForwardIosIcon fontSize="small" />
                </IconButton>
              </Grid>
              <Grid item>
                <IconButton aria-label="last" size="small" onClick={onLastButtonClicked}>
                  <SkipNextIcon fontSize="small" />
                </IconButton>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </AgMaterialDiv>
  )
}

const SpinningOverlay = () => {
  return (
    <div className="ag-custom-loading-cell" style={{paddingLeft: '10px', lineHeight: '25px'}}>
      <CircularProgress size="6rem"/>
    </div>
  );
}

interface PaginatedMaterialTableProps extends AgGridReactProps {
  onFirstButtonClicked: () => void;
  onBackButtonClicked: () => void;
  onNextButtonClicked: () => void;
  onLastButtonClicked: () => void;
  gridOptions?: GridOptions;
  onPageSizeChanged: (event: ChangeEvent<HTMLInputElement>) => void;
  paginationParameters: PaginationParameters;
  recordsCount: number;
}

const PaginatedMaterialTable = (props: PaginatedMaterialTableProps) => {
  const {
    gridOptions,
    onFirstButtonClicked,
    onBackButtonClicked,
    onNextButtonClicked,
    onLastButtonClicked,
    onPageSizeChanged,
    paginationParameters,
    recordsCount
  } = props

  const spinningOverlay = useMemo(() => SpinningOverlay, []);

  const options = {
    ...gridOptions,
    loadingOverlayComponent: spinningOverlay
  }

  return (
    <div>
      <PagingRow
        paginationParameters={paginationParameters}
        onFirstButtonClicked={onFirstButtonClicked}
        onBackButtonClicked={onBackButtonClicked}
        onNextButtonClicked={onNextButtonClicked}
        onLastButtonClicked={onLastButtonClicked}
        onPageSizeChanged={onPageSizeChanged}
        recordsCount={recordsCount}
        sx={{
          borderTop: 'none !important',
          borderBottomColor: 'text.secondary',
          borderBottomWidth: '1px',
          borderBottomStyle: 'solid',
        }}
      />
      <StandardMaterialTable
        {...props}
        gridOptions={options}
        loadingOverlayComponent={spinningOverlay}
      />
      <PagingRow
        paginationParameters={paginationParameters}
        onFirstButtonClicked={onFirstButtonClicked}
        onBackButtonClicked={onBackButtonClicked}
        onNextButtonClicked={onNextButtonClicked}
        onLastButtonClicked={onLastButtonClicked}
        onPageSizeChanged={onPageSizeChanged}
        recordsCount={recordsCount}
        sx={{
          borderTopColor: 'text.secondary',
          borderTopWidth: '1px',
          borderTopStyle: 'solid',
        }}
      />
    </div>
  );
}

export default PaginatedMaterialTable;