import React, {SetStateAction} from "react";
import TermDto from "../../../includes/models/TermDto";
import TermGroupingDto from "../../../includes/models/TermGroupingDto";
import Paper from "@mui/material/Paper";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import TabPanel from "../TabPanel/TabPanel";
import {Box} from "@mui/material";
import TextField from "@mui/material/TextField";
import TermGroupingSidebar from "../Term-Grouping-Components/TermGroupingSidebar";
import Button from "@mui/material/Button";
import SlugUtils from "../../../includes/validators/SlugUtils";
import CenteredSpinner from "../CenteredSpinner/CenteredSpinner";
import {SxProps} from "@mui/system";

interface AddEditTermProps {
  label: string,
  termDto: TermDto | undefined,
  onButtonClick: () => Promise<void>,
  setTermDto: (value: SetStateAction<TermDto | undefined>) => void,
  preventTermGroupingEdit?: boolean,
  sx?: SxProps
}

const AddEditTerm = (props: AddEditTermProps) => {
  const {
    label,
    termDto,
    onButtonClick,
    setTermDto,
    preventTermGroupingEdit,
    sx
  } = props;

  if (termDto === undefined) {
    return <Paper
      sx={{
        width: "100%",
      }}
    >
      <CenteredSpinner/>
    </Paper>
  }

  const handleChange = (e:any) => {
    const {name, value} = e.target;
    setTermDto((prevState) => {
      if (prevState === undefined) {
        return;
      }

      return ({
        ...prevState,
        [name]: value,
      })
    });
  };

  const onAddTermGrouping = (termGroupingDto: TermGroupingDto)  => {
    if (termDto.termGroupingDtos.some((tg) => tg.hashId === termGroupingDto.hashId)) {
      return;
    }

    setTermDto((prevState) => {
      if (prevState === undefined) {
        return undefined;
      }

      return ({
        ...prevState,
        termGroupingDtos: [...prevState.termGroupingDtos, termGroupingDto]
      });
    });
  }

  const onTermDeleted = (termGroupingDtoDeleted: TermGroupingDto) => {
    const termDtosMinusDeletedTerm = termDto.termGroupingDtos.filter((tg) => tg.hashId !== termGroupingDtoDeleted.hashId);

    setTermDto((prevState) => {
      if (prevState === undefined) {
        return undefined;
      }

      return ({
        ...prevState,
        termGroupingDtos: termDtosMinusDeletedTerm,
      });
    });
  };

  const handleNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (SlugUtils.slugify(termDto.name) == termDto.slug) {
      SlugUtils.inputSlugify(e, 'slug', setTermDto);
    }

    handleChange(e);
  }

  const handleSlugField = (e: React.ChangeEvent<HTMLInputElement>) => {
    SlugUtils.inputSlugify(e, 'slug', setTermDto);
  };

  return <Paper
    sx={{
      width: "100%",
      ...sx
    }}
  >
    <Tabs
      value={0}
      sx={{
        width: "100%",
      }}
    >
      <Tab
        label={label}
        sx={{
          minWidth: "100%",
        }}
      />
    </Tabs>
    <TabPanel value={0} index={0}>
      <Box
        sx={{
          padding: 2,
        }}
      >
        <TextField
          label="Name"
          fullWidth
          sx={{
            fontSize: "36px",
            marginBottom: 3,
          }}
          onChange={handleNameChange}
          name="name"
          variant="standard"
          value={termDto.name}
        />
        <TextField
          label="Slug"
          fullWidth
          sx={{
            fontSize: "36px",
            marginBottom: 3,
          }}
          onChange={handleSlugField}
          name="slug"
          variant="standard"
          value={termDto.slug}
        />
        <TermGroupingSidebar
          onAddTermGrouping={onAddTermGrouping}
          onTermDeleted={onTermDeleted}
          termGroupingDtos={termDto.termGroupingDtos}
          preventTermGroupingEdit={preventTermGroupingEdit}
        />
        <TextField
          fullWidth
          sx={{
            fontSize: "36px",
            marginBottom: 3,
          }}
          label="Description"
          multiline
          name="description"
          onChange={handleChange}
          variant="standard"
          value={termDto.description}
          rows={4}
        />
        <Button
          variant="contained"
          color="primary"
          onClick={onButtonClick}
        >
          {label}
        </Button>
      </Box>
    </TabPanel>
  </Paper>;
}

export default AddEditTerm;
