import React, {Component} from 'react';
import DefaultNavMenu from './DefaultNavMenu.tsx';

export class DefaultLayout extends Component {
  static displayName = DefaultLayout.name;

  render() {
    return (
      <div>
        <DefaultNavMenu>
          {this.props.children}
        </DefaultNavMenu>
      </div>
    );
  }
}
