import BlockQuote from "./src/BlockQuote";
import BlockQuoteTransforms from "./src/BlockQuoteTransforms";
import BoldLeafRendererComponent from "../CoreStyles/BoldLeafRendererComponent";
import ItalicLeafRendererComponent from "../CoreStyles/ItalicLeafRendererComponent";
import UnderlineLeafRendererComponent from "../CoreStyles/UnderlineLeafRendererComponent";
import CodeLeafRendererComponent from "../CoreStyles/CodeLeafRendererComponent";
import FormatQuoteIcon from '@mui/icons-material/FormatQuote';
import BlockQuotePlugins from "./src/BlockQuotePlugins";

const SlateBlockQuoteComponent = {
    author: 'Rob Reinhard',
    block: BlockQuote,
    decorators: undefined,
    default: false,
    defaultResetHandler: undefined,
    dependencyTypes: undefined,
    leafRenderers: undefined,
    name: 'Block Quote',
    onDrop: false,
    onKeyDown: undefined,
    plugins: [BlockQuotePlugins.withBlockQuotePlugin],
    resetToDefault: undefined,
    toolbar: {
        icon: FormatQuoteIcon,
        isActive: undefined,
        onClick: (editor, event) => BlockQuoteTransforms.insertBlockQuote(editor, event)
    },
    transforms: BlockQuoteTransforms,
    type: 'block-quote'
};

export default SlateBlockQuoteComponent;