import React from 'react';
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import SlateRichTextEditor from "../../../rob-horse/reusable-components/Slate-Editors/SlateRichTextEditor";
import styled from '@emotion/styled';
import {Box} from "@mui/material";

export const ItemTypes = {
    JOKE: 'joke'
};

const NumberedSlateRichTextEditor = styled(SlateRichTextEditor)`
  counter-reset: bitNumberHighlighting setlistNumberHighlighting;
  p {
    counter-increment: bitNumberHighlighting;
    padding-left: 1.5em;
    &::before {
      width: 1.5em;
      content: counters(bitNumberHighlighting, ".") ". ";
      margin-left: -1.5em;
      display: inline-block !important;
    };
    & span[data-slate-placeholder="true"] {
      display: inline-block !important;
    };
  }
`;

const SetlistWriter = (props: any) => {
    const {
        content,
        editor,
        onContentChange,
        readOnly,
        slateComponents
    } = props

    return (
        <Box
            sx={{
                pointerEvents: readOnly ? 'none' : 'inherit'
            }}
        >
            <DndProvider backend={HTML5Backend}>
                <NumberedSlateRichTextEditor
                    content={content}
                    editor={editor}
                    onContentChange={onContentChange}
                    readOnly={readOnly}
                    hideToolbar
                    slateComponents={slateComponents}
                    type="setlist-editor"
                />
            </DndProvider>
        </Box>
    )
};

export default SetlistWriter;