import PostDto from "../../../includes/models/PostDto";
import JokeBlockTransforms from "../../theme/slate-js/SlateJokeComponents/src/JokeBlockTransforms";
import JokePerformanceDto from "./JokePerformanceDto";

export default class JokeDto extends PostDto {

    slateContent: any = JokeBlockTransforms.defaultJokeEditorBlock();
    legacyJSONSerializedSlateContent: string = "";

    jokePerformanceDtos: JokePerformanceDto[] = [];
    contentTypeSlug: string = "joke"

    constructor(jokeDto?: Partial<JokeDto>) {
        super();
        Object.assign(this, jokeDto);
    };
}

export class JokeDtoUtils {
    static equals(x: JokeDto, y: JokeDto) {
        if (x.name !== y.name ||
            JSON.stringify(x.slateContent) !== JSON.stringify(y.slateContent)) {
            return false;
        }
        return true;
    }
}