import SetlistLaughBlock from "./src/SetlistLaughBlock";
import BoldLeafRendererComponent
    from "../../../../rob-horse/reusable-components/Slate-Editors/Custom-Components/CoreStyles/BoldLeafRendererComponent";
import ItalicLeafRendererComponent
    from "../../../../rob-horse/reusable-components/Slate-Editors/Custom-Components/CoreStyles/ItalicLeafRendererComponent";
import UnderlineLeafRendererComponent
    from "../../../../rob-horse/reusable-components/Slate-Editors/Custom-Components/CoreStyles/UnderlineLeafRendererComponent";

const SlateSetlistComponent = {
    author: 'Rob Reinhard',
    block: SetlistLaughBlock,
    decorators: undefined,
    default: false,
    defaultResetHandler: undefined,
    dependencyTypes: undefined,
    leafRenderers: [BoldLeafRendererComponent.type, ItalicLeafRendererComponent.type, UnderlineLeafRendererComponent.type],
    name: 'Setlist Laugh Block',
    onDrop: (editor, event) => {
        event.preventDefault();
    },
    onKeyDown: undefined,
    plugins: undefined,
    resetToDefault: undefined,
    toolbar: undefined,
    transforms: undefined,
    type: 'setlist-laugh'
};

export default SlateSetlistComponent;