import FormatListNumberedIcon from "@mui/icons-material/FormatListNumbered";
import ListTransforms from "./src/ListTransforms";
import ListPlugins from "./src/ListPlugins";
import OrderedListBlock from "./src/OrderedListBlock";

const SlateOrderedListComponent = {
    author: 'Rob Reinhard',
    block: OrderedListBlock,
    decorators: undefined,
    default: undefined,
    dependencyTypes: undefined,
    leafRenderers: undefined,
    name: 'Ordered List',
    onDrop: false,
    onKeyDown: undefined,
    plugins: [ListPlugins.withListPlugin],
    toolbar: {
        icon: FormatListNumberedIcon,
        isActive: undefined,
        onClick: (editor) => ListTransforms.insertList(editor, true)
    },
    transforms: ListTransforms,
    type: 'ordered-list'
};

export default SlateOrderedListComponent;