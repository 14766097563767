import React, {MouseEvent} from 'react';
import {Box, Button, ButtonBase, Popover, Typography} from "@mui/material";
import useTheme from "@mui/material/styles/useTheme";
import { alpha } from "@mui/material";
import {tagColors} from "./Colors";
import {SxProps} from "@mui/system";
import CheckIcon from '@mui/icons-material/Check';

interface ColorPickerProps {
  selectedColor?: string;
  onColorSelected: (color: string) => void
  sx: SxProps
}

const ColorPicker = (props: ColorPickerProps) => {
  const {
    selectedColor,
    onColorSelected,
    sx
  } = props;

  const theme = useTheme();

  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const onColorButtonClicked = (event: MouseEvent<HTMLButtonElement>) => {
    onColorSelected(event.currentTarget.value);
    setAnchorEl(null);
  }

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  return (
    <Box
      sx={sx}
    >
      <Button
        variant="contained"
        onClick={handleClick}
        fullWidth
        sx={{
          color: selectedColor ? theme.palette.getContrastText(selectedColor) : theme.palette.text.primary,
          backgroundColor: selectedColor || theme.palette.action.selected,
          ':hover': {
            backgroundColor: alpha(selectedColor || theme.palette.action.selected, .8)
          },
          ':active': {
            backgroundColor: alpha(selectedColor || theme.palette.action.selected, .6)
          },
        }}
      >
        {selectedColor ? "Color Selected" : "Select Color"}
      </Button>
      {anchorEl &&
        <Popover
            id={id}
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
        >
            <Box
                sx={{
                  display: 'flex',
                  flexWrap: 'wrap',
                  height: anchorEl?.clientWidth * 1.25 || '200px',
                  width: anchorEl?.clientWidth || '200px'
                }}
            >
              {tagColors.map(color => (
                <Box
                  component={ButtonBase}
                  sx={{
                    position: 'relative',
                    flexBasis: '25%',
                    backgroundColor: color,
                    boxSizing: 'border-box',
                    ':hover': {
                      backgroundColor: alpha(color, .8)
                    },
                  }}
                  onClick={onColorButtonClicked}
                  value={color}
                >
                  {selectedColor == color &&
                    <CheckIcon
                      sx={{
                        position: "absolute",
                        margin: "0 auto"
                      }}
                    />
                  }
                </Box>
              ))}
              <Box
                  component={ButtonBase}
                  sx={{
                    position: 'relative',
                    flexBasis: '100%',
                    backgroundColor: theme.palette.action.selected,
                    boxSizing: 'border-box',
                    ':hover': {
                      backgroundColor: alpha(theme.palette.action.selected, .8)
                    }
                  }}
                  onClick={onColorButtonClicked}
                  value={undefined}
              >
                  <Typography
                    sx={{
                      position: "absolute",
                      margin: "0 auto"
                    }}
                  >No Color</Typography>
              </Box>
            </Box>
        </Popover>
      }
    </Box>
  )
}

export default ColorPicker;