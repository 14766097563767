import React from 'react';
import TermGroupingChip from './TermGroupingChip';
import styled from '@emotion/styled';
import {Box} from "@mui/material";
import {SxProps} from "@mui/system";
import TermGroupingDto from "../../../includes/models/TermGroupingDto";

const StyledTermGroupingChip = styled(TermGroupingChip)`
  marginRight: 2px !important;
  marginBottom: 2px !important;
`;

interface TermGroupingBlockProps {
    onTermDeleted?: any,
    readOnly?: boolean,
    size?: 'medium' | 'small'
    termGroupingDtos: TermGroupingDto[],
    sx?: SxProps
}

export const TermGroupingsBlock = (props: TermGroupingBlockProps) => {
  const {termGroupingDtos, readOnly, onTermDeleted, size, sx} = props;

  return (
    <Box sx={sx}>
      {termGroupingDtos.map((termGroupingDto) => (
        <StyledTermGroupingChip
          label={termGroupingDto.name}
          onTermDelete={onTermDeleted}
          readonly={readOnly}
          size={size}
          termGroupingDto={termGroupingDto}
        />
      ))}
    </Box>
  );
};
