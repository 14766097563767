import React from "react";

import BoldLeafRenderer from "./BoldLeafRenderer";
import {FormatBold} from "@mui/icons-material";
import {disableStyle, getActiveStyles, toggleStyle} from "./MarkUtils";
import ParagraphBlockTransforms from "../SlateParagraphBlockComponent/src/ParagraphBlockTransforms";

const boldLeafRendererType = 'bold';

const BoldLeafRendererComponent = {
    author: 'Rob Reinhard',
    block: undefined,
    decorators: undefined,
    default: undefined,
    dependencyTypes: undefined,
    leafRenderers: undefined,
    leafRenderer: BoldLeafRenderer,
    name: 'Bold Leaf Renderer',
    onDrop: false,
    onKeyDown: (editor, event) => {
        if (event.key === 'b' && event.metaKey) {
            event.preventDefault();
            toggleStyle(editor, boldLeafRendererType);
        }
    },
    plugins: undefined,
    resetToDefault: undefined,
    toolbar: {
        icon: FormatBold,
        isActive: (editor) => {
            return getActiveStyles(editor).has(boldLeafRendererType);
        },
        disabled: (editor, slateComponents) => {
            return disableStyle(editor, slateComponents, boldLeafRendererType);
        },
        onClick: (editor, event) => {
            event.preventDefault();
            toggleStyle(editor, boldLeafRendererType);
        }
    },
    transforms: undefined,
    type: boldLeafRendererType
};

export default BoldLeafRendererComponent;