import React from 'react'
import styled from "@emotion/styled";

const UnorderedListStyle = styled.ul`
    font-size: 1rem;
    margin-bottom: 0;
    margin-top: 0;
`;

const UnorderedList = (props) => {
    return (
        <UnorderedListStyle
            {...props.attributes}
        >
            {props.children}
        </UnorderedListStyle>
    );
};

export default UnorderedList;