import {Transforms, Text} from 'slate';

const withDecoratedTextType = (editor) => {
    const { normalizeNode } = editor;

    editor.normalizeNode = (entry) => {
        const [node, path] = entry;

        if (Text.isText(node) && (node.bold || node.italic || node.code || node.underline))
        {
            const {type, ...rest} = node;

            Transforms.setNodes(
                editor,
                {
                    type: "decorated-text",
                    ...rest
                },
                {at: path})
        }

        normalizeNode(entry);
    };

    return editor;
};

export default {
    withDecoratedTextType
};