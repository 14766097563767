import CodeLine from "./src/HighlightedCodeLine/HighlightedCodeLine";
import HighlightedCodeBlockTransforms from "./src/HighlightedCodeBlock/HighlightedCodeBlockTransforms";
import SlateHighlightedCodeLineLeafRendererComponent from "./SlateHighlightedCodeLineLeafRendererComponent";

const SlateHighlightedCodeLineComponent = {
    author: 'Rob Reinhard',
    block: CodeLine,
    decorators: undefined,
    default: undefined,
    dependencyTypes: undefined,
    leafRenderers: [SlateHighlightedCodeLineLeafRendererComponent.type],
    name: 'Highlighted Code Line',
    onDrop: false,
    onKeyDown: undefined,
    plugins: undefined,
    resetToDefault: (editor, elementPath, defaultBlock) => {
        const codeBlockPath = elementPath.slice(0,-1);

        HighlightedCodeBlockTransforms.removeAndReplaceHighlightedCodeBlock(editor, codeBlockPath, defaultBlock);
    },
    toolbar: undefined,
    transforms: undefined,
    type: 'code-line'
};

export default SlateHighlightedCodeLineComponent;