import React, {MouseEvent, SyntheticEvent, useEffect, useState} from 'react';
import {
    Autocomplete, Box,
    CircularProgress,
    InputAdornment,
    TextField, TextFieldProps,
    Typography
} from "@mui/material";
import * as signalR from '@microsoft/signalr'
import {HubConnection, HubConnectionState} from '@microsoft/signalr'
import {accountService} from "../../../includes/api-authorization/services";
import { SxProps } from '@mui/system';
import Search from "@mui/icons-material/Search";
import SlugUtils from "../../../includes/validators/SlugUtils";
import TermGroupingDto from "../../../includes/models/TermGroupingDto";

interface TermSearchProps {
    onAddTermGrouping: (termGroupingDto: TermGroupingDto) => void,
    searchLabel: string,
    contentTypeSlugs?: string[] | undefined,
    sx?: SxProps | undefined,
    textFieldProps?: TextFieldProps
}

const TermGroupingSearch = (props: TermSearchProps) => {
    const [connection, setConnection] = useState<HubConnection>();
    const [searchedTermGroupings, setSearchedTermGroupings] = useState<TermGroupingDto[]>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const [searchFieldText, setSearchFieldText] = useState<string>("");
    const [open, setOpen] = React.useState(false);

    const {
        onAddTermGrouping,
        searchLabel,
        sx,
        textFieldProps
    } = props;

    useEffect(() => {
        const signalRConnection = new signalR.HubConnectionBuilder()
            .withUrl('/signalR/termGroupingSearchHub', { accessTokenFactory: () => accountService.userValue.jwtToken})
            .withAutomaticReconnect()
            .build();

        setConnection(signalRConnection);
    }, [])

    useEffect(() => {
        if (connection) {
            connection.start()
                .then(() => {
                    connection.on('ReceivedTermGroupings', message => {
                       setSearchedTermGroupings(message)
                    });
                })
                .catch(e => console.log('Connection failed: ', e));
        }
    }, [connection]);

    const sendMessage = async (chatMessage: string) => {
        if (connection?.state !== HubConnectionState.Connected) {
            return;
        }

        const data = {
            searchString: chatMessage,
            paginationParameters: {
                pageSize: 10,
                pageNumber: 1
            }
        }

        try {
            await connection.invoke('SearchTermGroupings', data);
        }
        catch(e) {
            console.log(e);
        }
    }

    useEffect(() => {
        if (!open) {
         return;
        }

        const delayDebounceFn = setTimeout(() => {
            sendMessage(searchFieldText).then(() => {
                setLoading(false);
            })
        }, 500);

        return () => clearTimeout(delayDebounceFn)
    }, [searchFieldText, sendMessage, setLoading, open]);

    const onSearchTextFieldChange = (event: SyntheticEvent, value : string, reason: string) => {
        setLoading(true);

        if (reason === 'input') {
            setSearchFieldText(value)
        }
        else if (reason === 'clear') {
            setSearchFieldText('')
        }
    };

    const onKeyPress = (event: any) => {
        if (event.key !== 'Enter') {
            return;
        }

        onAddTermGrouping(new TermGroupingDto({
            name: searchFieldText,
            slug: SlugUtils.slugify(searchFieldText),
        }));

        setSearchFieldText('')
    };

    const onSearchTermGroupingClicked = (event: MouseEvent<HTMLLIElement>) => {
        const target = event.currentTarget as HTMLLIElement;

        const hashId = target.getAttribute('data-hashId');

        const termGroupingToAdd = searchedTermGroupings.find(t => t.hashId === hashId);

        if (!termGroupingToAdd) {
            return;
        }

        onAddTermGrouping(termGroupingToAdd);

        setSearchFieldText('')
    }

    const onOpen = async () => {
        setOpen(true);
        await sendMessage(searchFieldText);
    }

    const onClose = () => setOpen(false);

    const endLoadingAdornment = textFieldProps?.InputProps?.endAdornment ? textFieldProps.InputProps.endAdornment : <Search/>;

    return (
        <Autocomplete
          options={searchedTermGroupings}
          freeSolo
          open={open}
          onOpen={onOpen}
          onKeyPress={onKeyPress}
          onInputChange={onSearchTextFieldChange}
          onClose={onClose}
          getOptionLabel={(option) => option.name}
          inputValue={searchFieldText}
          loading={loading}
          loadingText="Searching..."
          renderInput={(params) => (
            <TextField
             {...params}
             label={searchLabel}
             InputProps={{
                 ...params.InputProps,
                 endAdornment: (
                   <InputAdornment position="end">
                       {loading ? <CircularProgress color="inherit" size={20} /> : endLoadingAdornment}
                       {params.InputProps.endAdornment}
                   </InputAdornment>
                 ),
             }}
             variant="standard"
            />
         )}
         renderOption={(props, option) => (
            <li
              {...props}
              data-hashId={option.hashId}
              onMouseDown={onSearchTermGroupingClicked}
              onClick={onClose}
            >
                <Box>
                    <Typography>{option.name}</Typography>
                    <Typography variant="caption">{option.slug}</Typography>
                </Box>
            </li>
         )}
         filterOptions={(x) => x}
         sx={sx}
        />
    )
}

export default TermGroupingSearch;