import React, {createContext, useEffect, useState} from 'react';
import {BrowserRouter, Switch} from 'react-router-dom';
import {DefaultLayout} from './DefaultLayout';
import {SimpleLayout} from './SimpleLayout';
import {Add} from './Add';
import {AddPost} from './AddPost';
import {Posts} from './Posts';
import {ViewPost} from './ViewPost';
import {EditPost} from './EditPost';
import TermGrouping from './TermGrouping';
import {RobHorseHome} from './RobHorseHome';
import {accountService} from '../includes/api-authorization/services';
import {PrivateRouteWithLayout} from '../includes/route-components/PrivateRouteWithLayout';
import {PrivatePublicRouteWithLayout} from '../includes/route-components/PrivatePublicRouteWithLayout';
import {RouteWithLayout} from '../includes/route-components/RouteWithLayout';
import {Login} from './Login';
import './custom.css';
import Term from './Term';
import ContentTypes from './ContentTypes';
import {ContentType} from './ContentType';
import {ThemeProvider, StyledEngineProvider, createTheme} from '@mui/material/styles';
import {useMediaQuery} from '@mui/material';
import ProfilePage from './ProfilePage';
import TermGroupings from "./TermGroupings";
import Terms from "./Terms";
import Error404Page from "./Error404Page";

const validColorModeValues = ['light', 'system', 'dark'];

export const ColorModeContext = createContext({
  colorMode: 'system',
  setColorMode: (value) => {},
});

const App = () => {
  const [user, setUser] = useState();
  const [subscription, setSubscription] = useState(null);
  const [colorMode, setColorMode] = useState(localStorage.getItem('colorMode') || 'system');

  useEffect(() => {
    accountService.refreshToken();

    setUser(accountService.user);

    const newSubscription = accountService.user.subscribe((x) => {
      setUser(x);
    });

    setSubscription(newSubscription);

    return () => {
      return subscription?.unsubscribe();
    };
  }, []);

  const systemOption = useMediaQuery('(prefers-color-scheme: dark)') ? 'dark' : 'light';

  const theme = React.useMemo(() => createTheme({
    palette: {
      mode: colorMode === 'system' ? systemOption : colorMode,
    },
  }), [colorMode, systemOption]);

  useEffect(() => {
    if (!validColorModeValues.includes(localStorage.getItem('colorMode'))) {
      localStorage.setItem('colorMode', 'system');
      setColorMode('system');
    } else {
      localStorage.setItem('colorMode', colorMode);
    }
  }, [colorMode]);

  return (
    <StyledEngineProvider injectFirst>
      <ColorModeContext.Provider
        value={{
          colorMode,
          setColorMode,
        }}
      >
        <ThemeProvider theme={theme}>
          <BrowserRouter>
            <Switch>
              <PrivatePublicRouteWithLayout unauthLayout={SimpleLayout} authLayout={DefaultLayout} exact path='/' unauthComponent={RobHorseHome} authComponent={Posts} />
              <PrivatePublicRouteWithLayout unauthLayout={SimpleLayout} authLayout={DefaultLayout} exact path='/@:username' unauthComponent={ProfilePage} authComponent={ProfilePage}/>

              <RouteWithLayout layout={SimpleLayout} path='/login' component={Login} />

              <PrivateRouteWithLayout layout={DefaultLayout} path='/posts' component={Posts} />

              <PrivateRouteWithLayout layout={DefaultLayout} exact path='/add' component={Add} />
              <PrivateRouteWithLayout layout={DefaultLayout} exact path='/add/:contentTypeSlug' component={AddPost} />

              <PrivateRouteWithLayout layout={DefaultLayout} exact path='/edit/:hashId' component={EditPost} />
              <PrivatePublicRouteWithLayout unauthLayout={SimpleLayout} authLayout={DefaultLayout} exact path='/view/:hashId' unauthComponent={ViewPost} authComponent={ViewPost}/>

              <PrivateRouteWithLayout layout={DefaultLayout} exact path='/groupings' component={TermGroupings} />
              <PrivateRouteWithLayout layout={DefaultLayout} exact path='/groupings/:termGroupingSlug' component={TermGrouping} />

              <PrivateRouteWithLayout layout={DefaultLayout} exact path='/tags' component={Terms} />
              <PrivateRouteWithLayout layout={DefaultLayout} exact path='/tags/:termSlug' component={Term} />

              <PrivateRouteWithLayout layout={DefaultLayout} exact path='/content-types' component={ContentTypes} />
              <PrivateRouteWithLayout layout={DefaultLayout} exact path='/content-types/:contentTypeSlug' component={ContentType} />

              <PrivateRouteWithLayout layout={DefaultLayout} exact path='/profile/settings' component={Posts} />

              <PrivatePublicRouteWithLayout unauthLayout={SimpleLayout} authLayout={DefaultLayout} path='*' unauthComponent={Error404Page} authComponent={Error404Page}/>
            </Switch>
          </BrowserRouter>
        </ThemeProvider>
      </ColorModeContext.Provider>
    </StyledEngineProvider>
  );
};

export default App;
