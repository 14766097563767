import {Editor, Transforms, Node} from "slate";

const defaultListBlock = (isOrderedList, startingNumber) => {
    return {
        children: [defaultListItem()],
        type: isOrderedList ? 'ordered-list' : 'unordered-list' ,
        start: startingNumber,
        key: Math.random()
    }
};

const defaultListItem = () => {
    return {
        type: 'list-item',
        children: [{
            text: ''
        }],
        key: Math.random()
    };
};

const insertList = (editor, isOrderedList, startingNumber) => {
    if (!editor.selection) {
        Transforms.select(editor, [editor.children.length-1]);
    }
    const leafNode = Node.get(editor, editor.selection.anchor.path);
    if (editor.selection.anchor.path.toString() === editor.selection.focus.path.toString()
        && editor.selection.anchor.offset === 0
        && editor.selection.focus.offset === 0
        && leafNode.text === '') {
        Transforms.setNodes(
            editor,
            defaultListBlock(isOrderedList, startingNumber),
            {
                match: n => Editor.isBlock(editor, n) && n.type !== 'list-item'
            }
        );
    }
    else {
        Transforms.insertNodes(
            editor,
            defaultListBlock(isOrderedList, startingNumber),
            {
                match: n => Editor.isBlock(editor, n) && n.type !== 'list-item'
            }
        )
    }
};

const exitList = (editor, path, escapedElement) => {
    const listPath = path.slice(0,-1);
    listPath[0] = listPath[0] + 1;
    Transforms.insertNodes(editor, escapedElement, {
        at: listPath
    });

    Transforms.select(editor, listPath);
}

const removeAndReplaceList = (editor, path, escapedElement) => {
    Transforms.removeNodes(editor, {
        at: path
    });
    Transforms.insertNodes(editor, escapedElement, {at: path});
    Transforms.select(editor, path);
};

export default {
    insertList,
    defaultListBlock,
    defaultListItem,
    exitList,
    removeAndReplaceList
}