import Typography from "@mui/material/Typography";
import {Box, Card, Grid} from "@mui/material";
import React from "react";
import {ICellRendererParams} from "ag-grid-community";
import TermGroupingDto from "../../../includes/models/TermGroupingDto";
import {useTheme} from "@mui/material/styles";

interface TermGroupingRowProps extends ICellRendererParams {
  data: TermGroupingDto,
}


const TermGroupingRow = (props : TermGroupingRowProps) => {
  const {
    data: termGroupingDto,
  } = props;

  const theme = useTheme();

  return (
    <Box sx={{
      m: 1,
    }}>
      <Typography variant="h6" component="h2">{termGroupingDto.name}</Typography>
      <Typography variant="subtitle2">{termGroupingDto.slug}</Typography>
      {termGroupingDto.color &&
        <Typography variant="subtitle2">
          color:
          <Box
              sx={{
                width: '100px',
                height: '15px',
                backgroundColor: termGroupingDto.color || 'inherit',
                borderWidth: '1px',
                borderStyle: 'solid',
                ml: 1,
                display: 'inline-block',
                verticalAlign: 'text-bottom',
                borderColor: theme.palette.text.secondary
              }}
          />
        </Typography>
      }
    </Box>
  )
}

export default TermGroupingRow;