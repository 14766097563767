import {Element, Transforms, Node, Text} from 'slate';
import ListTransforms from "./ListTransforms";
import SlateParagraphBlockComponent from "../../SlateParagraphBlockComponent/SlateParagraphBlockComponent";

const LIST_ITEMS = ['ordered-list', 'unordered-list']

const unorderedListRegex = new RegExp('^([\*][\\s])')

const orderedListRegex = new RegExp('^([1-9][0-9]*)[\.][\\s]')


const withListPlugin = (editor) => {
    const { normalizeNode } = editor;

    editor.normalizeNode = (entry) => {
        const [node, path] = entry;

        if (Element.isElement(node) && LIST_ITEMS.includes(node.type)) {
            node.children.forEach((child, index) => {
                const newNodePath = path.concat(index);
                if (child.type !== 'list-item') {
                    Transforms.removeNodes(editor, {at: newNodePath});
                    Transforms.insertNodes(editor,
                        ListTransforms.defaultListItem(),
                        {at: newNodePath});
                    Transforms.select(editor, newNodePath);
                }
            });
        }

        if (Element.isElement(node) && node.type === 'list-item') {
            const parent = Node.parent(editor, path);
            if (!LIST_ITEMS.includes(parent.type)) {
                Transforms.setNodes(editor, {
                        type: SlateParagraphBlockComponent.type,
                        children: node.children
                    },
                    {at: path})
            }
        }

        if (Text.isText(node) && Node.parent(editor, path).type === SlateParagraphBlockComponent.type) {
            if (unorderedListRegex.test(node.text)) {
                ListTransforms.insertList(editor, false);
                Transforms.removeNodes(editor, {at: path.slice(0, -1)});
            }

            if (orderedListRegex.test(node.text)) {
                const matchingNumber = orderedListRegex.exec(node.text)[1];

                ListTransforms.insertList(editor, true, Number.parseInt(matchingNumber));
                Transforms.removeNodes(editor, {at: path.slice(0, -1)});
            }
        }

        normalizeNode(entry);
    };

    return editor;
};

export default {
    withListPlugin
};