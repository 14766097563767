import PostDto from "../../../includes/models/PostDto";
import {TermDtoUtils} from "../../../includes/models/TermDto";
import JokeDto from "./JokeDto";
import {cloneDeep} from "lodash";

export default class BitDto extends PostDto {
    jokes: JokeDto[] = [];
    contentTypeSlug: string = "bit"

    constructor(bitDto?: Partial<BitDto>) {
        super();
        Object.assign(this, bitDto);
    };
}

export class BitDtoUtils {
    static equals(x: BitDto, y: BitDto) {
        if (x.name !== y.name ||
            !TermDtoUtils.equalArrays(x.termDtos, y.termDtos)) {
            return false;
        }
        return true;
    }

    static createJokeContentFromSlateContent(bitEditor: any) {
        const jokesInBit: JokeDto[] = [];

        bitEditor.children.forEach((blockAtRootLevel: any) => {
            if (blockAtRootLevel.type !== 'joke-editor') {
                throw new Error("There are values in the editor that are not a Joke");
            }

            const appendedJoke = new JokeDto({
                slateContent: blockAtRootLevel,
                hashedParent: blockAtRootLevel.hashedParent
            });


            jokesInBit.push(appendedJoke);
        });

        return jokesInBit;
    }
}