const DoubleQuotation = String.fromCharCode(34);
const LeftDoubleQuotation = String.fromCharCode(8220);
const RightDoubleQuotation = String.fromCharCode(8221);

const Apostrophe = String.fromCharCode(39);
const LeftApostrophe = String.fromCharCode(8216);
const RightApostrophe = String.fromCharCode(8217);
const EmDash = String.fromCharCode(8212);

interface ICharCodeMappingIndex {
  [key: string]: string;
}

const CharCodeMappingIndex: ICharCodeMappingIndex = {
  [RightDoubleQuotation]: DoubleQuotation,
  [LeftDoubleQuotation]: DoubleQuotation,
  [LeftApostrophe]: Apostrophe,
  [RightApostrophe]: Apostrophe,
  [EmDash]: '--'

};

const IOSCharCodeMapper = (oldString: string) => {
  for (const toBeReplaced in CharCodeMappingIndex) {
    oldString = oldString.replace(new RegExp(toBeReplaced, 'g'), CharCodeMappingIndex[toBeReplaced]);
  }
  return oldString;
};

export default IOSCharCodeMapper;