import React from "react";

const UnderlineLeafRenderer = ({attributes, children, leaf}, content) => {
    if (leaf.underline) {
        return <u>{content}</u>;
    }
    else {
        return content;
    }
}

export default UnderlineLeafRenderer;