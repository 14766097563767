import {Node, Transforms} from "slate";
import HighlightedCodeBlockTransforms from "../../SlateHighlightedCodeBlockComponent/src/HighlightedCodeBlock/HighlightedCodeBlockTransforms";
import ListTransforms from "../../SlateListComponent/src/ListTransforms";
import BlockQuoteTransforms from "../../SlateBlockQuoteComponent/src/BlockQuoteTransforms";
import BlockQuoteLine from "../../SlateBlockQuoteComponent/src/BlockQuoteLine";
import SlateBlockQuoteLineComponent from "../../SlateBlockQuoteComponent/SlateBlockQuoteLineComponent";
import SlateListItemComponent from "../../SlateListComponent/SlateListItemComponent";
import SlateHighlightedCodeLineComponent
    from "../../SlateHighlightedCodeBlockComponent/SlateHighlightedCodeLineComponent";

const defaultParagraphBlock = () => {
    return {
        type: "paragraph",
        children: [
            {
                text: "",
            }
        ],
        key: Math.random()
    };
};

const insertParagraphBlock = (editor, event) => {
    event.preventDefault();

    const path = editor.selection.anchor.path.slice(0,-1);
    const selectedElement = Node.get(editor, path);

    switch (selectedElement.type) {
        case SlateHighlightedCodeLineComponent.type:
            HighlightedCodeBlockTransforms.exitHighlightedCodeBlock(editor, path, defaultParagraphBlock());
            break;
        case SlateListItemComponent.type:
            ListTransforms.exitList(editor, path, defaultParagraphBlock());
            break;
        case SlateBlockQuoteLineComponent.type:
            BlockQuoteTransforms.exitBlockQuote(editor, path, defaultParagraphBlock());
            break;
        default:
            Transforms.insertNodes(editor, defaultParagraphBlock());
            break;
    }
}

const resetToParagraphBlock = (editor, event, slateComponents) => {
    if (editor.selection.anchor.offset !== 0
        || !editor.selection.anchor.path.every((node) => node === 0)
    ) {
        return;
    }

    const elementPath = editor.selection.anchor.path.slice(0,-1);
    const selectedElement = Node.get(editor, elementPath);

    const componentThatMayBeReset = slateComponents.find((slateComponent) => slateComponent.type === selectedElement.type);

    if (componentThatMayBeReset) {
        componentThatMayBeReset.resetToDefault(editor, elementPath, defaultParagraphBlock());
    }
}

export default {
    insertParagraphBlock,
    defaultParagraphBlock,
    resetToParagraphBlock
};