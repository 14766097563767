import React from "react";

import TermDto from '../models/TermDto';
import { fetchWrapper } from "../api-authorization/helpers";
import TermQueryParameters from "../data-transfer-objects/TermQueryParameters";
import qs from "qs";

export const getTerms = async (termQueryParameters: TermQueryParameters, onSuccessSetData: (value: React.SetStateAction<TermDto[]>) => void, onErrorCallback: (error: Response) => void) => {
    const searchTermParametersString = qs.stringify(termQueryParameters, { allowDots: true, encode: false });

    await fetchWrapper.getWithHeaders(`api/term?${searchTermParametersString}`).then(onSuccessSetData, onErrorCallback);
}

export const getTermDto = async (termSlug: string, onSuccessSetData: (termDto: TermDto) => void, onErrorCallback: (error: Response) => void) => {
    await fetchWrapper.get(`api/term/${termSlug}`).then(onSuccessSetData,onErrorCallback);
}

export const postTermDto = async (termDto: TermDto, onSuccessSetData: (value: React.SetStateAction<TermDto | undefined>) => void, onErrorCallback: (error: Response) => void) => {
    await fetchWrapper.post('api/term/', termDto).then(onSuccessSetData,onErrorCallback);
}

export const putTermDto = async (termDto: TermDto, onErrorCallback: (error: Response) => void) => {
    await fetchWrapper.put('api/term/', termDto).then(undefined, onErrorCallback);
};