import React, {useState, useEffect} from 'react';
import TermDto from '../includes/models/TermDto';
import {postTermDto} from '../includes/api-functions/TermDtoCalls';
import TermMaterialTable from "./reusable-components/MaterialTable-Components/TermMaterialTable";
import TermSearchProps from "../includes/data-transfer-objects/TermSearchParams";
import TermGroupingDto from "../includes/models/TermGroupingDto";
import {
  getTermGroupingDto,
  putTermGroupingDto
} from "../includes/api-functions/TermGroupingDtoCalls";
import AddEditTerm from "./reusable-components/Term-Components/AddEditTerm";
import CenteredSpinner from "./reusable-components/CenteredSpinner/CenteredSpinner";
import {useParams} from "react-router-dom";
import AddEditTermGrouping from "./reusable-components/Term-Grouping-Components/AddEditTermGrouping";

const TermGrouping = () => {
  const {
    termGroupingSlug
  } = useParams();

  const [termGroupingDto, setTermGroupingDto] = useState<TermGroupingDto | undefined>();
  const [termDto, setTermDto] = useState<TermDto | undefined>(new TermDto());
  const [refreshTerms, setRefreshTerms] = useState<boolean>(true);
  const [termSearchParams, setTermSearchParams] = useState<TermSearchProps | undefined>();

  useEffect(() => {
    setRefreshTerms(false);

    getTermGroupingDto(termGroupingSlug, setTermGroupingDto, handleError).then(async () => {
      setRefreshTerms(true);
    });

    setTermSearchParams({
      termGroupingSlug: termGroupingSlug
    });
  }, [termGroupingSlug]);

  useEffect(() => {
    if (!termGroupingDto) {
      return;
    }

    setTermDto((prevState) => {
      if (prevState === undefined || termGroupingDto === undefined) {
        return undefined;
      }

      return ({
        ...prevState,
        termGroupingDtos: [termGroupingDto]
      });
    });
  }, [termGroupingDto, setTermDto]);

  const handleError = (e: Response) => {
    console.log(e.status);
  };

  if (termGroupingDto === undefined) {
    return <CenteredSpinner />;
  }

  const onAddTerm = async () => {
    setRefreshTerms(false);

    if (termDto === undefined) {
      return;
    }

    await postTermDto(termDto, setTermDto, handleError).then(async () => {
      setRefreshTerms(true);
    });
  };

  const onEditTermGrouping = async () => {
    await putTermGroupingDto(termGroupingDto, handleError).then(async () => {
      await getTermGroupingDto(termGroupingSlug, setTermGroupingDto, handleError);
      setRefreshTerms(true);
    });
  }

  const label = `Add ${termGroupingDto.name} Tag`;

  return (
        <TermMaterialTable
          pageTitle={termGroupingDto?.name || ''}
          termSearchProps={termSearchParams}
          refreshTerms={refreshTerms}
        >
          <AddEditTerm
            label={label}
            termDto={termDto}
            onButtonClick={onAddTerm}
            setTermDto={setTermDto}
            preventTermGroupingEdit
            sx={{
              mb: 2
            }}
          />
          <AddEditTermGrouping
            label="Edit Grouping"
            termGroupingDto={termGroupingDto}
            onButtonClicked={onEditTermGrouping}
            setTermGroupingDto={setTermGroupingDto}
          />
        </TermMaterialTable>
  );
}

export default TermGrouping;
