import React from "react";

import ItalicLeafRenderer from "./ItalicLeafRenderer";
import {FormatItalic} from "@mui/icons-material";
import {disableStyle, getActiveStyles, toggleStyle} from "./MarkUtils";

const italicLeafRendererType = 'italic';

const ItalicLeafRendererComponent = {
    author: 'Rob Reinhard',
    block: undefined,
    decorators: undefined,
    default: undefined,
    dependencyTypes: undefined,
    leafRenderers: undefined,
    leafRenderer: ItalicLeafRenderer,
    name: 'Italic Leaf Renderer',
    onDrop: false,
    onKeyDown: (editor, event) => {
        if (event.key === 'i' && event.metaKey) {
            event.preventDefault();
            toggleStyle(editor, italicLeafRendererType);
        }
    },
    plugins: undefined,
    resetToDefault: undefined,
    toolbar: {
        icon: FormatItalic,
        isActive: (editor) => {
            return getActiveStyles(editor).has(italicLeafRendererType);
        },
        disabled: (editor, slateComponents) => {
            return disableStyle(editor, slateComponents, italicLeafRendererType);
        },
        onClick: (editor, event) => {
            event.preventDefault();
            toggleStyle(editor, italicLeafRendererType);
        }
    },
    transforms: undefined,
    type: italicLeafRendererType
};

export default ItalicLeafRendererComponent;