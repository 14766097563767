import React, {useEffect} from 'react';
import ViewPostBase from "../reusable-components/Add-Edit-View-Default-Bases/ViewPostBase";
import FileUploaderViewer from "../reusable-components/Material-File-Viewers/FileUploaderViewer";
import {
    getFileDtoByHashId,
    getFileDtosFileByHashId
} from "../../includes/api-functions/FileDtoCalls";
import FileDto from "../../includes/models/FileDto";

interface IViewFileProps {
    hashId: string;
}

const ViewFile = (props: IViewFileProps) => {

    const { hashId } = props;
    const [fileDto, setFileDto] = React.useState<FileDto>(new FileDto());

    useEffect(() => {
        getFileDtosFileByHashId(hashId, onContentChange, handleError).then(async() => {
            await getFileDtoByHashId(hashId, onSuccessfulGetPostHashIdCallback, handleError);
        });
    }, []);

    const onSuccessfulGetPostHashIdCallback = (fileDto: FileDto) => {
        const processedFileDto = fileDto;

        if (processedFileDto.hashId) {
            processedFileDto.hashedParent = hashId;
            processedFileDto.hashId = "";
        }

        setFileDto(prevState => ({
            ...prevState,
            name: processedFileDto.name,
            hashedParent: processedFileDto.hashedParent,
            termDtos: processedFileDto.termDtos,
            contentTypeSlug: processedFileDto.contentTypeSlug
        }));
    }

    const handleError = (e: Response) => {
        console.log(e);
    }

    const onContentChange = (newFile: any) => {
        setFileDto(prevState => ({
            ...prevState,
            file: newFile
        }));
    };

    return (
        <ViewPostBase
            hashId={hashId}
            postDto={fileDto}
            setPostDto={setFileDto}
        >
            <FileUploaderViewer
                file={fileDto.file}
                onDropCallback={() => null}
                readOnly
                hideDeleteIcon={true}
            />
        </ViewPostBase>
    );
}

export default ViewFile;
