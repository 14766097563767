import CardActionArea from "@mui/material/CardActionArea";
import Typography from "@mui/material/Typography";
import React from "react";
import {Card} from "@mui/material";

interface IContentTypeCard {
  name: string;
  slug: string;
  onClick: Function;
  className?: string;
}

const ContentTypeCard = (props: IContentTypeCard) => {
  const {name, slug, onClick, className} = props;

  const handleClick = () => {
    onClick(slug);
  };

  return (
    <Card>
      <CardActionArea
        onClick={handleClick}
        key={slug}
        sx={{
          height: '100%',
          padding: 2,
        }}
      >
        <Typography variant="h6" component="h2" align="center">{name}</Typography>
        <Typography variant="subtitle2" component="h3" align="center">{slug}</Typography>
      </CardActionArea>
    </Card>
  );
}

export default ContentTypeCard;