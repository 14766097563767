import ListItem from './src/ListItem';
import HighlightedCodeBlockTransforms
    from "../SlateHighlightedCodeBlockComponent/src/HighlightedCodeBlock/HighlightedCodeBlockTransforms";
import ListTransforms from "./src/ListTransforms";
import BoldLeafRendererComponent from "../CoreStyles/BoldLeafRendererComponent";
import ItalicLeafRendererComponent from "../CoreStyles/ItalicLeafRendererComponent";
import UnderlineLeafRendererComponent from "../CoreStyles/UnderlineLeafRendererComponent";
import CodeLeafRendererComponent from "../CoreStyles/CodeLeafRendererComponent";

const SlateListItemComponent = {
    author: 'Rob Reinhard',
    block: ListItem,
    decorators: undefined,
    default: undefined,
    dependencyTypes: undefined,
    leafRenderers: [BoldLeafRendererComponent.type, ItalicLeafRendererComponent.type, UnderlineLeafRendererComponent.type, CodeLeafRendererComponent.type],
    name: 'List Item',
    onDrop: false,
    onKeyDown: undefined,
    plugins: undefined,
    resetToDefault: (editor, elementPath, defaultBlock) => {
        const listPath = elementPath.slice(0,-1);

        ListTransforms.removeAndReplaceList(editor, listPath, defaultBlock);
    },
    toolbar: undefined,
    transforms: undefined,
    type: 'list-item'
};

export default SlateListItemComponent;