import CodeBlock from "./src/HighlightedCodeBlock/HighlightedCodeBlock";
import HighlightedCodeBlockPlugins from "./src/HighlightedCodeBlock/HighlightedCodeBlockPlugins";
import HighlightedCodeBlockDecorators from "./src/HighlightedCodeBlock/HighlightedCodeBlockDecorators";
import DeveloperModeIcon from '@mui/icons-material/DeveloperMode';
import HighlightedCodeBlockTransforms from "./src/HighlightedCodeBlock/HighlightedCodeBlockTransforms";

const SlateHighlightedCodeBlockComponent = {
    author: 'Rob Reinhard',
    block: CodeBlock,
    decorators: [HighlightedCodeBlockDecorators.prismDecorator],
    default: undefined,
    dependencyTypes: ['code-line'],
    leafRenderers: undefined,
    name: 'Highlighted Code Block',
    onDrop: false,
    onKeyDown: (editor, event) => {
        if (event.key === '`' && event.ctrlKey) {
            HighlightedCodeBlockTransforms.insertHighlightedCodeBlock(editor, event);
        }
    },
    plugins: [HighlightedCodeBlockPlugins.withHighlightedCodeBlockPlugin],
    resetToDefault: undefined,
    toolbar: {
        icon: DeveloperModeIcon,
        isActive: undefined,
        onClick: (editor, event) => HighlightedCodeBlockTransforms.insertHighlightedCodeBlock(editor, event)
    },
    transforms: HighlightedCodeBlockTransforms,
    type: 'code-block'
};

export default SlateHighlightedCodeBlockComponent;