import React, {useState, useEffect, useCallback, MouseEvent, useMemo} from 'react';
import GenericPostDto from '../../includes/models/GenericPostDto';
import {useHistory} from 'react-router-dom';
import {getGenericPostDtoByHashId, getGenericPostRevisionDtosByHashId} from '../../includes/api-functions/GenericPostDtoCalls';
import SlateRichTextEditor from '../reusable-components/Slate-Editors/SlateRichTextEditor';
import {EditorState} from 'draft-js';
import HighlightedCodeBlockPlugins
  from '../reusable-components/Slate-Editors/Custom-Components/SlateHighlightedCodeBlockComponent/src/HighlightedCodeBlock/HighlightedCodeBlockPlugins';
import ListPlugins from '../reusable-components/Slate-Editors/Custom-Components/SlateListComponent/src/ListPlugins';
import {createEditor, Transforms} from 'slate';
import {withReact} from 'slate-react';
import {withHistory} from 'slate-history';
import {GenericPostSlateComponents} from './GenericPostSlateComponents';
import ViewPostBase from '../reusable-components/Add-Edit-View-Default-Bases/ViewPostBase';
import {onSuccessfulGenericPost, onSuccessfulGetPostRevisions} from "./genericPostHooks";
import useSlateEditor from "../reusable-components/Slate-Editors/useSlateEditorState";

interface IViewPostProps {
    hashId: string;
}

export const ViewPostGeneric = (props: IViewPostProps) => {
  const {hashId} = props;
  const [genericPostDto, setGenericPostDto] = React.useState<GenericPostDto>(new GenericPostDto());
  const [genericPostDtoRevisions, setGenericPostDtoRevisions] = useState<GenericPostDto[]>([]);
  const [revisionIndex, setRevisionIndex] = useState<number>(0);
  const history = useHistory();

  const [editor] = useSlateEditor(GenericPostSlateComponents);

  useEffect(() => {
    getGenericPostDtoByHashId(hashId, onSuccessfulGetPostHashIdCallback, handleError).then(async () => {
      await getGenericPostRevisionDtosByHashId(hashId, onSuccessfulGetPostRevisionsCallback, handleError);
    });
  }, []);

  const onSuccessfulGetPostHashIdCallback = onSuccessfulGenericPost(hashId, setGenericPostDto);

  const onSuccessfulGetPostRevisionsCallback = onSuccessfulGetPostRevisions(setGenericPostDtoRevisions, setRevisionIndex);

  const clickEditButton = useCallback((event:MouseEvent) =>
    history.push('/edit/' + hashId),
  [history],
  );

  const handleError = (e: Response) => {
    console.log(e.status);
  };

  const onContentChange = (newEditorStateValue: EditorState) => {
    setGenericPostDto((prevState) => ({
      ...prevState,
      slateContent: newEditorStateValue,
    }));
  };

  const onPreviousClicked = (event: MouseEvent) => {
    if (revisionIndex !== 0) {
      const index = revisionIndex - 1;
      setRevisionIndex(index);
      Transforms.deselect(editor);
      onContentChange(genericPostDtoRevisions[index].slateContent);
    }
  };

  const onNextClicked = (event: MouseEvent) => {
    if (revisionIndex !== genericPostDtoRevisions.length-1) {
      const index = revisionIndex + 1;
      setRevisionIndex(index);
      Transforms.deselect(editor);
      onContentChange(genericPostDtoRevisions[index].slateContent);
    }
  };

  return (
    <ViewPostBase
      hashId={hashId}
      revisionData={{
        onNextClicked: onNextClicked,
        onPreviousClicked: onPreviousClicked,
        postDtoRevisions: genericPostDtoRevisions,
        revisionIndex: revisionIndex,
      }}
      postDto={genericPostDto}
      setPostDto={setGenericPostDto}
    >
      <SlateRichTextEditor
        content={genericPostDto.slateContent}
        editor={editor}
        onContentChange={onContentChange}
        readOnly={true}
        slateComponents={GenericPostSlateComponents}
      />
    </ViewPostBase>
  );
}
