import React, {useState, useEffect, MouseEvent, useMemo} from 'react';
import {createEditor, Transforms} from 'slate';
import {withReact} from 'slate-react';
import {withHistory} from 'slate-history';
import JokeBlockTransforms from '../slate-js/SlateJokeComponents/src/JokeBlockTransforms';
import EditPostBase from '../../../rob-horse/reusable-components/Add-Edit-View-Default-Bases/EditPostBase';
import SetlistSlateComponents from '../slate-js/SetlistSlateComponents';
import {
  getSetlistDtoByHashId,
  getSetlistRevisionDtosByHashId, postSetlistDto,
} from '../../includes/api-functions/SetlistDtoCalls';
import SetlistDto from '../../includes/models/SetlistDto';
import SetlistWriter from '../slate-js/SetlistWriter';
import StagingSetlistDto from '../../includes/models/StagingSetlistDto';
import setlistService from './setlistService';
import PostSearch from '../../../rob-horse/reusable-components/Websocket-Components/PostSearch';
import {Typography} from '@mui/material';
import {getJokeDtoByHashId, postJokeDto} from '../../includes/api-functions/JokeDtoCalls';
import {getBitDtoByHashId} from '../../includes/api-functions/BitDtoCalls';
import PostDto from '../../../includes/models/PostDto';
import SetlistLaughDto from '../../includes/models/SetlistLaughDto';
import SetlistJokeDto from '../../includes/models/SetlistJokeDto';
import JokeDto, {JokeDtoUtils} from '../../includes/models/JokeDto';
import SetlistBitDto from '../../includes/models/SetlistBitDto';
import BitDto from '../../includes/models/BitDto';
import {cloneDeep} from 'lodash';
import {TermDtoUtils} from "../../../includes/models/TermDto";
import useSlateEditor from "../../../rob-horse/reusable-components/Slate-Editors/useSlateEditorState";

interface IEditBitProps {
    hashId: string;
}

export const EditSetlist = (props: IEditBitProps) => {
  const {hashId} = props;
  const [setlistDto, setSetlistDto] = useState<SetlistDto>(new SetlistDto());
  const [setlistDtoRevisions, setSetlistDtoRevisions] = useState<SetlistDto[]>([]);
  const [revisionIndex, setRevisionIndex] = useState<number>(0);
  const [updatingInDatabase, setUpdatingInDatabase] = useState<boolean>(true);
  const [slateContent, setSlateContent] = useState<any>([JokeBlockTransforms.defaultJokeEditorBlock()]);
  const [initialSlateContent, setInitialSlateContent] = useState<any>([JokeBlockTransforms.defaultJokeEditorBlock()]);

  const [editor] = useSlateEditor(SetlistSlateComponents);

  useEffect(() => {
    getSetlistDtoByHashId(hashId, onSuccessfulGetPostHashIdCallback, handleError).then(async () => {
      await getSetlistRevisionDtosByHashId(hashId, onSuccessfulGetPostRevisionsCallback, handleError);
      setUpdatingInDatabase(false);
    });
  }, []);

  const onSuccessfulGetPostHashIdCallback = (stagingSetlistDto: StagingSetlistDto) => {
    const processedStagingSetlistDto = stagingSetlistDto;

    if (processedStagingSetlistDto.hashId) {
      processedStagingSetlistDto.hashedParent = hashId;
      processedStagingSetlistDto.hashId = '';
    }

    const newSetlistDto = setlistService.convertStagingSetlistDtoToSetlistDto(processedStagingSetlistDto);

    for (const setlistLaugh of newSetlistDto.act) {
      delete setlistLaugh.setlistLaughId;
    }

    setSetlistDto(newSetlistDto);

    const initialContent = cloneDeep(setlistService.createSetlistContent(newSetlistDto));
    setInitialSlateContent(initialContent);
  };

  const onSuccessfulGetPostRevisionsCallback = (revisions: StagingSetlistDto[]) => {
    const processedRevisions = revisions;

    const setlistDtoRevisions: SetlistDto[] = [];

    for (const revision of processedRevisions) {
      const setlistDto = setlistService.convertStagingSetlistDtoToSetlistDto(new StagingSetlistDto(revision));

      setlistDtoRevisions.push(setlistDto);
    }

    setSetlistDtoRevisions(setlistDtoRevisions);
    setRevisionIndex(processedRevisions.length-1);
  };

  const onContentChange = (newEditorStateValue: any) => {
    setSetlistDto((prevState) => ({
      ...prevState,
      act: setlistService.createNewActOnTextEditorChange(setlistDto.act, newEditorStateValue),
    }));
  };

  const handleError = (e: Response) => {
    console.log(e.status);
  };

  const handleChange = (e:any) => {
    const {name, value} = e.target;
    setSetlistDto((prevState) => ({
      ...prevState,
      [name]: value,
    }));
    console.log(setlistDto);
  };

  const onSuccessfulPostCallback = () => {
    getSetlistDtoByHashId(hashId, onSuccessfulGetPostHashIdCallback, handleError).then(async () => {
      await getSetlistRevisionDtosByHashId(hashId, onSuccessfulGetPostRevisionsCallback, handleError);
      setUpdatingInDatabase(false);
    });
  };

  const onPostAddCallback = (postDto: PostDto) => {
    let setlistLaugh: SetlistLaughDto;

    if (postDto.contentTypeSlug === 'joke') {
      setlistLaugh = new SetlistJokeDto({
        jokeDto: new JokeDto(postDto),
      });
    } else if (postDto.contentTypeSlug === 'bit') {
      setlistLaugh = new SetlistBitDto({
        bitDto: new BitDto(postDto),
      });
    }

    setSetlistDto((prevState) => ({
      ...prevState,
      act: [...prevState.act, setlistLaugh],
    }));
  };

  const addPost = async (hashId: string, contentTypeSlug: string) => {
    if (contentTypeSlug === 'joke') {
      await getJokeDtoByHashId(hashId, onPostAddCallback, handleError);
    } else if (contentTypeSlug === 'bit') {
      await getBitDtoByHashId(hashId, onPostAddCallback, handleError);
    }
  };

  const updateSetlistButtonClicked = async () => {
    setUpdatingInDatabase(true);
    await postSetlistDto(setlistDto, onSuccessfulPostCallback, handleError);
  };

  const onPreviousClicked = (event: MouseEvent) => {
    if (revisionIndex !== 0) {
      const index = revisionIndex - 1;
      setRevisionIndex(index);
      Transforms.deselect(editor);
      setSetlistDto(setlistDtoRevisions[index]);
    }
  };

  const onNextClicked = (event: MouseEvent) => {
    if (revisionIndex !== setlistDtoRevisions.length-1) {
      const index = revisionIndex + 1;
      setRevisionIndex(index);
      Transforms.deselect(editor);
      setSetlistDto(setlistDtoRevisions[index]);
    }
  };

  useEffect(() => {
    const content = setlistService.createSetlistContent(setlistDto);

    setSlateContent(content);
  }, [setlistDto]);

  const allowUpdate =
            updatingInDatabase ||
            setlistDtoRevisions.length <= 0 ||
            JSON.stringify(slateContent) !== JSON.stringify(initialSlateContent) ||
            !TermDtoUtils.equalArrays(setlistDto.termDtos, setlistDtoRevisions[0].termDtos);

  return (
    <EditPostBase
      allowUpdate={allowUpdate}
      contentTypeName="Setlist"
      handleChange={handleChange}
      hashId={hashId}
      revisionData={{
        onPreviousClicked: onPreviousClicked,
        onNextClicked: onNextClicked,
        revisionIndex: revisionIndex,
        postDtoRevisions: setlistDtoRevisions,
      }}
      postDto={setlistDto}
      setPostDto={setSetlistDto}
      updatePostButtonClicked={updateSetlistButtonClicked}
    >
      <PostSearch
        addPost={addPost}
        searchLabel={"Search for existing bits and jokes"}
        contentTypeSlugs={["bit", "joke"]}
        sx={{
          mb: 2,
        }}
      />
      <Typography variant="h4" component="h2">Setlist:</Typography>
      <SetlistWriter
        content={slateContent}
        editor={editor}
        onContentChange={onContentChange}
        slateComponents={SetlistSlateComponents}
      />
    </EditPostBase>
  );
};
