import React from 'react'
import Typography from "@mui/material/Typography";

const ListItem = (props) => {
    return (
        <li {...props.attributes}>
            <Typography>{props.children}</Typography>
        </li>
    );
};

export default ListItem
