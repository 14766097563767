import React, {useEffect, useMemo, useState} from 'react';
import {Box, Fab, Grid, IconButton, TextField, Typography} from '@mui/material';
import {Document, Page} from 'react-pdf/dist/esm/entry.webpack';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import styled from '@emotion/styled';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import DownloadButton from './DownloadButton';
import {SxProps} from "@mui/system";

interface IImageViewerProps {
    file: File,
    initialUpload?: boolean,
    sx?: SxProps
}

const PDFBox = styled(Box)`
  width: 100%;
  position: relative;
  canvas {
    width: auto !important;
    max-height: 450px;
  }
  .prevPage {
    position: absolute !important;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    z-index: 1 !important;
  }
`;

const PDFViewer = (props: IImageViewerProps) => {
  const {
    file,
    initialUpload,
    sx
  } = props;

  const [base64encoding, setBase64encoding] = useState<string>('');
  const [numPages, setNumPages] = useState<number>();
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [renderedPageNumber, setRenderedPageNumber] = useState(1);

  // PDF Any due to react-pdf typescript bug
  const onDocumentLoadSuccess = (pdf: any) => {
    setNumPages(pdf.numPages);
  };

  const onLoadError = (error: Error) => {
    console.log(Error);
  };

  const onPreviousPageClick = () => {
    if (pageNumber == 1) {
      return;
    }

    setPageNumber(pageNumber-1);
  };

  const onNextPageClicked = () => {
    if (pageNumber == numPages) {
      return;
    }

    setPageNumber(pageNumber+1);
  };

  const onPageNumberEntered = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newPageNumber = Number(event.target.value);

    if (numPages && newPageNumber >= 0 && newPageNumber <= numPages) {
      setPageNumber(newPageNumber);
    }
  };

  useEffect(() => {
    const reader = new FileReader();

    reader.addEventListener('load', async () => {
      if (typeof reader.result === 'string') {
        setBase64encoding(reader.result);
      }
    }, false);

    reader.readAsDataURL(file);
  }, [file]);

  const updateRenderedPageNumber = () => {
    setRenderedPageNumber(pageNumber);
  };

  const isLoading = renderedPageNumber !== pageNumber;

  return (
    <Grid
      container
      xs={12}
      justifyContent="space-between"
      alignItems="center"
      sx={sx}
    >
      <PDFBox
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          height: '450px',
          maxWidth: '100%',
          marginBottom: 1
        }}
      >
        <Document
          file={base64encoding}
          onLoadSuccess={onDocumentLoadSuccess}
          onLoadError={onLoadError}
        >
          <div
            style={{
              width: '100%',
              height: '100%',
            }}
          >
            {isLoading && renderedPageNumber &&
                        <Page
                          key={renderedPageNumber}
                          pageNumber={renderedPageNumber}
                          className="prevPage"
                        />
            }
            <Page
              key={pageNumber}
              pageNumber={pageNumber}
              className="currentPage"
              onRenderSuccess={updateRenderedPageNumber}
            />
          </div>
        </Document>
      </PDFBox>
      <Box
        sx={{
          width: '100%',
          display: 'flex',
          alignItems: 'center',
          paddingLeft: 3,
          paddingRight: 3,
        }}
      >
        <IconButton
          sx={{
            marginLeft: 'auto',
            marginBottom: 2,
          }}
          size="small"
          onClick={onPreviousPageClick}
        >
          <ArrowBackIcon />
        </IconButton>
        <Box
          sx={{
            marginLeft: 2,
            marginBottom: 2,
            marginRight: 2,
          }}
        >
          <Typography
            sx={{
              display: 'inline-block',
            }}
          >
                        Page
          </Typography>
          <TextField
            as="p"
            variant="standard"
            value={pageNumber}
            inputProps={{
              inputMode: 'numeric',
              pattern: '[0-9]*',
            }}
            onChange={onPageNumberEntered}
            sx={{
              marginLeft: 1,
              display: 'inline-block',
              width: `${numPages ? numPages.toString().length * 10 : 16}px`,
              verticalAlign: 'center',
            }}
          />
          <Typography
            sx={{
              marginLeft: 1,
              marginBottom: 2,
              display: 'inline-block',
            }}
          >
                        of {numPages}
          </Typography>
        </Box>
        <IconButton
          sx={{
            marginRight: 'auto',
            marginBottom: 2,
          }}
          size="small"
          onClick={onNextPageClicked}
        >
          <ArrowForwardIcon />
        </IconButton>
      </Box>
      {!initialUpload &&
                <DownloadButton
                  name={file.name}
                  href={base64encoding}
                />
      }
    </Grid>
  );
};

export default PDFViewer;
