import React from 'react'
import Typography from "@mui/material/Typography";
import {Box, IconButton} from "@mui/material";
import {ReactEditor, useSlate, useReadOnly} from "slate-react";
import {Transforms} from "slate";
import DeleteIcon from '@mui/icons-material/Delete';

const PerformanceLaughBlock = (props) => {
    const editor = useSlate();
    const path = ReactEditor.findPath(editor, props.element);

    const readOnly = useReadOnly();
    return (
        <Box
            {...props.attributes}
        >
            <Box>
                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        direction: 'row'
                    }}
                >
                    <div
                        contentEditable={false}
                        style={{
                            userSelect: 'none',
                            display: 'inherit',
                            pointerEvents: readOnly ? 'none' : 'inherit'
                        }}
                    >
                        <Typography
                            variant="h6"
                            component="h2"
                            sx={{
                                display: 'block'
                            }}
                        >
                            {props.element.name}
                        </Typography>
                    </div>
                </Box>
                <Box
                    sx={{
                        p: 2,
                    }}
                    contentEditable={false}
                >
                    {props.children}
                </Box>
            </Box>
        </Box>
    )
};

export default PerformanceLaughBlock;

