import React, {SyntheticEvent, MouseEvent, useEffect, useState} from 'react';
import {
    Autocomplete,
    CircularProgress,
    InputAdornment,
    TextField,
    Typography
} from "@mui/material";
import Search from "@mui/icons-material/Search";
import * as signalR from '@microsoft/signalr'
import {HubConnection, HubConnectionState} from '@microsoft/signalr'
import {accountService} from "../../../includes/api-authorization/services";
import PostDto from "../../../includes/models/PostDto";
import { SxProps } from '@mui/system';

interface BitJokeSearchSelectableProps {
    clearPost: () => void
}

interface BitJokeSearchProps {
    addPost: (hashId: string, contentTypeSlug: string) => void,
    searchLabel: string,
    contentTypeSlugs?: string[] | undefined,
    sx?: SxProps | undefined,
    selectable?: BitJokeSearchSelectableProps | undefined
}

const PostSearch = (props: BitJokeSearchProps) => {
    const [connection, setConnection] = useState<HubConnection>();
    const [searchedPosts, setSearchedPosts] = useState<PostDto[]>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const [searchFieldText, setSearchFieldText] = useState<string>("");
    const [open, setOpen] = React.useState(false);
    const [selectedPost, setSelectedPost] = React.useState<PostDto | null | undefined>(null);

    const {
        addPost,
        searchLabel,
        contentTypeSlugs,
        selectable,
        sx
    } = props;

    useEffect(() => {
        const signalRConnection = new signalR.HubConnectionBuilder()
          .withUrl('/signalR/postSearchHub', { accessTokenFactory: () => accountService.userValue.jwtToken})
          .withAutomaticReconnect()
          .build();

        setConnection(signalRConnection);
    }, [])

    useEffect(() => {
        if (connection) {
            connection.start()
                .then(() => {
                    connection.on('ReceivedPosts', message => {
                       setSearchedPosts(message)
                       setLoading(false);
                    });
                })
                .catch(e => console.log('Connection failed: ', e));
        }
    }, [connection]);

    const sendMessage = async (chatMessage: string) => {
        setLoading(true);

        if (connection?.state !== HubConnectionState.Connected) {
            return;
        }

        const data = {
            searchString: chatMessage,
            contentTypeSlugs: contentTypeSlugs,
            paginationParameters: {
                pageSize: 10,
                pageNumber: 1
            }
        }

        try {
            await connection.invoke('SearchPosts', data);
        }
        catch(e) {
            console.log(e);
        }
    }

    const onSearchTextFieldChange = async (event: SyntheticEvent, value : string, reason: string) => {
        console.log({value})

        if (reason === 'input') {
            setSearchFieldText(value)
            await sendMessage(value);
        }
        else if (reason === 'clear') {
            if (selectable) {
                selectable.clearPost();
                setSelectedPost(null);
            }

            setSearchFieldText("")
            await sendMessage("");
        }
    };

    const onSearchPostClicked = (event: MouseEvent<HTMLLIElement>) => {
        const target = event.currentTarget as HTMLLIElement;

        const hashId = target.getAttribute("data-hashId");
        const contentType = target.getAttribute("data-contentTypeSlug");

        if (hashId && contentType) {
            if (selectable) {
                setSelectedPost(searchedPosts.find((post) => post.hashId === hashId));
            }

            addPost(hashId as string, contentType as string);
        }
    }

    const onOpen = async () => {
        setOpen(true);
        await sendMessage(searchFieldText);
    }

    const onClose = () => setOpen(false);

    return (
        <Autocomplete
          options={searchedPosts}
          freeSolo={true}
          open={open}
          onOpen={onOpen}
          onInputChange={onSearchTextFieldChange}
          onClose={onClose}
          getOptionLabel={(option) => option.name}
          loading={loading}
          loadingText="Searching..."
          renderInput={(params) => (
            <TextField
             {...params}
             label={searchLabel}
             InputProps={{
                 ...params.InputProps,
                 endAdornment: (
                   <InputAdornment position="end">
                       {loading ? <CircularProgress color="inherit" size={20} /> : <Search/>}
                       {params.InputProps.endAdornment}
                   </InputAdornment>
                 ),
             }}
             variant="standard"
             value={searchFieldText}
            />
         )}
         renderOption={(props, option) => (
            <li
              {...props}
              data-hashId={option.hashId}
              data-contentTypeSlug={option.contentTypeSlug}
              onMouseDown={onSearchPostClicked}
              onClick={selectable ? props.onClick : onClose}
            >
                <Typography>{option.name}</Typography>
            </li>
         )}
         filterOptions={(x) => x}
         value={selectable ? selectedPost : null}
         sx={sx}
        />
    )
}

export default PostSearch;