import React, {useState, useEffect} from 'react';
import EditPostBase from "../reusable-components/Add-Edit-View-Default-Bases/EditPostBase";
import FileUploaderViewer from "../reusable-components/Material-File-Viewers/FileUploaderViewer";
import FileDto, {FileDtoUtils} from "../../includes/models/FileDto";
import {getFileDtoByHashId, getFileDtosFileByHashId} from "../../includes/api-functions/FileDtoCalls";
import {cloneDeep} from "lodash";
import {updateBasePostValues} from "../../includes/api-functions/PostDtoCalls";
import {IExistingPageProps} from "../contentTypeMapper";

export function EditFile(props: IExistingPageProps) {

    const { hashId } = props;
    const [fileDto, setFileDto] = useState<FileDto>(new FileDto());
    const [comparisonFileDto, setComparisonFileDto] = useState<FileDto>(new FileDto());
    const [allowUpdate, setAllowUpdate] =  useState<boolean>(false);

    useEffect(() => {
        getFileDtosFileByHashId(hashId, onContentChange, handleError).then(async() => {
            getFileDtoByHashId(hashId, onSuccessfulGetPostHashIdCallback, handleError).then(async () => {
                setAllowUpdate(true);
            });
        });
    }, []);

    const onSuccessfulGetPostHashIdCallback = (newFileDto: FileDto) => {
        const processedFileDto = newFileDto;

        const comparisonFileDto = cloneDeep(processedFileDto);
        setComparisonFileDto(comparisonFileDto);

        setFileDto(prevState => ({
            ...prevState,
            name: processedFileDto.name,
            hashedParent: processedFileDto.hashedParent,
            hashId: processedFileDto.hashId,
            termDtos: processedFileDto.termDtos,
            contentTypeSlug: processedFileDto.contentTypeSlug
        }));
    }

    const onContentChange = (newFile: any) => {
        setFileDto(prevState => ({
            ...prevState,
            file: newFile
        }));
    };

    const handleError = (e: Response) => {
        console.log(e.status);
    }

    const handleChange = (e:any) => {
        const { name, value } = e.target;
        setFileDto(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const onSuccessfulPutCallback = () => {
        getFileDtoByHashId(hashId, onSuccessfulGetPostHashIdCallback, handleError).then(async () => {
            setAllowUpdate(true);
        });
    }

    const updateFileButtonClicked = async () => {
        setAllowUpdate(false);
        await updateBasePostValues(fileDto, onSuccessfulPutCallback, handleError);
    }

    const disableUpdate =
        allowUpdate &&
        FileDtoUtils.equals(fileDto, comparisonFileDto);

    return (
        <EditPostBase
            allowUpdate={!disableUpdate}
            contentTypeName="File"
            handleChange={handleChange}
            hashId={hashId}
            postDto={fileDto}
            setPostDto={setFileDto}
            updatePostButtonClicked={updateFileButtonClicked}
        >
            <FileUploaderViewer
                file={fileDto.file}
                onDropCallback={() => null}
                readOnly
                hideDeleteIcon={true}
            />
        </EditPostBase>
    );
}

export default EditFile;
