import SetlistJokeDto from "../../includes/models/SetlistJokeDto";
import SetlistBitDto from "../../includes/models/SetlistBitDto";
import SetlistDto from "../../includes/models/SetlistDto";
import BitDto from "../../includes/models/BitDto";
import StagingSetlistDto from "../../includes/models/StagingSetlistDto";
import SetlistLaughDto from "../../includes/models/SetlistLaughDto";
import JokeDto from "../../includes/models/JokeDto";
import {cloneDeep} from "lodash";
import JokePerformanceDto from "../../includes/models/JokePerformanceDto";

const setupBitJokeContent = (jokeDto: JokeDto, performanceHashId: string | undefined, newPerformance: boolean) => {
  const jokeDtoContent = jokeDto.slateContent;
  jokeDtoContent.hashId = jokeDto.hashId;
  jokeDtoContent.rating = getRating(jokeDto.jokePerformanceDtos, performanceHashId, newPerformance);
  jokeDtoContent.key = Math.random();

  return jokeDtoContent
}

const setupBit = (bitDto: BitDto, performanceHashId: string | undefined, newPerformance: boolean) => {
  const content = bitDto.jokes.map((joke) => {
    return setupBitJokeContent(joke, performanceHashId, newPerformance);
  });
  return content;
}

const getRating = (jokePerformanceDtos: JokePerformanceDto[], performanceHashId: string | undefined, newPerformance: boolean = false) : number | undefined => {
  if (performanceHashId) {
    return jokePerformanceDtos?.find((jp) => jp.performanceHashId == performanceHashId)?.rating;
  }
  else if (newPerformance) {
    return jokePerformanceDtos[0]?.rating;
  }
  else {
    return undefined;
  }
}

const createSetlistContent = (setlistDto: SetlistDto, performanceHashId: string | undefined = undefined, newPerformance: boolean = false) => {
  const content = [];
  for (const setlistLaugh of setlistDto.act) {
    if (setlistLaugh instanceof SetlistJokeDto) {
      const children = setlistLaugh.jokeDto.slateContent;
      children.hashId = setlistLaugh.jokeDto.hashId;
      children.rating = getRating(setlistLaugh.jokeDto.jokePerformanceDtos, performanceHashId, newPerformance);
      children.key = Math.random();

      const setlistContent = {
        children: [children],
        type: 'setlist-laugh',
        hashId: setlistLaugh.jokeDto.hashId,
        name: setlistLaugh.jokeDto.name,
      };

      content.push(setlistContent);
    } else if (setlistLaugh instanceof SetlistBitDto) {
      const setlistContent = {
        type: 'setlist-laugh',
        hashId: setlistLaugh.bitDto.hashId,
        name: setlistLaugh.bitDto.name,
        children: setupBit(setlistLaugh.bitDto, performanceHashId, newPerformance),
      };

      content.push(setlistContent);
    }
  }

  return content;
};

const convertStagingSetlistDtoToSetlistDto = (stagingSetlistDto: StagingSetlistDto) : SetlistDto => {
  let act: SetlistLaughDto[] = [];

  for (const setlistBit of stagingSetlistDto.setlistBitsInAct) {
    act.push(new SetlistBitDto(setlistBit));
  }

  for (const setlistBit of stagingSetlistDto.setlistJokesInAct) {
    act.push(new SetlistJokeDto(setlistBit));
  }

  act.sort((a, b) => (a.order || 0) - (b.order || 0))

  return new SetlistDto({
    ...stagingSetlistDto,
    act
  });
}

const convertSetlistDtoToStagingSetlistDto = (setlistDto: SetlistDto) : StagingSetlistDto =>  {
  const clonedSetlistDto = cloneDeep(setlistDto);
  let setlistBitsInAct: SetlistBitDto[] = [];
  let setlistJokesInAct: SetlistJokeDto[] = [];


  clonedSetlistDto.act.forEach((setlistLaughDto, index) => {
    setlistLaughDto.order = index;

    if (setlistLaughDto instanceof SetlistJokeDto) {
      setlistJokesInAct.push(setlistLaughDto)
    }
    else if (setlistLaughDto instanceof SetlistBitDto) {
      setlistBitsInAct.push(setlistLaughDto);
    }
  });

  delete clonedSetlistDto.act;

  const stagingSetlistDto = new StagingSetlistDto({
    ...clonedSetlistDto,
    setlistBitsInAct,
    setlistJokesInAct
  })

  return stagingSetlistDto;
}

const createNewActOnTextEditorChange = (act: SetlistLaughDto[], slateContent: any) : SetlistLaughDto[] => {
  let newAct: SetlistLaughDto[] = [];

  for (const setlistLaughTextObject of slateContent) {
    if (setlistLaughTextObject.type === 'setlist-laugh') {
      const setlistLaughDto = act.find((setlistLaugh) => {
        if (setlistLaugh instanceof SetlistJokeDto) {
          return (setlistLaugh as SetlistJokeDto).jokeDto.hashId === setlistLaughTextObject.hashId;
        }
        else if (setlistLaugh instanceof SetlistBitDto) {
          return (setlistLaugh as SetlistBitDto).bitDto.hashId === setlistLaughTextObject.hashId;
        }
      });

      if (setlistLaughDto) {
        newAct.push(setlistLaughDto);
      }
    }
  }

  return newAct;
}

export default {
  createSetlistContent,
  convertStagingSetlistDtoToSetlistDto,
  convertSetlistDtoToStagingSetlistDto,
  createNewActOnTextEditorChange
}