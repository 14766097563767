import React from 'react';
import AdapterMoment from '@mui/lab/AdapterMoment';
import Typography from '@mui/material/Typography';
import moment from 'moment/moment';
import {CalendarPicker, LocalizationProvider} from '@mui/lab';

interface IDateViewerProps {
    date: string
}

const DateViewer = (props: IDateViewerProps) => {
  const {date} = props;

  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <Typography
        sx={{
          width: '320px',
          margin: '0 auto',
          paddingLeft: '24px',
          paddingRight: '24px',
        }}
        variant="subtitle2"
      >DATE</Typography>
      <Typography
        sx={{
          width: '320px',
          margin: '0 auto',
          paddingLeft: '24px',
          paddingRight: '24px',
        }}
        variant="h4"
      >{moment.utc(date).local().format('ddd, MMM D')}</Typography>
      <CalendarPicker
        date={moment.utc(date).local()}
        disabled
        onChange={() => {}}
      />
    </LocalizationProvider>
  );
};

export default DateViewer;
