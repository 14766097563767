import styled from '@emotion/styled';
import {Link} from 'react-router-dom';

const NoLinkDecoration = styled(Link)`
    text-decoration: none;
    color: inherit;
    height: 100%;
    &:hover {
        color: inherit;
        height: 100%;
    }
`;

export default NoLinkDecoration;
