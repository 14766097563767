import React, {useEffect, useState} from 'react'
import Typography from "@mui/material/Typography";
import {Box, Grid, Icon, Rating} from "@mui/material";
import {useSlate} from "slate-react";
import {Transforms} from "slate";

const JokePerformanceBlock = (props) => {
    const editor = useSlate();

    const {
        element: {
            rating,
            key
        }
    } = props;

    const onRatingChange = (event, newValue) => {
        event.preventDefault();
        Transforms.setNodes(
            editor,
            {
                type: "joke",
                rating: newValue,
                key: key
            },
            {
                at: [],
                match: (node, path) => {
                    return node.key && node.key === key;
                }
            }
        )
    };

    return (
        <Box
            {...props.attributes}
            sx={{
                display: 'flex',
                alignItems: "center",
                justifyContent: "space-between"
            }}

        >
            <Typography>{props.children}</Typography>
            <Grid
                item
                contentEditable={false}
                sx={{
                    width: '120px'
                }}
            >
                <Rating onChange={onRatingChange} value={rating}/>
            </Grid>

        </Box>
    );
};

export default JokePerformanceBlock;

