import {FormatUnderlined} from "@mui/icons-material";
import {disableStyle, getActiveStyles, toggleStyle} from "./MarkUtils";
import React from "react";
import UnderlineLeafRenderer from "./UnderlineLeafRenderer";

const underlineLeafRendererType = 'underline';

const UnderlineLeafRendererComponent = {
    author: 'Rob Reinhard',
    block: undefined,
    decorators: undefined,
    default: undefined,
    dependencyTypes: undefined,
    leafRenderers: undefined,
    leafRenderer: UnderlineLeafRenderer,
    name: 'Underline Leaf Renderer',
    onDrop: false,
    onKeyDown: (editor, event) => {
        if (event.key === 'u' && event.metaKey) {
            event.preventDefault();
            toggleStyle(editor, underlineLeafRendererType);
        }
    },
    plugins: undefined,
    resetToDefault: undefined,
    toolbar: {
        icon: FormatUnderlined,
        isActive: (editor) => {
            return getActiveStyles(editor).has(underlineLeafRendererType);
        },
        disabled: (editor, slateComponents) => {
            return disableStyle(editor, slateComponents, underlineLeafRendererType);
        },
        onClick: (editor, event) => {
            event.preventDefault();
            toggleStyle(editor, underlineLeafRendererType);
        }
    },
    transforms: undefined,
    type: underlineLeafRendererType
};

export default UnderlineLeafRendererComponent;