import React from 'react';

import {PostMaterialTable} from './reusable-components/MaterialTable-Components/PostMaterialTable';

export const Posts = () => {
  return (
    <PostMaterialTable
      pageTitle="Posts"
      addNew
    />
  );
}
