import React from "react";

const ItalicLeafRenderer = ({attributes, children, leaf}, content) => {
    if (leaf.italic) {
        return <em>{content}</em>;
    }
    else {
        return content;
    }
}

export default ItalicLeafRenderer;