import JokeBlockTransforms from "./src/JokeBlockTransforms";
import JokeBlock from "./src/JokeBlock";
import BoldLeafRendererComponent
    from "../../../../rob-horse/reusable-components/Slate-Editors/Custom-Components/CoreStyles/BoldLeafRendererComponent";
import ItalicLeafRendererComponent
    from "../../../../rob-horse/reusable-components/Slate-Editors/Custom-Components/CoreStyles/ItalicLeafRendererComponent";
import UnderlineLeafRendererComponent
    from "../../../../rob-horse/reusable-components/Slate-Editors/Custom-Components/CoreStyles/UnderlineLeafRendererComponent";

const SlateJokeBlockComponent = {
    author: 'Rob Reinhard',
    block: JokeBlock,
    decorators: undefined,
    default: false,
    defaultResetHandler: undefined,
    dependencyTypes: undefined,
    leafRenderers: [BoldLeafRendererComponent.type, ItalicLeafRendererComponent.type, UnderlineLeafRendererComponent.type],
    name: 'Joke Block',
    onDrop: undefined,
    onKeyDown: undefined,
    plugins: undefined,
    resetToDefault: undefined,
    toolbar: undefined,
    transforms: undefined,
    type: 'joke'
};

export default SlateJokeBlockComponent;