import React, {useState, useEffect, MouseEvent, useMemo} from 'react';
import ViewPostBase from '../../../rob-horse/reusable-components/Add-Edit-View-Default-Bases/ViewPostBase';
import {Transforms} from 'slate';
import JokeDto from '../../includes/models/JokeDto';
import SlateRichTextEditor from '../../../rob-horse/reusable-components/Slate-Editors/SlateRichTextEditor';
import {getJokeDtoByHashId, getJokeRevisionDtosByHashId} from '../../includes/api-functions/JokeDtoCalls';
import SingleJokeComponents from '../slate-js/SingleJokeComponents';
import {Alert} from '@mui/material';
import useSlateEditor from "../../../rob-horse/reusable-components/Slate-Editors/useSlateEditorState";
import {onSuccessfulGetJoke, onSuccessfulGetJokeRevisions} from "./jokeHooks";


interface IViewPostProps {
    hashId: string;
}

export function ViewJoke(props: IViewPostProps) {
  const {hashId} = props;
  const [jokeDto, setJokeDto] = React.useState<JokeDto>(new JokeDto());
  const [jokeDtoRevisions, setJokeDtoRevisions] = useState<JokeDto[]>([]);
  const [revisionIndex, setRevisionIndex] = useState<number>(0);

  const [editor] = useSlateEditor(SingleJokeComponents);

  useEffect(() => {
    getJokeDtoByHashId(hashId, onSuccessfulGetPostHashIdCallback, handleError).then(async () => {
      await getJokeRevisionDtosByHashId(hashId, onSuccessfulGetPostRevisionsCallback, handleError);
    });
  }, []);

  const onSuccessfulGetPostHashIdCallback = onSuccessfulGetJoke(hashId, setJokeDto);

  const onSuccessfulGetPostRevisionsCallback = onSuccessfulGetJokeRevisions(setJokeDtoRevisions, setRevisionIndex);

  const handleError = (e: Response) => {
    console.log(e.status);
  };

  const onContentChange = (newEditorStateValue: any) => {
    setJokeDto((prevState) => ({
      ...prevState,
      content: newEditorStateValue.children,
    }));
  };

  const onPreviousClicked = (event: MouseEvent) => {
    if (revisionIndex !== 0) {
      const index = revisionIndex - 1;
      setRevisionIndex(index);
      Transforms.deselect(editor);
      onContentChange(jokeDtoRevisions[index].slateContent);
    }
  };

  const onNextClicked = (event: MouseEvent) => {
    if (revisionIndex !== jokeDtoRevisions.length-1) {
      const index = revisionIndex + 1;
      setRevisionIndex(index);
      Transforms.deselect(editor);
      onContentChange(jokeDtoRevisions[index].slateContent);
    }
  };

  return (
    <ViewPostBase
      hashId={hashId}
      revisionData={{
        onNextClicked: onNextClicked,
        onPreviousClicked: onPreviousClicked,
        postDtoRevisions: jokeDtoRevisions,
        revisionIndex: revisionIndex,
      }}
      postDto={jokeDto}
      setPostDto={setJokeDto}
    >
      {jokeDto.dependent &&
                <Alert
                  severity="warning"
                  sx={{
                    marginTop: 2,
                    marginBottom: 2,
                  }}>
                        This joke is part of a larger bit.
                </Alert>
      }
      <SlateRichTextEditor
        content={jokeDto.slateContent}
        editor={editor}
        onContentChange={onContentChange}
        readOnly={true}
        slateComponents={SingleJokeComponents}
      />
    </ViewPostBase>
  );
}
