import React, {useState, useCallback, useEffect, useMemo} from 'react';
import JokeDto from '../../includes/models/JokeDto';
import SingleJokeComponents from '../slate-js/SingleJokeComponents';
import AddPostBase from '../../../rob-horse/reusable-components/Add-Edit-View-Default-Bases/AddPostBase';
import SlateRichTextEditor from '../../../rob-horse/reusable-components/Slate-Editors/SlateRichTextEditor';
import {useHistory} from 'react-router-dom';
import {postJokeDto} from '../../includes/api-functions/JokeDtoCalls';
import {INewPostProps} from "../../../rob-horse/contentTypeMapper";
import useSlateEditor from "../../../rob-horse/reusable-components/Slate-Editors/useSlateEditorState";

const AddJoke = (props: INewPostProps) => {
  const {contentTypeSlug} = props;
  const [jokeDto, setJokeDto] = useState<JokeDto>(new JokeDto());
  const history = useHistory();
  const [allowSubmission, setAllowSubmission] = useState<boolean>(false);

  const [editor] = useSlateEditor(SingleJokeComponents);

  useEffect(() => {
    setJokeDto(new JokeDto({contentTypeSlug}));
  }, [contentTypeSlug]);

  const onContentChange = (newEditorStateValue: any) => {
    setJokeDto((prevState) => ({
      ...prevState,
      slateContent: newEditorStateValue,
    }));
  };

  const goToEditPage = useCallback((jokeDto:JokeDto) =>
    history.push(`/edit/${jokeDto.hashId}`),
  [history],
  );

  const handleChange = (e:any) => {
    const {name, value} = e.target;
    setJokeDto((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const publishGenericPostButtonClicked = async () => {
    setAllowSubmission(true);
    await postJokeDto(jokeDto, goToEditPage, handleError);
  };

  const handleError = (e: Response) => {
    console.log(e.status);
  };

  return (
    <AddPostBase
      allowSubmission={allowSubmission}
      contentTypeName="Joke"
      handleChange={handleChange}
      postDto={jokeDto}
      setPostDto={setJokeDto}
      publishPostButtonClicked={publishGenericPostButtonClicked}
    >
      <SlateRichTextEditor
        content={jokeDto.slateContent}
        editor={editor}
        onContentChange={onContentChange}
        readonly={0}
        slateComponents={SingleJokeComponents}
        type="joke-editor"
      />
    </AddPostBase>
  );
};

export default AddJoke;
