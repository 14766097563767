import HighlightedCodeLineLeafRenderer from "./src/HighlightedCodeLine/HighlightedCodeLineLeafRenderer";

const SlateHighlightedCodeLineLeafRendererComponent = {
    author: 'Rob Reinhard',
    block: undefined,
    decorators: undefined,
    default: undefined,
    dependencyTypes: undefined,
    leafRenderers: undefined,
    leafRenderer: HighlightedCodeLineLeafRenderer,
    name: 'Highlighted Code Line Leaf Renderer',
    onDrop: false,
    onKeyDown: undefined,
    plugins: undefined,
    resetToDefault: undefined,
    toolbar: undefined,
    transforms: undefined,
    type: 'code-line-leaf-renderer'
};

export default SlateHighlightedCodeLineLeafRendererComponent;