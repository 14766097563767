import { Node } from 'slate'
import BitDto from '../models/BitDto';
import { fetchWrapper } from "../../../includes/api-authorization/helpers";
import { cloneDeep } from 'lodash';
import SlateJSON from "../../../rob-horse/reusable-components/Slate-Editors/Custom-Components/SlateJSON";

export const postBitDto = async (bitDto: BitDto, onSuccessToEditCallback: (bit: BitDto) => void, onErrorCallback: (error: Response) => void) => {
    const postToPublish = cloneDeep(bitDto);
    postToPublish.jokes.forEach((jokeInBit) => {
        jokeInBit.name = jokeInBit.slateContent.children.map((node: any) => Node.string(node)).join(' ');
    });

    await fetchWrapper.post('api/comedy/bit', postToPublish, SlateJSON.stringify).then(onSuccessToEditCallback,onErrorCallback);
};

export const getBitDtoByHashId = async (hashId: string, onSuccessCallback: (bitDto: BitDto) => void, onErrorCallback: (error: Response) => void) => {
    await fetchWrapper.get('api/comedy/bit/'+hashId).then(onSuccessCallback,onErrorCallback);
}

export const getBitRevisionDtosByHashId = async (hashId: string, onSuccessCallback: (value: BitDto[]) => void, onErrorCallback: (error: Response) => void) => {
    await fetchWrapper.get('api/comedy/bit/revisions/'+hashId).then(onSuccessCallback,onErrorCallback);
}