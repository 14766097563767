import PostDto from './PostDto';
import FileDto from "./FileDto";
import ParagraphBlockTransforms
    from "../../rob-horse/reusable-components/Slate-Editors/Custom-Components/SlateParagraphBlockComponent/src/ParagraphBlockTransforms";

export default class UserProfileDto extends PostDto {
    contentTypeSlug: string = "user-profile";
    coverPhotoDto: FileDto | null = null;
    profilePhotoDto: FileDto | null = null;
    description = {
        type: "editor",
        children: [ParagraphBlockTransforms.defaultParagraphBlock()]
    };

    constructor(userprofileDto?: Partial<UserProfileDto>) {
        super();
        Object.assign(this, userprofileDto);
    };
}

