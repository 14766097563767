import React from "react";

import IconButton from "@mui/material/IconButton";

const BlockButton = (props) => {
    const {
        icon: Icon,
        type,
        isActive,
        disabled,
        onClick
    } = props;

    return (
        <IconButton
            aria-label={type}
            color={isActive ? 'secondary' : 'default'}
            disabled={disabled}
            onMouseDown={onClick}
            size="large">
            <Icon />
        </IconButton>
    );
};

export default BlockButton;