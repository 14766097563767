import React, {useState, useEffect, MouseEvent} from 'react';
import {Transforms} from "slate";
import BitSlateComponents from "../slate-js/BitSlateComponents";
import {getBitDtoByHashId, getBitRevisionDtosByHashId, postBitDto} from "../../includes/api-functions/BitDtoCalls";
import BitDto, {BitDtoUtils} from "../../includes/models/BitDto";
import JokeBlockTransforms from "../slate-js/SlateJokeComponents/src/JokeBlockTransforms";
import BitWriter from "../slate-js/BitWriter";
import {cloneDeep} from "lodash";
import EditPostBase from "../../../rob-horse/reusable-components/Add-Edit-View-Default-Bases/EditPostBase";
import JokeDto from "../../includes/models/JokeDto";
import {TermDtoUtils} from "../../../includes/models/TermDto";
import useSlateEditor from "../../../rob-horse/reusable-components/Slate-Editors/useSlateEditorState";
import {setSlateJokeContent} from "../Joke/jokeHooks";

interface IEditBitProps {
    hashId: string;
}

export function EditBit(props: IEditBitProps) {

    const { hashId } = props;
    const [bitDto, setBitDto] = useState<BitDto>(new BitDto());
    const [bitDtoRevisions, setBitDtoRevisions] = useState<BitDto[]>([]);
    const [revisionIndex, setRevisionIndex] = useState<number>(0);
    const [updatingInDatabase, setUpdatingInDatabase] =  useState<boolean>(true);
    const [slateContent, setSlateContent] = useState<any>({
        type: "bit-editor",
        children: [JokeBlockTransforms.defaultJokeEditorBlock()]
    });
    const [initialSlateContent, setInitialSlateContent] = useState<any>({
        type: "bit-editor",
        children: [JokeBlockTransforms.defaultJokeEditorBlock()]
    });

    const [editor] = useSlateEditor(BitSlateComponents);

    useEffect(() => {
        getBitDtoByHashId(hashId, onSuccessfulGetPostHashIdCallback, handleError).then(async () => {
            await getBitRevisionDtosByHashId(hashId, onSuccessfulGetPostRevisionsCallback, handleError);
            setUpdatingInDatabase(false);
        });
    }, []);

    function setupJokeContent(jokeDtos: JokeDto[]) {
        const content = jokeDtos.map((joke) => {
            joke = setSlateJokeContent(joke);

            joke.slateContent[0]["hashedParent"] = joke.hashId;
            return joke.slateContent[0]
        });
        return content;
    }

    const onSuccessfulGetPostHashIdCallback = (bitDto: BitDto) => {
        const processedBitDto = bitDto;

        if (processedBitDto.hashId) {
            processedBitDto.hashedParent = hashId;
            processedBitDto.hashId = "";
        }

        const content = {
            type: "bit-editor",
            children: processedBitDto.jokes.map((joke) => setSlateJokeContent(joke).slateContent)
        };

        setBitDto(new BitDto(processedBitDto));
        setSlateContent(content);

        const initialContent = cloneDeep(content);
        setInitialSlateContent(initialContent);
    }

    const onSuccessfulGetPostRevisionsCallback = (revisions: BitDto[]) => {
        const processedRevisions = revisions;

        for (let revision of processedRevisions) {

            revision = new BitDto(revision);
        }

        setBitDtoRevisions(processedRevisions);
        setRevisionIndex(processedRevisions.length-1);
    }

    const onContentChange = (newEditorStateValue: any) => {
        setSlateContent(newEditorStateValue);
    };

    const handleError = (e: Response) => {
        console.log(e.status);
    }

    const handleChange = (e:any) => {
        const { name, value } = e.target;
        setBitDto(prevState => ({
            ...prevState,
            [name]: value
        }));
        console.log(bitDto);
    };

    const onSuccessfulPostCallback = () => {
        getBitDtoByHashId(hashId, onSuccessfulGetPostHashIdCallback, handleError).then(async () => {
            await getBitRevisionDtosByHashId(hashId, onSuccessfulGetPostRevisionsCallback, handleError);
            setUpdatingInDatabase(false);
        });
    }

    const updateGenericPostButtonClicked = async () => {
        setUpdatingInDatabase(true);
        setBitDto(prevState => ({
            ...prevState,
            jokes: BitDtoUtils.createJokeContentFromSlateContent(slateContent)
        }));
    }

    useEffect(() => {
        if (updatingInDatabase && bitDto.jokes.length > 0) {
            postBitDto(bitDto,onSuccessfulPostCallback, handleError);
        }
    }, [updatingInDatabase, bitDto]);

    const onPreviousClicked = (event: MouseEvent) => {
        if (revisionIndex !== 0) {
            const index = revisionIndex - 1;
            setRevisionIndex(index);
            Transforms.deselect(editor)
            const content = setupJokeContent(bitDtoRevisions[index].jokes);
            onContentChange(content);
        }
    }

    const onNextClicked = (event: MouseEvent) => {
        if (revisionIndex !== bitDtoRevisions.length-1) {
            const index = revisionIndex + 1;
            setRevisionIndex(index);
            Transforms.deselect(editor)
            const content = setupJokeContent(bitDtoRevisions[index].jokes);
            onContentChange(content);
        }
    }

    const allowUpdate =
            updatingInDatabase ||
            bitDtoRevisions.length <= 0 ||
            JSON.stringify(slateContent) !== JSON.stringify(initialSlateContent) ||
            !TermDtoUtils.equalArrays(bitDto.termDtos, bitDtoRevisions[0].termDtos);

    return (
        <EditPostBase
            allowUpdate={allowUpdate}
            contentTypeName="Bit"
            handleChange={handleChange}
            hashId={hashId}
            revisionData={{
                onPreviousClicked:onPreviousClicked,
                onNextClicked:onNextClicked,
                revisionIndex:revisionIndex,
                postDtoRevisions:bitDtoRevisions
            }}
            postDto={bitDto}
            setPostDto={setBitDto}
            updatePostButtonClicked={updateGenericPostButtonClicked}
        >
            <BitWriter
                content={slateContent}
                editor={editor}
                onContentChange={onContentChange}
                readonly={0}
                slateComponents={BitSlateComponents}
            />
        </EditPostBase>
    );
}
