import React from 'react';
import { Route } from 'react-router-dom';

export function RouteWithLayout({ component: Component, layout: Layout, ...rest }) {

    return (
        <Layout>
            <Route component={Component} {...rest} /> 
        </Layout>
    );


}