import React from 'react';
import styled from "@emotion/styled";
import {useTheme} from "@mui/material/styles";

const StickyDiv = styled.div`
  position: -webkit-sticky;
  position: sticky;
  top: 56px;
  padding-top: 5px;
  padding-bottom: 5px;
  background-color: ${props => props.theme.palette.background.default};
  z-index: 3;

  @media (min-width: 0px) {
    @media (orientation: landscape) {
      top: 48px; 
    }
  }
  
  @media (min-width: 600px) {
    top: 64px;
  }
`;

const SlateToolbar = (props) => {
    const theme = useTheme();

    return (
        <StickyDiv theme={theme}>
            {props.children}
        </StickyDiv>
    );

};

export default SlateToolbar;