import {Editor, Transforms, Node} from "slate";

const defaultHighlightedCodeBlock = () => {
    return {
        children: [defaultHighlightedCodeLine()],
        type: 'code-block' ,
        language: 'js',
        key: Math.random()
    }
};

const defaultHighlightedCodeLine = () => {
    return {
        type: 'code-line',
        children: [{
            text: ''
        }],
        key: Math.random()
    };
};

const insertHighlightedCodeBlock = (editor, event) => {
    if (!editor.selection) {
        Transforms.select(editor, [editor.children.length-1]);
    }

    const leafNode = Node.get(editor, editor.selection.anchor.path);
    if (editor.selection.anchor.path.toString() === editor.selection.focus.path.toString()
        && editor.selection.anchor.offset === 0
        && editor.selection.focus.offset === 0
        && leafNode.text === '') {

        Transforms.setNodes(
            editor,
            defaultHighlightedCodeBlock(),
            {
                match: n => Editor.isBlock(editor, n) && n.type !== 'code-line'
            }
        );
    }
    else {
        Transforms.insertNodes(
            editor,
            defaultHighlightedCodeBlock(),
            {
                match: n => Editor.isBlock(editor, n) && n.type !== 'code-line'
            }
        )
    }
};

const exitHighlightedCodeBlock = (editor, path, escapedElement) => {
    const codeBlockPath = path.slice(0,-1);
    codeBlockPath[0] = codeBlockPath[0] + 1;
    Transforms.insertNodes(editor, escapedElement, {
        at: codeBlockPath
    });

    Transforms.select(editor, codeBlockPath);
}

const removeAndReplaceHighlightedCodeBlock = (editor, path, escapedElement) => {
    Transforms.removeNodes(editor, {
        at: path
    });
    Transforms.insertNodes(editor, escapedElement, {at: path});
    Transforms.select(editor, path);
};

export default {
    defaultHighlightedCodeLine,
    exitHighlightedCodeBlock,
    insertHighlightedCodeBlock,
    removeAndReplaceHighlightedCodeBlock
}