import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { accountService } from '../api-authorization/services';

export function PrivateRouteWithLayout({ component: Component, layout: Layout, roles, ...rest }) {

    return (
        <Route {...rest} render={props => {
            const user = accountService.userValue;
            if (user === 0) {
                // not logged in so redirect to login page with the return url
                return <Redirect to={{ pathname: '/login', state: { from: props.location } }} />
            }

            // check if route is restricted by role
            if (user !== 0 && roles && roles.indexOf(user.role) === -1) {
                // role not authorized so redirect to home page
                return <Redirect to={{ pathname: '/'}} />
            }

            if (user === null) {
                return <div></div>
            }

            // authorized so return component
            if (user) {
                return <Layout><Component {...props} /></Layout>
            }
        }} />
    );
    
}