import React from 'react'
import Typography from "@mui/material/Typography";
import {Box} from "@mui/material";

const JokeBlock = (props) => {
    return (
        <Box
            {...props.attributes}
        >
            <Typography
                sx={{
                    display: 'inline-block'
                }}
            >
                {props.children}
            </Typography>
        </Box>
    )
};

export default JokeBlock;

