import React, {useCallback, useEffect, useState, ChangeEvent} from 'react';
import {AgGridEvent, ColDef, GridApi} from 'ag-grid-community';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import {FormControl, InputAdornment, InputLabel, Paper, SelectChangeEvent, TextField} from '@mui/material';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Button from '@mui/material/Button';
import Search from '@mui/icons-material/Search';
import {useHistory} from 'react-router-dom';
import TermDto from '../../../includes/models/TermDto';
import PaginatedMaterialTable from "./PaginatedMaterialTable";
import CenteredSpinner from "../CenteredSpinner/CenteredSpinner";
import {debounce} from "lodash";
import {getTerms} from "../../../includes/api-functions/TermDtoCalls";
import TermQueryParameters from "../../../includes/data-transfer-objects/TermQueryParameters";
import TermSearchProps from "../../../includes/data-transfer-objects/TermSearchParams";
import TermRow from "./TermRow";

enum TermBulkActions {
    None,
    Delete
}

const columnDefinitions: ColDef[] = [
  {
    cellRenderer: 'termRenderer',
    field: 'name',
    checkboxSelection: true,
  },
  {
    field: 'slug',
    hide: true
  },
  {
    field: 'description',
    hide: true
  },
];

const defColDefs: ColDef = {
  flex: 1,
  wrapText: true,
  autoHeight: true,
  suppressMovable: true,
};

const options = {
  rowSelection: 'multiple',
  suppressCellSelection: true,
  suppressRowClickSelection: true,
};

const frameworkComponents = {
  termRenderer: TermRow,
};

interface PostMaterialTableProps {
    addNew?: boolean;
    pageTitle: String,
    children?: React.ReactNode;
    termSearchProps?: TermSearchProps;
    refreshTerms: boolean;
}

const TermMaterialTable = (props: PostMaterialTableProps) => {
  const [terms, setTerms] = useState<TermDto[]>([]);
  const [recordsCount, setRecordsCount] = useState<number>(0);
  const [bulkChangeSelected, setBulkChangeSelected] = useState<TermBulkActions>(TermBulkActions.None);
  const [gridApi, setGridApi] = useState<GridApi | undefined>();
  const [termQueryParameters, setTermQueryParameters] = useState<TermQueryParameters>();
  const history = useHistory();

  const {
    addNew,
    children,
    pageTitle,
    termSearchProps,
    refreshTerms,
  } = props;

  useEffect(() => {
    setRecordsCount(0);
    setTermQueryParameters(() => ({
      ...termSearchProps,
      paginationParameters: {
        pageNumber: 1,
        pageSize: 10
      },
      searchString: "",
    }));
  }, [termSearchProps]);

  const onSuccessSetData = (parsedResponse: any) => {
    setTerms(parsedResponse.parsedBody);

    setRecordsCount(Number.parseInt(parsedResponse.headers.get('RecordsCount')));
  }

  useEffect(() => {
    if (termQueryParameters === undefined || !refreshTerms) {
      return;
    }

    gridApi?.showLoadingOverlay();
    getTerms(termQueryParameters, onSuccessSetData, handleError).then(async () => {
      gridApi?.hideOverlay();
    });
  }, [termQueryParameters, refreshTerms]);

  useEffect(() => {
    gridApi?.setColumnDefs(columnDefinitions);
  }, [columnDefinitions, gridApi]);

  useEffect(() => {
    if (gridApi) {
      const gridListen = () => {
        resizeListener(gridApi);
      };
      window.addEventListener('resize', gridListen);

      return () => {
        window.removeEventListener('resize', gridListen);
      };
    }
  }, [gridApi]);

  const onSearchFieldChanged = (searchString: string) => {
    if (searchString === termQueryParameters?.searchString) {
      return;
    }

    setTermQueryParameters(prevState => {
      if (prevState !== undefined) {
        return ({
          ...prevState,
          searchString: searchString,
          paginationParameters: {
            pageSize: prevState?.paginationParameters.pageSize,
            pageNumber: 1
          }
        })
      }

      return prevState;
    });
  };

  const onSearchDebounced = useCallback(debounce(onSearchFieldChanged, 1000), []);

  const onSearchTextFieldChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
    onSearchDebounced(event.target.value);
  }

  const onBulkChangeSelected = (event: SelectChangeEvent<unknown>) => {
    setBulkChangeSelected(event.target.value as TermBulkActions);
  };

  const resizeListener = (api: GridApi) => {
    api.sizeColumnsToFit();
  };

  const onGridReadyCallback = (params: AgGridEvent) => {
    setGridApi(params.api);
  };

  const handleError = (e: Response) => {
    console.log(e.status);
  };

  const clickPostColumn = (event: any) => {
    if (columnDefinitions.filter((column:ColDef) => event.column.colId === column.field).length > 0) {
      pushToViewPage(event);
    }
  };

  const pushToViewPage = useCallback((event:any) =>
    history.push(`/tags/${event.data.slug}`),
  [history, termQueryParameters],
  );

  const pushToAddPage = useCallback(() =>
    history.push('/add'),
    [history],
  );

  const onBulkChangeApplyClicked = () => {
    if (gridApi) {
      const selectedRowHashIds = gridApi.getSelectedRows().map((row) => row.hashId);
      if (bulkChangeSelected === TermBulkActions.Delete) {
        // deletePostsByHashIds(selectedRowHashIds, handleError).then(() => {
        //   setTimeout(() => {
        //     getPostDtos(termQueryParameters as SearchPostParameters, setTerms, handleError);
        //   }, 500);
        // });
      }
    }
  };

  const onPageSizeChanged = (event: ChangeEvent<HTMLInputElement>) => {
    setTermQueryParameters(prevState => {
      if (prevState !== undefined) {
        return ({
          ...prevState,
          paginationParameters: {
            pageSize: Number.parseInt(event.target.value),
            pageNumber: 1
          }
        })
      }

      return prevState;
    });
  }

  const onFirstButtonClicked = () => {
    setTermQueryParameters(prevState => {
      if (prevState !== undefined) {
        return ({
          ...prevState,
          paginationParameters: {
            pageSize: prevState?.paginationParameters.pageSize,
            pageNumber: 1
          }
        })
      }

      return prevState;
    });
  };

  const onBackButtonClicked = () => {
    if (termQueryParameters?.paginationParameters.pageNumber === 1) {
      return;
    }

    setTermQueryParameters(prevState => {
      if (prevState !== undefined) {
        return ({
          ...prevState,
          paginationParameters: {
            pageSize: prevState?.paginationParameters.pageSize,
            pageNumber:  prevState?.paginationParameters.pageNumber - 1
          }
        })
      }

      return prevState;
    });
  };

  const onNextButtonClicked = () => {
    if (termQueryParameters === undefined || termQueryParameters?.paginationParameters.pageNumber === Math.ceil(recordsCount/termQueryParameters?.paginationParameters.pageSize)) {
      return;
    }

    setTermQueryParameters(prevState => {
      if (prevState !== undefined) {
        return ({
          ...prevState,
          paginationParameters: {
            pageSize: prevState?.paginationParameters.pageSize,
            pageNumber:  prevState?.paginationParameters.pageNumber + 1
          }
        })
      }

      return prevState;
    });
  }

  const onLastButtonClicked = () => {
    setTermQueryParameters(prevState => {
      if (prevState !== undefined) {
        return ({
          ...prevState,
          paginationParameters: {
            pageSize: prevState?.paginationParameters.pageSize,
            pageNumber: Math.ceil(recordsCount/prevState?.paginationParameters.pageSize)
          }
        })
      }

      return prevState;
    });
  }

  if (termQueryParameters?.paginationParameters === undefined) {
    return (
      <CenteredSpinner />
    )
  }

  return (
    <Grid container spacing={3}>
      <Grid
        container
        item xs={12}
        lg={8}
      >
        <Grid
          direction="column"
          justifyContent="flex-start"
          xs={12}
        >
          <Grid
            container
            item
            xs={12}
          >
            <Typography variant="h4" component="h1">{pageTitle}</Typography>
            {addNew &&
              <Button
                  variant="contained"
                  color="primary"
                  onClick={pushToAddPage}
                  sx={{
                    ml: 2
                  }}
              >
                  Add New
              </Button>
            }
          </Grid>
          <Grid
            container
            item xs={12}
            alignItems="center"
            sx={{
              marginTop: 1,
              marginBottom: 1,
            }}
          >
            <Grid item>
              <FormControl
                sx={{
                  width: '150px',
                  marginRight: 2,
                  marginBottom: 2,
                }}
              >
                <InputLabel id="bulk-page-actions-label">Bulk actions</InputLabel>
                <Select
                  variant="standard"
                  labelId="bulk-page-actions-searchLabel"
                  id="bulk-page-actions"
                  defaultValue=""
                  onChange={onBulkChangeSelected}
                >
                  <MenuItem value={TermBulkActions.Delete}>Delete</MenuItem>
                </Select>
              </FormControl>
              <Button
                variant="contained"
                color="secondary"
                sx={{
                  verticalAlign: 'bottom',
                  marginRight: 2,
                  marginBottom: 2,
                }}
                onClick={onBulkChangeApplyClicked}
              >
                Apply
              </Button>
            </Grid>
            <Grid
              item
              sx={{
                flexGrow: 1
              }}
            >
              <TextField
                variant="standard"
                label="Search"
                sx={{
                  width: '100%',
                  marginBottom: 2,
                }}
                InputProps={{
                  endAdornment: <InputAdornment position="end"><Search/></InputAdornment>,
                }}
                onChange={onSearchTextFieldChanged}
              />
            </Grid>
          </Grid>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Paper style={{width: 'auto'}}>
                <PaginatedMaterialTable
                  animateRows
                  columnDefs={columnDefinitions}
                  defaultColDef={defColDefs}
                  frameworkComponents={frameworkComponents}
                  onFirstButtonClicked={onFirstButtonClicked}
                  onBackButtonClicked={onBackButtonClicked}
                  onNextButtonClicked={onNextButtonClicked}
                  onLastButtonClicked={onLastButtonClicked}
                  onPageSizeChanged={onPageSizeChanged}
                  paginationParameters={termQueryParameters?.paginationParameters}
                  recordsCount={recordsCount}
                  rowData={terms}
                  gridOptions={options}
                  onCellClicked={clickPostColumn}
                  domLayout='autoHeight'
                  onGridReady={onGridReadyCallback}
                />
              </Paper>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      {children &&
        <Grid item xs={12} lg={4}>
          {children}
        </Grid>
      }
    </Grid>
  );
};

export default TermMaterialTable;
