import React, {useState, useCallback, useEffect, useMemo} from 'react';
import {useHistory} from 'react-router-dom';
import BitDto from '../../includes/models/BitDto';
import {getBitDtoByHashId} from '../../includes/api-functions/BitDtoCalls';
import AddPostBase from '../../../rob-horse/reusable-components/Add-Edit-View-Default-Bases/AddPostBase';
import {Typography} from '@mui/material';
import PostSearch from '../../../rob-horse/reusable-components/Websocket-Components/PostSearch';
import SetlistDto from '../../includes/models/SetlistDto';
import {getJokeDtoByHashId} from '../../includes/api-functions/JokeDtoCalls';
import PostDto from '../../../includes/models/PostDto';
import SetlistWriter from '../slate-js/SetlistWriter';
import SetlistJokeDto from '../../includes/models/SetlistJokeDto';
import JokeDto from '../../includes/models/JokeDto';
import SetlistLaughDto from '../../includes/models/SetlistLaughDto';
import SetlistBitDto from '../../includes/models/SetlistBitDto';
import SetlistSlateComponents from '../slate-js/SetlistSlateComponents';
import {postSetlistDto} from '../../includes/api-functions/SetlistDtoCalls';
import setlistService from './setlistService';
import {INewPostProps} from "../../../rob-horse/contentTypeMapper";
import useSlateEditor from "../../../rob-horse/reusable-components/Slate-Editors/useSlateEditorState";

const AddSetlist = (props: INewPostProps) => {
  const {contentTypeSlug} = props;
  const [setlistDto, setSetlistDto] = useState<SetlistDto>(new SetlistDto());
  const [slateContent, setSlateContent] = useState<any>({
    type: "setlist-editor",
    children: []
  });
  const history = useHistory();
  const [allowSubmission, setAllowSubmission] = useState<boolean>(false);

  const [editor] = useSlateEditor(SetlistSlateComponents);

  useEffect(() => {
    setSetlistDto(new SetlistDto({contentTypeSlug}));
  }, [contentTypeSlug]);

  const onContentChange = (newEditorStateValue: any) => {
    setSetlistDto((prevState) => ({
      ...prevState,
      act: setlistService.createNewActOnTextEditorChange(setlistDto.act, newEditorStateValue),
    }));
  };

  const goToEditPage = useCallback((setlistDto:SetlistDto) =>
    history.push(`/edit/${setlistDto.hashId}`),
  [history],
  );

  const handleChange = (e:any) => {
    const {name, value} = e.target;
    setSetlistDto((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const publishSetlistButtonClicked = async () => {
    setAllowSubmission(true);
  };

  useEffect(() => {
    if (allowSubmission && setlistDto.act.length > 0) {
      postSetlistDto(setlistDto, goToEditPage, handleError);
    }
  }, [allowSubmission, setlistDto]);

  const handleError = (e: Response) => {
    console.log(e.status);
  };

  const addPost = async (hashId: string, contentTypeSlug: string) => {
    if (contentTypeSlug === 'joke') {
      await getJokeDtoByHashId(hashId, onPostAddCallback, handleError);
    } else if (contentTypeSlug === 'bit') {
      await getBitDtoByHashId(hashId, onPostAddCallback, handleError);
    }
  };

  const onPostAddCallback = (postDto: PostDto) => {
    let setlistLaugh: SetlistLaughDto;

    if (postDto.contentTypeSlug === 'joke') {
      setlistLaugh = new SetlistJokeDto({
        jokeDto: new JokeDto(postDto),
      });
    } else if (postDto.contentTypeSlug === 'bit') {
      setlistLaugh = new SetlistBitDto({
        bitDto: new BitDto(postDto),
      });
    }

    setSetlistDto((prevState) => ({
      ...prevState,
      act: [...prevState.act, setlistLaugh],
    }));
  };

  useEffect(() => {
    const content = {
      type: "setlist-editor",
      children: setlistDto.act.length ? setlistService.createSetlistContent(setlistDto) : []
    };

    setSlateContent(content);
  }, [setlistDto]);

  return (
    <AddPostBase
      allowSubmission={allowSubmission}
      contentTypeName="Setlist"
      handleChange={handleChange}
      postDto={setlistDto}
      setPostDto={setSetlistDto}
      publishPostButtonClicked={publishSetlistButtonClicked}
    >
      <PostSearch
        addPost={addPost}
        searchLabel="Search for existing bits and jokes"
        contentTypeSlugs={["bit", "joke"]}
        sx={{
          mb: 4,
        }}
      />
      <Typography variant="h4" component="h2">Setlist:</Typography>
      <SetlistWriter
        content={slateContent}
        editor={editor}
        onContentChange={onContentChange}
        hideToolbar
        slateComponents={SetlistSlateComponents}
      />
    </AddPostBase>
  );
};

export default AddSetlist;
