import {Node, Transforms} from "slate";
import {FileDtoForGenericPost} from "../../../../../../includes/models/FileDto";

const defaultFileBlock = () => {
    return {
        type: "file",
        fileDto: new FileDtoForGenericPost(),
        children: [
            {
                type: "void",
                text: "",
            }
        ],
        key: Math.random()
    };
};

const insertParagraphBlock = (editor, event) => {
    event.preventDefault();

    const path = editor.selection.anchor.path.slice(0,-1);
    const selectedElement = Node.get(editor, path);

    Transforms.insertNodes(editor, defaultFileBlock());
}

const resetToParagraphBlock = (editor, event, slateComponents) => {
    if (editor.selection.anchor.offset !== 0
        || !editor.selection.anchor.path.every((node) => node === 0)
    ) {
        return;
    }

    const elementPath = editor.selection.anchor.path.slice(0,-1);
    const selectedElement = Node.get(editor, elementPath);

    const componentThatMayBeReset = slateComponents.find((slateComponent) => slateComponent.type === selectedElement.type);

    if (componentThatMayBeReset) {
        componentThatMayBeReset.resetToDefault(editor, elementPath, defaultFileBlock());
    }
}

export default {
    insertParagraphBlock,
    defaultParagraphBlock: defaultFileBlock,
    resetToParagraphBlock
};