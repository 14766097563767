import SlateHighlightedCodeBlockComponent
    from "../reusable-components/Slate-Editors/Custom-Components/SlateHighlightedCodeBlockComponent/SlateHighlightedCodeBlockComponent";
import SlateHighlightedCodeLineComponent
    from "../reusable-components/Slate-Editors/Custom-Components/SlateHighlightedCodeBlockComponent/SlateHighlightedCodeLineComponent";
import SlateListItemComponent from "../reusable-components/Slate-Editors/Custom-Components/SlateListComponent/SlateListItemComponent";
import SlateOrderedListComponent from "../reusable-components/Slate-Editors/Custom-Components/SlateListComponent/SlateOrderedListComponent";
import SlateUnorderedListComponent from "../reusable-components/Slate-Editors/Custom-Components/SlateListComponent/SlateUnorderedListComponent";
import SlateParagraphBlockComponent
    from "../reusable-components/Slate-Editors/Custom-Components/SlateParagraphBlockComponent/SlateParagraphBlockComponent";
import FileBlockComponent
    from "../reusable-components/Slate-Editors/Custom-Components/FileComponent/FileBlockComponent";
import SlateHighlightedCodeLineLeafRendererComponent
    from "../reusable-components/Slate-Editors/Custom-Components/SlateHighlightedCodeBlockComponent/SlateHighlightedCodeLineLeafRendererComponent";
import BoldLeafRendererComponent
    from "../reusable-components/Slate-Editors/Custom-Components/CoreStyles/BoldLeafRendererComponent";
import ItalicLeafRendererComponent
    from "../reusable-components/Slate-Editors/Custom-Components/CoreStyles/ItalicLeafRendererComponent";
import UnderlineLeafRendererComponent
    from "../reusable-components/Slate-Editors/Custom-Components/CoreStyles/UnderlineLeafRendererComponent";
import CodeLeafRendererComponent
    from "../reusable-components/Slate-Editors/Custom-Components/CoreStyles/CodeLeafRendererComponent";
import SlateBlockQuoteComponent
    from "../reusable-components/Slate-Editors/Custom-Components/SlateBlockQuoteComponent/SlateBlockQuoteComponent";
import SlateBlockQuoteLineComponent
    from "../reusable-components/Slate-Editors/Custom-Components/SlateBlockQuoteComponent/SlateBlockQuoteLineComponent";
import SlateTextTypeComponent
    from "../reusable-components/Slate-Editors/Custom-Components/TextTypeComponent/SlateTextTypeComponent";
import SlateDecoratedTextTypeComponent
    from "../reusable-components/Slate-Editors/Custom-Components/DecoratedTextTypeComponent/SlateDecoratedTextTypeComponent";

export const GenericPostSlateComponents = [
    SlateDecoratedTextTypeComponent,
    SlateTextTypeComponent,
    BoldLeafRendererComponent,
    ItalicLeafRendererComponent,
    UnderlineLeafRendererComponent,
    CodeLeafRendererComponent,
    SlateHighlightedCodeBlockComponent,
    SlateHighlightedCodeLineComponent,
    SlateHighlightedCodeLineLeafRendererComponent,
    SlateListItemComponent,
    SlateOrderedListComponent,
    SlateUnorderedListComponent,
    SlateBlockQuoteComponent,
    SlateBlockQuoteLineComponent,
    SlateParagraphBlockComponent,
    FileBlockComponent,
];