import React, {ReactNode} from "react";
import * as Sentry from "@sentry/react";
import {Card, Grid, Typography} from "@mui/material";

interface ModalErrorBoundaryProps {
  children: React.ReactNode;
}

interface ErrorModalProps {
  children?: ReactNode;
}

const ErrorCard = (props: ErrorModalProps) => {
  return (
    <Grid
      container
      direction="row"
      justifyContent="center"
      alignItems="center"
    >
      <Card
        sx={{
          position: 'absolute',
          top: '20%',
          height: 'auto',
          '-webkit-transform': 'translateY(-20%)',
          transform: 'translateY(-20%)',
          m: 2,
          p: 2
        }}
      >
        <Typography variant="h1" sx={{mb: 1}}>Uh Oh.</Typography>
        <Typography variant="h2" sx={{mb: 1}}>We messed up.</Typography>
        <Typography sx={{mb: 1}}>An error has occurred while you were using the app. Thankfully, it has already been sent to the engineers.</Typography>
        <Typography sx={{mb: 1}}>Thank you for your continued use of Rob.Horse.</Typography>
      </Card>
    </Grid>
  )
}

const ModalErrorBoundary = (props: ModalErrorBoundaryProps) => {
  const {
    children
  } = props;

  const onError = (error: Error) => {
    console.log(error.message)
  }

  return (
    <Sentry.ErrorBoundary fallback={<ErrorCard />} onError={onError} showDialog={true}>
      {children}
    </Sentry.ErrorBoundary>
  )
}

export default ModalErrorBoundary;