import PostDto from "../../../includes/models/PostDto";
import SetlistLaughDto from "./SetlistLaughDto";

export default class SetlistDto extends PostDto {
    act: SetlistLaughDto[] = [];
    contentTypeSlug: string = "setlist"

    constructor(setlistDto?: Partial<SetlistDto>) {
        super();
        Object.assign(this, setlistDto);
    };
}