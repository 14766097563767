import React, { Component } from 'react';
import SimpleNavMenu  from './SimpleNavMenu.tsx';

export class SimpleLayout extends Component {
  static displayName = SimpleLayout.name;

  render () {
    return (
        <SimpleNavMenu>
            {this.props.children}
        </SimpleNavMenu>
    );
  }
}
