import React, {useCallback} from 'react';
import Chip from '@mui/material/Chip';
import TermDto from '../../../includes/models/TermDto';
import {alpha, ChipProps} from '@mui/material';
import {useHistory} from 'react-router-dom';
import {useTheme} from "@mui/material/styles";

interface ITermChipProps extends ChipProps {
    readonly?: boolean,
    onTermDelete: Function,
    size?: 'medium' | 'small'
    term: TermDto
};

export const TermChip = (props: ITermChipProps) => {
  const history = useHistory();

  const theme = useTheme();

  const onDelete = () => {
    props.onTermDelete(props.term.name);
  };


  const clickPostRow = useCallback(() =>
    history.push(`/tags/${props.term.slug}`),
  [history],
  );

  const chipRef = (ref: HTMLDivElement | null) => {
    if (!ref) {
      return;
    }

    if (!props.readonly) {
      return;
    }

    ref.onclick = (event) => {
      event.stopPropagation(); // you use a ref here to prevent onCellClicked from bubbling up
      clickPostRow();
    };
  }

  const termGroupingDto = props.term.termGroupingDtos.find(tg => tg.color !== undefined);

  return (
    <Chip
      {...props}
      ref={chipRef}
      label={props.term.name}
      onDelete={props.readonly ? undefined : onDelete}
      sx={{
        margin: '5px',
        color: termGroupingDto?.color ? theme.palette.getContrastText(termGroupingDto?.color) : theme.palette.text.primary,
        backgroundColor: termGroupingDto?.color || theme.palette.action.selected,
        ':hover': {
          backgroundColor: alpha(termGroupingDto?.color || theme.palette.action.selected, .8)
        },
        ':active': {
          backgroundColor: alpha(termGroupingDto?.color || theme.palette.action.selected, .6)
        },
      }}
    />);
};

export default TermChip;
