import { fetchWrapper } from "../api-authorization/helpers";
import UserProfileDto from "../models/UserProfileDto";
import {cloneDeep} from "lodash";
import userProfileService from "../services/userProfileService";
import FileDto from "../models/FileDto";

const getUserProfileByUsername = async (username: string, onSuccessCallback: (userProfileDto: UserProfileDto) => void, onErrorCallback: (error: Response) => void) => {
    await fetchWrapper.get(`api/userProfile/byUsername/${username}`).then(onSuccessCallback,onErrorCallback);
}

const getUserProfileByHashId = async (hashid: string, onSuccessCallback: (userProfileDto: UserProfileDto) => void, onErrorCallback: (error: Response) => void) => {
    await fetchWrapper.get(`api/userProfile/byHashId/${hashid}`).then(onSuccessCallback,onErrorCallback);
}

const getUserProfileRevisionsByHashId = async (hashId: string, onSuccessCallback: (userProfileDtoRevisions: UserProfileDto[]) => void, onErrorCallback: (error: Response) => void) => {
    await fetchWrapper.get(`api/userProfile/revisions/${hashId}`).then(onSuccessCallback,onErrorCallback);
}


export const postUserProfile = async (userProfileDto: UserProfileDto, onSuccessToEditCallback?: (userProfileDto: UserProfileDto) => void, onErrorCallback?: (error: Response) => void) => {
    const postToPublish = cloneDeep(userProfileDto);

    await fetchWrapper.postMultiform('api/userProfile', postToPublish).then(onSuccessToEditCallback,onErrorCallback);
};

export default {
    getUserProfileByUsername,
    getUserProfileRevisionsByHashId,
    getUserProfileByHashId,
    postUserProfile
};