import SetlistLaughDto from "./SetlistLaughDto";
import BitDto from "./BitDto";
import JokeDto from "./JokeDto";

export default class SetlistJokeDto extends SetlistLaughDto {
    jokeDto: JokeDto = new JokeDto();

    constructor(setlistJokeDto?: Partial<SetlistJokeDto>) {
        super();
        Object.assign(this, setlistJokeDto);
    };
}