import PostDto from '../models/PostDto';
import {fetchWrapper} from "../api-authorization/helpers";
import {ContentTypeDto} from "../models/ContentTypeDto";
import React from "react";
import qs from 'qs';
import SearchPostParameters from "../data-transfer-objects/SearchPostParameters";

export const getPostDtos = async (searchPostParameters: SearchPostParameters, onSuccessSetData: (value: any) => void, onErrorCallback: (error: Response) => void) => {
    const searchPostParametersString = qs.stringify(searchPostParameters, { allowDots: true, encode: false });

    await fetchWrapper.getWithHeaders(`api/post?${searchPostParametersString}`).then(onSuccessSetData, onErrorCallback);
}

export const getContentTypeByPostId = async (hashId: string, onSuccessSetData: (value: React.SetStateAction<ContentTypeDto | undefined>) => void, onErrorCallback: (error: Response) => void) => {
    await fetchWrapper.get(`api/post/${hashId}/contentType`).then(onSuccessSetData,onErrorCallback);
}

export const deletePostsByHashIds = async (hashIds: string[], onErrorCallback: (error: Response) => void) => {
    await fetchWrapper.bulkDelete('api/post/bulkDelete', hashIds).catch(onErrorCallback);
}

export const updateBasePostValues = async (postDto: PostDto, onSuccessCallback: (value: PostDto) => void, onErrorCallback: (error: Response) => void) => {
    await fetchWrapper.put('api/post', postDto).then(onSuccessCallback,onErrorCallback);
}