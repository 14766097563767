import TermGroupingDto from "./TermGroupingDto";

export default class TermDto {
    name: string = '';
    slug: string = '';
    description: string = '';
    hashId?: string = undefined;
    termGroupingDtos: TermGroupingDto[] = [];

    constructor(termDto?: Partial<TermDto>) {
      Object.assign(this, termDto);
    }
}

export class TermDtoUtils {
    static sort = (x: TermDto, y: TermDto): number => {
      return `${x.slug}` > `${y.slug}` ? 1 : -1;
    }

    static equalArrays = (x: TermDto[], y: TermDto[]) : boolean => {
      const sortedAndMappedX = x.sort(TermDtoUtils.sort).map((termDto) => ({
        name: termDto.name,
        slug: termDto.slug,
      }));

      const sortedAndMappedY = y.sort(TermDtoUtils.sort).map((termDto) => ({
        name: termDto.name,
        slug: termDto.slug,
      })); ;

      return JSON.stringify(sortedAndMappedX) === JSON.stringify(sortedAndMappedY);
    }
}
