import JokeDto from "../../includes/models/JokeDto";

export const setSlateJokeContent = (processedGenericPostDto: JokeDto): JokeDto => {
    if (processedGenericPostDto.slateContent === undefined) {
        processedGenericPostDto.slateContent = {
            type: "joke-editor",
            children: JSON.parse(processedGenericPostDto.legacyJSONSerializedSlateContent)
        };
    }

    return processedGenericPostDto;
}


const onSuccessfulGetJoke = (hashId: string, setJokeDto: (value: JokeDto) => void) => (newGenericPostDto: JokeDto)  => {
    let processedGenericPostDto = newGenericPostDto;

    if (processedGenericPostDto.hashId) {
        processedGenericPostDto.hashedParent = hashId;
        processedGenericPostDto.hashId = '';
    }

    processedGenericPostDto = setSlateJokeContent(processedGenericPostDto);

    setJokeDto(new JokeDto(processedGenericPostDto));
};

const onSuccessfulGetJokeRevisions = (setJokeDtoRevisions: (value: JokeDto[]) => void, setRevisionIndex: (value: number) => void) => (revisions: JokeDto[]) => {
    const processedRevisions = revisions;

    for (let revision of processedRevisions) {

        revision = setSlateJokeContent(revision);

        revision = new JokeDto(revision);
    }

    setJokeDtoRevisions(processedRevisions);
    setRevisionIndex(processedRevisions.length-1);
}

export {
    onSuccessfulGetJoke,
    onSuccessfulGetJokeRevisions
}