import SlatePerformanceComponent from "./PerformanceComponents/SlatePerformanceComponent/SlatePerformanceComponent";
import SlateJokePerformanceComponent
  from "./PerformanceComponents/SlateJokePerformanceComponent/SlateJokePerformanceComponent";
import BoldLeafRendererComponent
  from "../../../rob-horse/reusable-components/Slate-Editors/Custom-Components/CoreStyles/BoldLeafRendererComponent";
import ItalicLeafRendererComponent
  from "../../../rob-horse/reusable-components/Slate-Editors/Custom-Components/CoreStyles/ItalicLeafRendererComponent";
import UnderlineLeafRendererComponent
  from "../../../rob-horse/reusable-components/Slate-Editors/Custom-Components/CoreStyles/UnderlineLeafRendererComponent";

const PerformanceSlateComponents = [
  BoldLeafRendererComponent,
  ItalicLeafRendererComponent,
  UnderlineLeafRendererComponent,
  SlatePerformanceComponent,
  SlateJokePerformanceComponent,
];

export default PerformanceSlateComponents;
