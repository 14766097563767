import React from 'react';
import HorseBackground from './assets/horse.jpg';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import styled from '@emotion/styled';
import {Box} from "@mui/material"; ;

const RootDiv = styled(Box)`
  width: 100vw;
  height: 100vh;
  position: fixed;
  background-image: url("${HorseBackground}");
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
`;

export const RobHorseHome = () => {
  return (
    <RootDiv sx={{
        m: -3
    }}>
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="flex-end"
        sx={{
          height: '75%',
          color: 'white',
        }}
      >
        <Grid item xs={6}>
          <Typography variant="h4">Do you rob the horse,</Typography>
          <Typography variant="h4">or does the horse rob you?</Typography>
          <Typography variant="h6">-Unknown Horse Thief</Typography>
        </Grid>
      </Grid>

    </RootDiv>
  );
};
