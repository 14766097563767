import React, {useState, useEffect} from 'react';
import AudioPlayer from './AudioPlayer';
import DownloadButton from './DownloadButton';
import {SxProps} from "@mui/system";
import {Box} from "@mui/material";

interface IAudioViewerProps {
  file: File,
  initialUpload?: boolean,
  sx?: SxProps
}


const AudioViewer = (props: IAudioViewerProps) => {
  const {
    file,
    initialUpload,
    sx
  } = props;

  const [base64encoding, setBase64encoding] = useState<string>('');
  const [audioElement, setAudioElement] = useState<HTMLAudioElement>();
  const [audioContext, setAudioContext] = useState<AudioContext>();

  useEffect(() => {
    return () => {
      if (audioElement) {
        audioElement.pause();
      }
    };
  }, [audioElement]);

  useEffect(() => {
    const reader = new FileReader();

    reader.addEventListener('load', async () => {
      if (typeof reader.result === 'string') {
        const audio = new Audio(reader.result);

        const context = new AudioContext();
        const track = await context.createMediaElementSource(audio);
        track.connect(context.destination);

        setAudioElement(audio);
        setAudioContext(context);
        setBase64encoding(reader.result);
      }
    }, false);

    reader.readAsDataURL(file);
  }, [file]);

  return (
    <Box sx={sx}>
      <AudioPlayer
        audioElement={audioElement}
        audioContext={audioContext}
      />
      {!initialUpload &&
        <DownloadButton
          href={base64encoding}
          name={file.name}
        />
      }
    </Box>
  );
};

export default AudioViewer;
