import JokePerformanceBlock from "./src/JokePerformanceBlock";
import ParagraphBlockTransforms from "./src/JokePerformanceBlockTransforms";
import BoldLeafRendererComponent
    from "../../../../../rob-horse/reusable-components/Slate-Editors/Custom-Components/CoreStyles/BoldLeafRendererComponent";
import ItalicLeafRendererComponent
    from "../../../../../rob-horse/reusable-components/Slate-Editors/Custom-Components/CoreStyles/ItalicLeafRendererComponent";
import UnderlineLeafRendererComponent
    from "../../../../../rob-horse/reusable-components/Slate-Editors/Custom-Components/CoreStyles/UnderlineLeafRendererComponent";

const SlateJokePerformanceComponent = {
    author: 'Rob Reinhard',
    block: JokePerformanceBlock,
    decorators: undefined,
    default: true,
    defaultResetHandler: ParagraphBlockTransforms.resetToParagraphBlock,
    dependencyTypes: undefined,
    leafRenderers: [BoldLeafRendererComponent.type, ItalicLeafRendererComponent.type, UnderlineLeafRendererComponent.type],
    name: 'Joke Performance Block',
    onDrop: false,
    onKeyDown: (editor, event) => {
        if (event.key === "Enter" && event.shiftKey) {
            ParagraphBlockTransforms.insertParagraphBlock(editor, event);
        }
    },
    plugins: undefined,
    resetToDefault: undefined,
    toolbar: undefined,
    transforms: undefined,
    type: 'setlist-laugh'
};

export default SlateJokePerformanceComponent;