import React from 'react';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import UndoIcon from '@mui/icons-material/Undo';
import RedoIcon from '@mui/icons-material/Redo';

interface IRevisionBarProps {
    className?: string,
    onPreviousClicked: ((event: React.MouseEvent<HTMLButtonElement, globalThis.MouseEvent>) => void),
    onNextClicked: ((event: React.MouseEvent<HTMLButtonElement, globalThis.MouseEvent>) => void),
    revisionIndex: number,
    revisionsLength: number
}

const RevisionBar = (props: IRevisionBarProps) => {
  const {className, onPreviousClicked, onNextClicked, revisionIndex, revisionsLength} = props;

  return (
    <Grid container item className={className} justifyContent="space-between" alignItems="center">
      <Grid item xs={4}>
        <IconButton
          aria-label="delete"
          sx={{
            float: 'left',
          }}
          onClick={onPreviousClicked}
          size="large">
          <UndoIcon fontSize="large" />
        </IconButton>
      </Grid>
      <Grid item xs={4}>
        <Typography variant="h6" align="center">{revisionIndex+1}/{revisionsLength}</Typography>
      </Grid>
      <Grid item xs={4}>
        <IconButton
          aria-label="delete"
          sx={{
            float: 'right',
          }}
          onClick={onNextClicked}
          size="large">
          <RedoIcon fontSize="large" />
        </IconButton>
      </Grid>
    </Grid>
  );
};

export default RevisionBar;
