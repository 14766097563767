import SetlistDto from "../../includes/models/SetlistDto";
import {cloneDeep} from "lodash";
import SetlistJokeDto from "../../includes/models/SetlistJokeDto";
import SetlistBitDto from "../../includes/models/SetlistBitDto";
import JokePerformanceDto from "../../includes/models/JokePerformanceDto";
import PerformanceDto from "../../includes/models/PerformanceDto";
import StagingPerformanceDto from "../../includes/models/StagingPerformanceDto";
import setlistService from "../Setlist/setlistService";
import StagingSetlistDto from "../../includes/models/StagingSetlistDto";

const updateJokePerformances = (setlistDto: SetlistDto, slateContent: any, performanceHashId: string | undefined = undefined) : SetlistDto => {
  const newSetlistDto = cloneDeep(setlistDto)

  for (const [index, setlistLaughTextObject] of slateContent.entries()) {
    if (setlistLaughTextObject.type !== 'setlist-laugh') {
      continue;
    }

    const setlistLaughDto = newSetlistDto.act[index];

    for (const joke of setlistLaughTextObject.children) {
      if (joke.type !== 'joke' || !joke.rating) {
        continue;
      }

      if (setlistLaughDto instanceof SetlistJokeDto) {
        setlistLaughDto.jokeDto.jokePerformanceDtos = [new JokePerformanceDto({
          rating: joke.rating,
          performanceHashId
        })]
      }
      else if (setlistLaughDto instanceof  SetlistBitDto) {
        const jokeDtoIndex = setlistLaughDto.bitDto.jokes.findIndex((jokeDto) => jokeDto.hashId === joke.hashId);

        if (jokeDtoIndex >= 0) {
          setlistLaughDto.bitDto.jokes[jokeDtoIndex].jokePerformanceDtos = [new JokePerformanceDto({
            rating: joke.rating,
            performanceHashId
          })];
        }
      }
    }
  }

  return newSetlistDto;
}

const convertPerformanceDtoToStagingPerformanceDto = (performanceDto: PerformanceDto) : StagingPerformanceDto => {
  let stagingSetlistDto : StagingSetlistDto | undefined;
  if (performanceDto.setlistDto) {
    stagingSetlistDto = setlistService.convertSetlistDtoToStagingSetlistDto(performanceDto.setlistDto)
  }

  const file = performanceDto.fileDto?.file;

  delete performanceDto.fileDto?.file;

  const stagingPerformanceDto = new StagingPerformanceDto({
    ...performanceDto,
    stagingSetlistDto: stagingSetlistDto,
    filePostDto: performanceDto.fileDto,
    file
  });

  return stagingPerformanceDto;
}

const convertStagingPerformanceDtoToPerformanceDto = (stagingPerformanceDto: StagingPerformanceDto) : PerformanceDto => {
  const setlistDto = setlistService.convertStagingSetlistDtoToSetlistDto(stagingPerformanceDto.stagingSetlistDto);

  const fileDto = stagingPerformanceDto.filePostDto ? {
    ...stagingPerformanceDto.filePostDto,
    file: stagingPerformanceDto.file
  } : null;

  const performanceDto = new PerformanceDto({
    ...stagingPerformanceDto,
    fileDto: fileDto,
    setlistDto: setlistDto
  });

  return performanceDto;
}

export default {
  updateJokePerformances,
  convertPerformanceDtoToStagingPerformanceDto,
  convertStagingPerformanceDtoToPerformanceDto
}