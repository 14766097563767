import { Editor, Node } from "slate";

const getActiveStyles = (editor) => {
    return new Set(Object.keys(Editor.marks(editor) ?? {}));
};

const toggleStyle = (editor, style) => {
    const activeStyles = getActiveStyles(editor);
    if (activeStyles.has(style)) {
        Editor.removeMark(editor, style);
    } else {
        Editor.addMark(editor, style, true);
    }
}

const disableStyle = (editor, slateComponents, style) => {
    const {
        selection
    } = editor;

    if (selection !== null && selection.anchor !== null) {
        const parentElement = Node.parent(editor, selection.anchor.path);

        if (slateComponents === undefined) {
            return true;
        }

        const slateComponent = slateComponents.find(sc => sc.type === parentElement.type);

        if (!slateComponent || slateComponent.leafRenderers === undefined || slateComponent.leafRenderers.length === 0) {
            return true;
        }

        return !slateComponent.leafRenderers.includes(style)
    }

    return false;
}

export {
    getActiveStyles,
    toggleStyle,
    disableStyle
}