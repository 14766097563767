import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import App from './rob-horse/App';
import registerServiceWorker from './registerServiceWorker';
import {BrowserTracing} from "@sentry/tracing";
import * as Sentry from "@sentry/react";

const baseUrl = document.getElementsByTagName('base')[0].getAttribute('href');
const rootElement = document.getElementById('root');

Sentry.init({
    dsn: "https://1d4f864b9f244caca58a5dbf0b80a240@o1425031.ingest.sentry.io/6774840",
    integrations: [new BrowserTracing()],
    tracesSampleRate: .0001,
    tunnel: "/api/errorTunnel",
});

ReactDOM.render(
  <BrowserRouter basename={baseUrl}>
    <App />
  </BrowserRouter>,
  rootElement);

// Uncomment the line above that imports the registerServiceWorker function
// and the line below to register the generated service worker.
// By default create-react-app includes a service worker to improve the
// performance of the application by caching static assets. This service
// worker can interfere with the Identity UI, so it is
// disabled by default when Identity is being used.
//
registerServiceWorker();

