import React, {useState, useCallback, useEffect, useMemo} from 'react';
import FileDto from '../../includes/models/FileDto';
import { useHistory } from 'react-router-dom';
import AddPostBase from "../reusable-components/Add-Edit-View-Default-Bases/AddPostBase";
import FileUploaderViewer from "../reusable-components/Material-File-Viewers/FileUploaderViewer";
import {postFileDto} from "../../includes/api-functions/FileDtoCalls";
import {INewPostProps} from "../contentTypeMapper";

const AddFile = (props: INewPostProps) => {
    const {contentTypeSlug} = props;

    const [fileDto, setFileDto] = useState<FileDto>(new FileDto());
    const history = useHistory();
    const [allowSubmission, setAllowSubmission] =  useState<boolean>(false);

    useEffect(() => {
        setFileDto(new FileDto());
    }, [contentTypeSlug])

    const goToEditPage = useCallback((genericPostDto:FileDto) =>
        history.push(`/edit/${genericPostDto.hashId}`),
        [history]
    );

    const handleChange = (e:any) => {
        const { name, value } = e.target;
        setFileDto(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const publishFileButtonClicked = async () => {
        setAllowSubmission(true);
        await postFileDto(fileDto,goToEditPage,handleError);
    }

    const handleError = (e: Response) => {
        console.log(e.status);
    }

    const onDrop = (acceptedFiles: File[]) => {
        if (acceptedFiles.length == 0) {
            return;
        }

        setFileDto(prevState => ({
            ...prevState,
            'file': acceptedFiles[0]
        }));
    }

    const onDeleteCallback = () => {
        setFileDto(prevState => ({
            ...prevState,
            'file': null
        }))
    }

    return (
            <AddPostBase
                allowSubmission={allowSubmission}
                contentTypeName="File"
                handleChange={handleChange}
                postDto={fileDto}
                setPostDto={setFileDto}
                publishPostButtonClicked={publishFileButtonClicked}
            >
                <FileUploaderViewer
                    file={fileDto?.file}
                    initialUpload
                    onDropCallback={onDrop}
                    onDeleteCallback={onDeleteCallback}
                    hideDeleteIcon={fileDto?.file === null}
                />
            </AddPostBase>
    );
}

export default AddFile;
