import FileBlock from "./src/FileBlock";
import FileBlockTransforms from "./src/FileBlockTransforms";
import UploadFileIcon from '@mui/icons-material/UploadFile';
import FileBlockPlugins from "./src/FileBlockPlugins";

const FileBlockComponent = {
    author: 'Rob Reinhard',
    block: FileBlock,
    decorators: undefined,
    default: false,
    defaultResetHandler: undefined,
    dependencyTypes: undefined,
    leafRenderers: undefined,
    name: 'File Block',
    onDrop: false,
    onKeyDown: undefined,
    plugins: [FileBlockPlugins.withFile],
    resetToDefault: undefined,
    toolbar: {
        icon: UploadFileIcon,
        isActive: undefined,
        onClick: (editor, event) => FileBlockTransforms.insertParagraphBlock(editor, event)
    },
    transforms: undefined,
    type: 'file'
};

export default FileBlockComponent;