import React, {useCallback} from 'react'
import Typography from "@mui/material/Typography";
import {Box, IconButton} from "@mui/material";
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import { useDrag, useDrop } from 'react-dnd'
import {ItemTypes} from "../../BitWriter";
import { Divider } from '@mui/material';
import {ReactEditor, useSlate, useReadOnly} from "slate-react";
import {Transforms} from "slate";
import DeleteIcon from '@mui/icons-material/Delete';

const SetlistLaughBlock = (props) => {
    const editor = useSlate();
    const path = ReactEditor.findPath(editor, props.element);

    const onDrop = useCallback((item) => {
        const newPath = [...ReactEditor.findPath(editor, props.element)];

        if (newPath[newPath.length-1] <= item.path[newPath.length-1]) {
            newPath[newPath.length-1] += 1;
        }

        Transforms.moveNodes(editor, {
            at: item.path,
            to: newPath
        })
    }, [props.element]);

    const [, drag, preview] = useDrag(() => ({
        collect: monitor => ({
            isDragging: !!monitor.isDragging(),
        }),
        item: {
            path: ReactEditor.findPath(editor, props.element),
        },
        type: ItemTypes.JOKE,
    }), [path, props.element]);

    const [{ isOver }, drop] = useDrop(() => ({
        accept: ItemTypes.JOKE,
        drop: onDrop,
        collect: (monitor) => ({
            isOver: !!monitor.isOver(),
        }),
    }), [onDrop]);

    const onDelete = () => {
        console.log({path});
        console.log({children: [...editor.children]});
        Transforms.deselect(editor);
        Transforms.removeNodes(editor, {
            at: path
        })
    }

    const readOnly = useReadOnly();
    return (
        <Box
            {...props.attributes}
        >
            <Box
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    direction: 'row'
                }}
            >
                <Box>
                    <Box
                        ref={preview}
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            direction: 'row'
                        }}
                    >
                        <div
                            contentEditable={false}
                            ref={drag}
                            style={{
                                userSelect: 'none',
                                display: 'inherit',
                                pointerEvents: readOnly ? 'none' : 'inherit'
                            }}
                        >
                            <Box
                                sx={{
                                    alignItems: 'center',
                                    display: readOnly ? 'none' : 'flex',
                                    justifyContent: 'center',
                                    marginRight: 1,
                                    cursor: 'grab',
                                    opacity: .25
                                }}
                            >
                                <DragIndicatorIcon/>
                            </Box>
                            <Typography
                                variant="h6"
                                component="h2"
                                sx={{
                                    display: 'block'
                                }}
                            >
                                {props.element.name}
                            </Typography>
                        </div>
                    </Box>
                    <Box
                        sx={{
                            p: 2
                        }}
                        contentEditable={false}
                    >
                        {props.children}
                    </Box>
                </Box>
                <IconButton
                    contentEditable={false}
                    size="large"
                    variant="contained"
                    color="error"
                    onClick={onDelete}
                    sx={{
                        display: readOnly ? 'none' : 'inherit'
                    }}
                >
                    <DeleteIcon/>
                </IconButton>
            </Box>
            <div
                contentEditable={false}
                ref={drop}
                style={{
                    paddingTop: '5px',
                    paddingBottom: '5px',
                    userSelect: 'none',
                    display: readOnly ? 'none' : 'inherit'
                }}
            >
                <span devLabel="forChromiumBugRelatedToTyping"></span>
                {isOver &&
                    <Divider
                        contentEditable={false}
                        sx={{userSelect: "none"}}
                    />
                }
            </div>
        </Box>
    )
};

export default SetlistLaughBlock;

