import React, {useState, useEffect, useMemo} from 'react';
import {createEditor} from 'slate';
import {withReact} from 'slate-react';
import {withHistory} from 'slate-history';
import JokeBlockTransforms from '../slate-js/SlateJokeComponents/src/JokeBlockTransforms';
import ViewPostBase from '../../../rob-horse/reusable-components/Add-Edit-View-Default-Bases/ViewPostBase';
import PerformanceDto from '../../includes/models/PerformanceDto';
import PerformanceSlateComponents from "../slate-js/PerformanceSlateComponents";
import FileUploaderViewer from "../../../rob-horse/reusable-components/Material-File-Viewers/FileUploaderViewer";
import PerformanceAdditionalProperties from "./components/PerformanceAdditionalProperties";
import {getStagingPerformanceDtoByHashId} from "../../includes/api-functions/PerformanceDtoCalls";
import performanceService from "./performanceService";
import StagingPerformanceDto from "../../includes/models/StagingPerformanceDto";
import setlistService from "../Setlist/setlistService";
import PerformanceWriter from "../slate-js/PerformanceWriter";
import {Box} from "@mui/material";
import {getFileDtosFileByHashId} from "../../../includes/api-functions/FileDtoCalls";
import useSlateEditor from "../../../rob-horse/reusable-components/Slate-Editors/useSlateEditorState";

interface IViewBitProps {
    hashId: string;
}

const ViewPerformance = (props: IViewBitProps) => {
  const {hashId} = props;
  const [performanceDto, setPerformanceDto] = useState<PerformanceDto>(new PerformanceDto());
  const [slateContent, setSlateContent] = useState<any>([JokeBlockTransforms.defaultJokeEditorBlock()]);

  const [editor] = useSlateEditor(PerformanceSlateComponents);

  useEffect(() => {
    getStagingPerformanceDtoByHashId(hashId, onSuccessfulGetPostHashIdCallback, handleError);
  }, []);

  const onSuccessfulGetPostHashIdCallback = (stagingPerformanceDto: StagingPerformanceDto) => {
    const newPerformanceDto = performanceService.convertStagingPerformanceDtoToPerformanceDto(stagingPerformanceDto);

    if (newPerformanceDto.fileDto?.hashId) {
      getFileDtosFileByHashId(newPerformanceDto.fileDto.hashId as string, onGetFileCallback, handleError);
    }

    setPerformanceDto(newPerformanceDto);
  };

  const onGetFileCallback = (file: File) => {
    setPerformanceDto((prevState) => {
      if (prevState.fileDto && file) {
        return {
          ...prevState,
          fileDto: {
            ...prevState.fileDto,
            file
          }
        }
      } else {
        return prevState;
      }
    })
  }

  const handleError = (e: Response) => {
    console.log(e.status);
  };

  const onContentChange = (newEditorStateValue: any) => {
    setSlateContent(newEditorStateValue);
  };

  useEffect(() => {
    if (performanceDto.setlistDto) {
      const content = setlistService.createSetlistContent(performanceDto.setlistDto, performanceDto.hashId);

      setSlateContent(content);
    }
  }, [performanceDto]);

  return (
    <ViewPostBase
      hashId={hashId}
      postDto={performanceDto}
      setPostDto={setPerformanceDto}
    >
      <PerformanceWriter
        content={slateContent}
        editor={editor}
        onContentChange={onContentChange}
        hideToolbar
        readOnly={true}
        slateComponents={PerformanceSlateComponents}
      />

      {performanceDto.fileDto !== null &&
        <Box
          sx={{
            mt: 4,
            mb: 4
          }}
        >
          <FileUploaderViewer
              accept={["audio/*", "video/*"]}
              multiple={false}
              file={performanceDto.fileDto !== null ? performanceDto.fileDto.file : null}
              initialUpload
              placeholder="Drag 'n' Drop or click to upload an audio/video file"
              onDropCallback={() => undefined}
          />
        </Box>
      }
      <PerformanceAdditionalProperties
          handleChange={() => undefined}
          location={performanceDto.location}
          comments={performanceDto.comments}
      />

    </ViewPostBase>
  );
}

export default ViewPerformance;
