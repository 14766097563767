import ParagraphBlockTransforms
  from '../../rob-horse/reusable-components/Slate-Editors/Custom-Components/SlateParagraphBlockComponent/src/ParagraphBlockTransforms';
import {TermDtoUtils} from './TermDto';
import PostDto from './PostDto';
import SlateJSON from "../../rob-horse/reusable-components/Slate-Editors/Custom-Components/SlateJSON";

export default class GenericPostDto extends PostDto {
    slateContent: any = {
        type: "editor",
        children: [ParagraphBlockTransforms.defaultParagraphBlock()]
    };

    contentTypeSlug: string = "richtext";
    legacyJSONSerializedSlateContent: string = "";

    constructor(genericPostDto?: Partial<GenericPostDto>) {
      super();
      Object.assign(this, genericPostDto);
    };
}

export class GenericPostDtoUtils {
  static equals(x: GenericPostDto, y: GenericPostDto) {
    if (x.name !== y.name ||
            JSON.stringify(x.slateContent) !== JSON.stringify(y.slateContent)) {
      return false;
    }
    return true;
  }
}
