import PostDto from "../../../includes/models/PostDto";
import SetlistLaughDto from "./SetlistLaughDto";
import SetlistBitDto from "./SetlistBitDto";
import SetlistJokeDto from "./SetlistJokeDto";

export default class StagingSetlistDto extends PostDto {
    setlistBitsInAct: SetlistBitDto[] = [];
    setlistJokesInAct: SetlistJokeDto[] = [];

    constructor(setlistDto?: Partial<StagingSetlistDto>) {
        super();
        Object.assign(this, setlistDto);
    };
}