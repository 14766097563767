import React, {useCallback} from 'react'
import {Editable, ReactEditor, Slate} from 'slate-react'
import {Node} from 'slate';
import SlateToolbar from "./Toolbar/SlateToolbar";
import UndoIcon from "@mui/icons-material/Undo";
import RedoIcon from "@mui/icons-material/Redo";
import BlockButton from "./Toolbar/BlockButton";

const SlateRichTextEditor = (props) => {
    const {
        content,
        className,
        editor,
        onContentChange,
        placeHolder = "Type something here...",
        readOnly = false,
        hideToolbar = false,
        slateComponents,
        type = "editor"
    } = props

    const onChange = (value) => {
        onContentChange({
            type: type,
            children: value
        });
    }

    const renderElement = useCallback(props => {
        const slateComponent = slateComponents.find((slateComponent) => slateComponent.type === props.element.type && !slateComponent.default);

        if (slateComponent && slateComponent.block) {
            return <slateComponent.block {...props} />;
        }

        const defaultComponent = slateComponents.find((component) => component.default);

        if (defaultComponent && defaultComponent.block) {
            return <defaultComponent.block {...props} />
        }

        return <p {...props} ></p>
    }, [slateComponents]);

    const renderLeaf = ({ attributes, children, leaf, text }) => {
        let content = <>{children}</>;

        const leafTextPath = ReactEditor.findPath(editor, text);

        if (leafTextPath === []) {
            return <span {...attributes}>{content}</span>;
        }

        const parent = Node.parent(editor, leafTextPath);

        const slateComponent = slateComponents.find((slateComponent) => slateComponent.type === parent.type);

        if (slateComponent && slateComponent.leafRenderers) {
            slateComponent.leafRenderers.forEach((leafRendererType) => {
                const component = slateComponents.find((slateComponent) => slateComponent.type === leafRendererType && slateComponent?.leafRenderer);

                if (component) {
                    content = component.leafRenderer({ attributes, children, leaf }, content);
                }
            });
        }

        return <span {...attributes}>{content}</span>;
    }

    const resetToDefaultBlock = (event) => {
        const defaultComponent = slateComponents.find((component) => component.default);

        if (!defaultComponent) {
            return;
        }

        const resetToDefaultComponents = slateComponents.filter((component) => component.resetToDefault);

        defaultComponent.defaultResetHandler(editor, event, resetToDefaultComponents);
    };

    const onDrop = (event) => {
        const slateNode = ReactEditor.toSlateNode(editor, event.target);

        const slateComponent = slateComponents.find((slateComponent) => slateComponent.type === slateNode.type);

        if (slateComponent && slateComponent.onDrop) {
            return slateComponent.onDrop(editor, event);
        }

        return false;
    };

    const onKeyDown = (event) => {
        slateComponents.forEach((slateComponent) => {
            if (slateComponent.onKeyDown) {
                slateComponent.onKeyDown(editor, event);
            }
        });

        if (event.key === 'Backspace') {
            resetToDefaultBlock(event);
        }
    }

    const decorateWithDecorators = ([node, path]) => {
        let ranges = [];

        slateComponents.forEach((slateComponent) => {
            if (slateComponent.decorators) {
                slateComponent.decorators.forEach((decorator) => {
                    ranges = ranges.concat(decorator(editor, [node, path]));
                })
            }
        });

        return ranges;
    };

    editor.children = content.children

    return (
        <Slate
            editor={editor}
            value={editor.children}
            onChange={onChange}
        >
            {(!readOnly && !hideToolbar) &&
            <SlateToolbar>
                {slateComponents && slateComponents.filter((slateComponent) => slateComponent.toolbar !== undefined).map((slateComponent) => (
                    <BlockButton
                        type={slateComponent.type}
                        icon={slateComponent.toolbar.icon}
                        isActive={slateComponent?.toolbar?.isActive ? slateComponent.toolbar.isActive(editor) : false}
                        disabled={slateComponent?.toolbar?.disabled ? slateComponent.toolbar.disabled(editor, slateComponents) : false}
                        onClick={(event) => slateComponent.toolbar.onClick(editor, event)}
                    />
                ))}
                <BlockButton
                    type="undo"
                    icon={UndoIcon}
                    onClick={(event) => {
                        event.preventDefault();
                        editor.undo();
                    }}
                />
                <BlockButton
                    type="redo"
                    icon={RedoIcon}
                    onClick={(event) => {
                        event.preventDefault();
                        editor.redo();
                    }}
                />
            </SlateToolbar>
            }
            <Editable
                className={className}
                decorate={decorateWithDecorators}
                renderLeaf={renderLeaf}
                renderElement={renderElement}
                placeholder={placeHolder}
                onKeyDown={onKeyDown}
                onDrop={onDrop}
                style={{
                    marginTop: '16px'
                }}
                readOnly={readOnly}
            />
        </Slate>
    );
}

export default SlateRichTextEditor;
