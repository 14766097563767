import React from 'react';
import AppBar from '@mui/material/AppBar';
import CssBaseline from '@mui/material/CssBaseline';
import Grid from '@mui/material/Grid';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import {LoginMenuButton} from './LoginMenuButton';
import HorseLogo from './assets/favicon-32x32.png';
import {Box} from '@mui/material';
import NoLinkDecoration from './reusable-components/Base-Utility-Components/NoLinkDecoration';
import {ErrorBoundary} from "@sentry/react";
import ModalErrorBoundary from "./reusable-components/Error/ModalErrorBoundary";

const drawerWidth = 240;

interface Props {
    /**
     * Injected by the documentation to work in an iframe.
     * You won't need it on your project.
     */
    window?: () => Window;
    children?: React.ReactNode
}

const SimpleNavMenu = (props: Props) => {
  return (
      <Box sx={{display: 'flex'}}>
        <CssBaseline />
        <AppBar position="fixed">
          <Toolbar>
            <Grid
              justifyContent="space-between"
              container
              direction="row"
              spacing={10}
              alignItems="center"
            >
              <Grid
                item
                sx={{
                  padding: 0,
                }}>
                <NoLinkDecoration to="/">
                  <Grid container
                    direction="row"
                    alignItems="baseline"
                  >
                    <Grid item>
                      <Typography
                        variant="h5"
                        component="p"
                        noWrap
                        sx={{
                          fontSize: '1.75rem',
                        }}
                      >
                                              Rob&nbsp;
                      </Typography>
                    </Grid>
                    <Grid item>
                      <img
                        src={HorseLogo}
                        alt="Horse"
                        style={{verticalAlign: 'text-bottom'}}
                      />
                    </Grid>
                  </Grid>
                </NoLinkDecoration>
              </Grid>
              <Grid item>
                <LoginMenuButton />
              </Grid>
            </Grid>
          </Toolbar>
        </AppBar>
        <Box
          sx={{flexGrow: 1, p: 3, width: {sm: `calc(100% - ${drawerWidth}px)`}}}
        >
          <Toolbar />
          <ModalErrorBoundary>
              {props.children}
          </ModalErrorBoundary>
        </Box>
      </Box>
  );
}

export default SimpleNavMenu;
