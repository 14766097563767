import React from "react";
import {Box, FormControl, FormControlLabel, FormLabel, Radio, RadioGroup, Typography} from "@mui/material";
import LockIcon from '@mui/icons-material/Lock';
import SportsBarIcon from '@mui/icons-material/SportsBar';

const PrivateLabel = () => {
    return (
        <Box
            sx={{
                display: 'flex',
                justifyContent: 'center'
            }}
        >
            <Typography sx={{display: 'inline-block'}}>Private</Typography>
            <LockIcon sx={{ml: 1}}/>
        </Box>
    )
};

const PublicLabel = () => {
    return (
        <Box
            sx={{
                display: 'flex',
                justifyContent: 'center'
            }}
        >
            <Typography sx={{display: 'inline-block'}}>Public</Typography>
            <SportsBarIcon sx={{ml: 1}}/>
        </Box>
    )
}

const PublishOptions = () => {
    return (
        <FormControl sx={{
            display: 'block'
        }}>
            <FormLabel id="demo-controlled-radio-buttons-group">Options</FormLabel>
            <RadioGroup
                aria-labelledby="demo-controlled-radio-buttons-group"
                name="controlled-radio-buttons-group"
                value="private"
                // onChange={handleChange}
            >
                <FormControlLabel value="private" control={<Radio />} label={<PrivateLabel />} />
                <FormControlLabel value="public" control={<Radio />} label={<PublicLabel />} />
            </RadioGroup>
        </FormControl>
    );
};

export default PublishOptions;