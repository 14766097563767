import React from 'react';
import {Button} from '@mui/material';
import DownloadIcon from '@mui/icons-material/Download';

interface IDownloadButtonProps {
    name: string,
    href: string
}

const DownloadButton = (props: IDownloadButtonProps) => {
  const onDownload = () => {
    const link = document.createElement('a');
    link.download = props.name;
    link.href = props.href;
    link.click();
  };

  return (
    <Button
      onClick={onDownload}
      variant="contained"
      endIcon={<DownloadIcon />}
      sx={{
        mt: 2
      }}
    >
            Download
    </Button>
  );
};

export default DownloadButton;
