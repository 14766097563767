import React from "react";

const HighlightedCodeLineLeafRenderer = ({attributes, children, leaf}, content) => {
    if (leaf.prismCSS) {
        return <span className={`token ${leaf.prismCSS}`}>{content}</span>
    }
    else {
        return content;
    }
}

export default HighlightedCodeLineLeafRenderer;