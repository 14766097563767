import React, {useState, useCallback, useEffect} from 'react';
import GenericPostDto from '../../includes/models/GenericPostDto';
import {useHistory} from 'react-router-dom';
import {postGenericPostDto} from '../../includes/api-functions/GenericPostDtoCalls';
import SlateRichTextEditor from '../reusable-components/Slate-Editors/SlateRichTextEditor';
import {GenericPostSlateComponents} from './GenericPostSlateComponents';
import AddPostBase from '../reusable-components/Add-Edit-View-Default-Bases/AddPostBase';
import {INewPostProps} from "../contentTypeMapper";
import useSlateEditor from "../reusable-components/Slate-Editors/useSlateEditorState";

export const AddPostGeneric = (props: INewPostProps) => {
  const {
    contentTypeSlug
  } = props;

  const [genericPostDto, setGenericPostDto] = useState<GenericPostDto>(new GenericPostDto());
  const history = useHistory();
  const [allowSubmission, setAllowSubmission] = useState<boolean>(false);

  const [editor] = useSlateEditor(GenericPostSlateComponents);

  useEffect(() => {
    setGenericPostDto(new GenericPostDto());
  }, [contentTypeSlug]);

  const onContentChange = (newEditorStateValue: any) => {
    setGenericPostDto((prevState) => ({
      ...prevState,
      slateContent: newEditorStateValue,
    }));
  };

  const goToEditPage = useCallback((genericPostDto:GenericPostDto) =>
    history.push(`/edit/${genericPostDto.hashId}`),
  [history],
  );

  const handleChange = (e:any) => {
    const {name, value} = e.target;
    setGenericPostDto((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const publishGenericPostButtonClicked = async () => {
    setAllowSubmission(true);
    await postGenericPostDto(genericPostDto, goToEditPage, handleError);
  };

  const handleError = (e: Response) => {
    console.log(e.status);
  };

  return (
    <AddPostBase
      allowSubmission={allowSubmission}
      contentTypeName="Rich Text"
      handleChange={handleChange}
      postDto={genericPostDto}
      setPostDto={setGenericPostDto}
      publishPostButtonClicked={publishGenericPostButtonClicked}
    >
      <SlateRichTextEditor
        content={genericPostDto.slateContent}
        editor={editor}
        onContentChange={onContentChange}
        readonly={0}
        slateComponents={GenericPostSlateComponents}
      />
    </AddPostBase>
  );
};
