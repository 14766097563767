import {Node, Transforms} from "slate";

let defaultJoke = () => {
    return {
        type: "joke",
        children: [
            {
                type: "text",
                text: "",
            }
        ],
        key: Math.random(),
    };
};

let defaultJokeEditorBlock = () => ({
    type: "joke-editor",
    children: [defaultJoke()]
});

const insertJokeEditorBlock = (editor, event) => {
    event.preventDefault();

    console.log(editor.selection.anchor.path);

    const path = [editor.selection.anchor.path[0] + 1];

    Transforms.insertNodes(editor, defaultJokeEditorBlock(), {at: path});
    Transforms.select(editor, path);
}

const resetToJokeEditorBlock = (editor, event, slateComponents) => {
    if (editor.selection.anchor.offset !== 0
        || !editor.selection.anchor.path.every((node) => node === 0)
    ) {
        return;
    }

    const elementPath = editor.selection.anchor.path.slice(0,-1);
    const selectedElement = Node.get(editor, elementPath);

    const componentThatMayBeReset = slateComponents.find((slateComponent) => slateComponent.type === selectedElement.type);

    if (componentThatMayBeReset) {
        componentThatMayBeReset.resetToDefault(editor, elementPath, defaultJokeEditorBlock());
    }
}

export default {
    insertJokeEditorBlock,
    defaultJokeEditorBlock,
    resetToJokeEditorBlock
};