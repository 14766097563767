import React, {useEffect} from 'react'
import {Box, Fab} from "@mui/material";
import FileUploaderViewer from "../../../../Material-File-Viewers/FileUploaderViewer";
import {Transforms} from "slate";
import {ReactEditor, useReadOnly, useSelected, useSlate} from "slate-react";
import {getFileDtoByHashId, getFileDtosFileByHashId} from "../../../../../../includes/api-functions/FileDtoCalls";

const FileBlock = (props) => {
    const editor = useSlate();
    const selected = useSelected()
    const readOnly = useReadOnly();

    const {
        element
    } = props;

    useEffect(() => {
        const path = ReactEditor.findPath(editor, props.element);

        const hashId = element.hashId || element.fileDto.hashId;

        if (!element.fileDto && hashId) {
            getFileDtoByHashId(hashId, fileDto => {
                Transforms.setNodes(
                    editor,
                    {
                        ...element,
                        fileDto
                    },
                    {
                        at: path,
                    }
                )
            })
        }
        else if ((!element.fileDto.file || !(element.fileDto.file instanceof File)) && hashId) {
            getFileDtosFileByHashId(hashId,(file) => {
                Transforms.setNodes(
                    editor,
                    {
                        ...element,
                        fileDto: {
                            ...element.fileDto,
                            file
                        }
                    },
                    {
                        at: path,
                    }
                )
            })
        }
    }, [element])

    const onDrop = (acceptedFiles) => {
        if (acceptedFiles.length == 0) {
            return;
        }

        const path = ReactEditor.findPath(editor, props.element);

        Transforms.setNodes(
            editor,
            {
                type: props.element.type,
                key: props.element.key,
                fileDto: {
                    ...props.element.fileDto,
                    file: acceptedFiles[0]
                }
            },
            {
                at: path,
            }
        )
    }

    const onDeleteCallback = () => {
        const path = ReactEditor.findPath(editor, props.element);

        Transforms.removeNodes(
            editor,
            {
                at: path,
            }
        )
    }

    return (
        <Box
            {...props.attributes}
            contentEditable={false}
            sx={{
                mt: 2,
                mb: 2,
            }}
        >
            <FileUploaderViewer
                file={props.element.fileDto?.file}
                initialUpload
                onDropCallback={onDrop}
                onDeleteCallback={onDeleteCallback}
                hideDeleteIcon={readOnly}
                sx={{
                    borderColor: (selected) ? 'action.active' : 'divider',
                    borderStyle: (selected) ? 'solid' : 'none',
                    borderRadius: '5px',
                    height: "auto"
                }}
            />
            <Box sx={{height: 0}}>
                {props.children}
            </Box>
        </Box>
    );
};

export default FileBlock;

