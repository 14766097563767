import {Box, Button, Grid, Paper, Typography} from "@mui/material";
import React, {useCallback} from "react";
import {useTheme} from "@mui/material/styles";
import UserProfileDto from "../../../includes/models/UserProfileDto";
import {accountService} from "../../../includes/api-authorization/services";
import {useHistory} from "react-router-dom";
import AddAPhotoIcon from '@mui/icons-material/AddAPhoto';
import ImageViewer from "../Material-File-Viewers/ImageViewer";
import SlateRichTextEditor from "../Slate-Editors/SlateRichTextEditor";
import useSlateEditor from "../Slate-Editors/useSlateEditorState";
import {UserProfileSlateComponents} from "../../UserProfile/UserProfileSlateComponents";

interface UserProfileProps {
    readOnly?: boolean,
    userProfileDto: UserProfileDto,
    onFileChangeCallback?: (e: React.ChangeEvent<HTMLInputElement>) => void,
    onSlateDescriptionContentChange?: (e: React.ChangeEvent<HTMLInputElement>) => void,
    onUserProfileContentChanged: (jsonString: string) => void
}


const UserProfileViewer = (props: UserProfileProps) => {
    const {
        readOnly,
        userProfileDto,
        onFileChangeCallback,
        onSlateDescriptionContentChange
    } = props;

    const theme = useTheme();
    const history = useHistory();

    const [editor] = useSlateEditor(UserProfileSlateComponents);

    const onEditUserProfileButtonClicked = useCallback(() =>
            history.push(`/edit/${userProfileDto.hashId}`),
        [history, userProfileDto]
    );

    const editProfileButton = accountService.userValue?.username === userProfileDto?.applicationUserDto?.username;

    return (
        <Box>
            <Box
                sx={{
                    m: -3,
                    height: '200px',
                    borderBottom: '5px solid',
                    borderColor: theme.palette.text.primary,
                    overflow:'hidden'
                }}
            >
                {!readOnly &&
                    <Box
                        sx={{
                            position: 'absolute',
                            width: 'calc(100% + 48px)',
                            height: '200px',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'flex-start',
                            '&:hover': {
                                backgroundColor: 'black',
                                opacity: .5,
                            },
                            zIndex: 0,
                        }}
                    >
                        <input
                            accept="image/*"
                            name="coverPhotoDto"
                            type="file"
                            onChange={onFileChangeCallback}
                            style={{
                                opacity: 0,
                                position: 'absolute',
                                top: 0,
                                left: 0,
                                bottom: 0,
                                right: 0,
                                width: '100%',
                                borderRadius: '20px',
                                height: '200px',
                            }}
                        />
                        <AddAPhotoIcon
                            sx={{
                                ml: 4
                            }}
                        />
                    </Box>
                }
                {userProfileDto?.coverPhotoDto?.file &&
                    <ImageViewer
                        hideDownloadButton
                        file={userProfileDto?.coverPhotoDto?.file}
                        imageSx={{
                            width: '100%',
                            height: '200px',
                            objectFit: 'cover',
                        }}
                    />
                }
            </Box>
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'center',
                }}
            >
                <Box
                    sx={{
                        m: -3,
                        top: '60px',
                        position: 'absolute',
                        display: 'block',
                        border: '5px solid',
                        borderColor: theme.palette.text.primary,
                        borderRadius: '20px',
                        overflow:'hidden'
                    }}
                >
                    <Paper
                        sx={{
                            width: '200px',
                            height: '200px',
                        }}
                    >
                        {!readOnly &&
                            <Box
                                sx={{
                                    position: 'absolute',
                                    width: '100%',
                                    height: '100%',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    '&:hover': {
                                        backgroundColor: 'black',
                                        opacity: .5,
                                    },
                                    zIndex: 1,
                                }}
                            >
                                <input
                                    accept="image/*"
                                    onChange={onFileChangeCallback}
                                    name="profilePhotoDto"
                                    type="file"
                                    style={{
                                        opacity: 0,
                                        position: 'absolute',
                                        top: 0,
                                        left: 0,
                                        bottom: 0,
                                        right: 0,
                                        width: '100%',
                                        height: '200px',
                                    }}
                                />
                                <AddAPhotoIcon />
                            </Box>
                        }
                        {userProfileDto?.profilePhotoDto?.file &&
                            <ImageViewer
                                hideDownloadButton
                                file={userProfileDto?.profilePhotoDto?.file}
                                imageSx={{
                                    width: '100%',
                                    height: '100%',
                                    objectFit: 'cover',
                                    zIndex: 0
                                }}
                            />
                        }
                    </Paper>
                </Box>
            </Box>
            <Box
                sx={{
                    mt: '80px',
                    mb: '20px'
                }}
            >
                <Grid
                    container
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="center"
                    spacing={2}
                >
                    <Grid item>
                            <Typography variant="h4">
                                {userProfileDto.applicationUserDto?.name}
                            </Typography>
                            <Typography variant="h5">
                                @{userProfileDto.applicationUserDto?.username}
                            </Typography>
                    </Grid>
                    {readOnly && editProfileButton &&
                        <Grid item>
                            <Button
                                variant="contained"
                                onClick={onEditUserProfileButtonClicked}
                            >
                                Edit Profile
                            </Button>
                        </Grid>
                    }
                </Grid>
            </Box>
            {userProfileDto?.description &&
                <SlateRichTextEditor
                    content={userProfileDto.description}
                    editor={editor}
                    onContentChange={onSlateDescriptionContentChange}
                    placeHolder="Describe your profile here..."
                    readonly={0}
                    slateComponents={UserProfileSlateComponents}
                />
            }
            <Typography variant="h5" sx={{mt: '100px', textAlign: 'center'}}>
                Profiles Coming Soon
            </Typography>
        </Box>
    );
};

export default UserProfileViewer;