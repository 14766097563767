import Typography from "@mui/material/Typography";
import {Box, Card, Grid} from "@mui/material";
import React from "react";
import {ICellRendererParams} from "ag-grid-community";
import PostDto from "../../../includes/models/PostDto";
import {PostTermsBlock} from "../Term-Components/PostTermsBlock";

interface IPostRowProps extends ICellRendererParams {
  data: PostDto,
}

const PostRow = (props : IPostRowProps) => {
  const {
    data: postDto,
  } = props;

  return (
    <Box sx={{
      m: 1
    }}>
      <Typography variant="h6" component="h2">{postDto.name}</Typography>
      {postDto.termDtos.length > 0 &&
        <Box>
          <Typography variant="subtitle2">{`Tags:`}</Typography>
          <PostTermsBlock
            readonly
            size="small"
            value={postDto.termDtos}
          />
        </Box>
      }
    </Box>
  )
}

export default PostRow;