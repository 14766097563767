import JokeBlockTransforms from "./src/JokeBlockTransforms";
import JokeEditorBlock from "./src/SlateJokeEditorBlock";

const SlateJokeEditorBlockComponent = {
    author: 'Rob Reinhard',
    block: JokeEditorBlock,
    decorators: undefined,
    default: true,
    defaultResetHandler: JokeBlockTransforms.resetToJokeEditorBlock,
    dependencyTypes: undefined,
    leafRenderers: undefined,
    name: 'Joke Editor Block',
    onDrop: (editor, event) => {
        event.preventDefault();
    },
    onKeyDown: (editor, event) => {
        if (event.key === "Enter") {
            JokeBlockTransforms.insertJokeEditorBlock(editor, event);
        }
    },
    plugins: undefined,
    resetToDefault: undefined,
    toolbar: undefined,
    transforms: undefined,
    type: 'joke-editor'
};

export default SlateJokeEditorBlockComponent;