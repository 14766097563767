import TermDto from './TermDto';
import AccountDto from "./Authentication/AccountDto";

class PostDto {
    applicationUserDto: AccountDto | null = null;
    name: string = '';
    hashId?: string = undefined;
    contentTypeSlug: string = '';
    date: string = new Date().toISOString();
    hashedParent: string | null = null;
    termDtos: TermDto[] = [];
    dependent: boolean = false;

    constructor(postDto?: Partial<PostDto>) {
      Object.assign(this, postDto);
    };
}

export default PostDto;


