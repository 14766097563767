import React, {SetStateAction} from "react";
import TermGroupingDto from "../../../includes/models/TermGroupingDto";
import Paper from "@mui/material/Paper";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import TabPanel from "../TabPanel/TabPanel";
import {Box} from "@mui/material";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import SlugUtils from "../../../includes/validators/SlugUtils";
import {SxProps} from "@mui/system";
import ColorPicker from "../ColorPicker/ColorPicker";

interface AddEditTermGroupingProps {
  label: string,
  termGroupingDto: TermGroupingDto,
  onButtonClicked: () => Promise<void>,
  setTermGroupingDto: (value: SetStateAction<TermGroupingDto | undefined>) => void,
  sx?: SxProps
}

const AddEditTermGrouping = (props: AddEditTermGroupingProps) => {
  const {
    label,
    termGroupingDto,
    setTermGroupingDto,
    onButtonClicked,
    sx
  } = props;

  const handleChange = (e:any) => {
    const {name, value} = e.target;
    setTermGroupingDto((prevState) => {
      if (prevState === undefined) {
        return undefined;
      }

      return ({
        ...prevState,
        [name]: value,
      })
    });
  };

  const handleNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (SlugUtils.slugify(termGroupingDto.name) == termGroupingDto.slug) {
      SlugUtils.inputSlugify(e, 'slug', setTermGroupingDto);
    }

    handleChange(e);
  }

  const handleSlugField = (e: React.ChangeEvent<HTMLInputElement>) => {
    SlugUtils.inputSlugify(e, 'slug', setTermGroupingDto);
  };

  const handleColorChange = (color: string) => {
    setTermGroupingDto((prevState) => {
      if (prevState === undefined) {
        return undefined;
      }

      return ({
        ...prevState,
        'color': color,
      })
    });
  }

  return <Paper
    sx={{
      ...sx
    }}
  >
    <Tabs
      value={0}
      aria-label="simple tabs example"
      sx={{
        width: "100%",
      }}
    >
      <Tab
        label={label}
        sx={{
          minWidth: "100%",
        }}
      />
    </Tabs>
    <TabPanel value={0} index={0}>
      <Box
        sx={{
          padding: 2,
        }}
      >
        <TextField
          label="Name"
          fullWidth
          sx={{
            fontSize: "36px",
            marginBottom: 3,
          }}
          onChange={handleNameChange}
          name="name"
          variant="standard"
          value={termGroupingDto.name}
        />
        <ColorPicker
          sx={{
            width: '60%',
            mb: 2
          }}
          selectedColor={termGroupingDto.color}
          onColorSelected={handleColorChange}
        />
        <TextField
          label="Slug"
          fullWidth
          sx={{
            fontSize: "36px",
            marginBottom: 3,
          }}
          onChange={handleSlugField}
          name="slug"
          variant="standard"
          value={termGroupingDto.slug}
        />
        <Button
          variant="contained"
          color="primary"
          onClick={onButtonClicked}
        >
          {label}
        </Button>
      </Box>
    </TabPanel>
  </Paper>;
}

export default AddEditTermGrouping;