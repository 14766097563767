import React from 'react'
import styled from "@emotion/styled";

const OrderedListStyle = styled.ol`
    font-size: 1rem;
    margin-bottom: 0;
    margin-top: 0;
`;

const OrderedList = (props) => {
    const {
        element: {
            start
        }
    } = props;

    return (
        <OrderedListStyle {...props.attributes} start={start}>
            {props.children}
        </OrderedListStyle>
    );
};

export default OrderedList;