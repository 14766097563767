import React from 'react';
import TermDto from '../../../includes/models/TermDto';
import TermChip from './TermChip';
import styled from '@emotion/styled';
import {Box} from "@mui/material";
import {SxProps} from "@mui/system";

const StyledTermChip = styled(TermChip)`
  marginRight: 2px !important;
  marginBottom: 2px !important;
`;

interface IPostTermBlockProps {
    onTermDeleted?: any,
    readonly?: boolean,
    size?: 'medium' | 'small'
    value: TermDto[],
    sx?: SxProps
}

export const PostTermsBlock = (props: IPostTermBlockProps) => {
  const {readonly, onTermDeleted, size, sx, value} = props;

  return (
    <Box sx={sx}>
      {value.map((term) => (
        <StyledTermChip
          label={term.name}
          onTermDelete={onTermDeleted}
          readonly={readonly}
          size={size}
          term={term}
        />
      ))}
    </Box>
  );
};
