import React from 'react'
import Typography from "@mui/material/Typography";
import {useReadOnly} from "slate-react";
import styled from "@emotion/styled";
import {useTheme} from "@mui/material/styles";

const EmptyLine = styled.div`
  margin-bottom: 24px;
`;

const StyledBlockQuote = styled.blockquote`
  margin-left: 0px;
  padding:1.2em 30px 1.2em 30px;
  border-left: 6px solid ${props => props.theme.palette.text.secondary};
  position: relative;
`;

const BlockQuote = (props) => {
    const {
        element: {
            children
        }
    } = props;

    const theme = useTheme();

    const readOnly = useReadOnly();

    if (readOnly && children.length === 1 && children[0].text === '') {
        return <EmptyLine></EmptyLine>
    }

    return <StyledBlockQuote
        {...props.attributes}
        theme={theme}
    >{props.children}</StyledBlockQuote>
};

export default BlockQuote;

