import React from 'react';
import {Paper} from "@mui/material";
import styled from "@emotion/styled";

const StickyPaper = styled(Paper)`
  position: -webkit-sticky;
  position: sticky;
  top: 88px;
  z-index: 2;
`;

export default StickyPaper;
