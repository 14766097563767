import React from "react";

const BoldLeafRenderer = ({attributes, children, leaf}, content) => {
    if (leaf.bold) {
        return <strong>{content}</strong>;
    }
    else {
        return content;
    }
}

export default BoldLeafRenderer;