import React, {useState, useCallback, useEffect} from 'react';
import {useHistory} from "react-router-dom";
import BitWriter from "../slate-js/BitWriter";
import BitSlateComponents from "../slate-js/BitSlateComponents";
import BitDto, {BitDtoUtils} from "../../includes/models/BitDto";
import JokeBlockTransforms from "../slate-js/SlateJokeComponents/src/JokeBlockTransforms";
import {postBitDto} from "../../includes/api-functions/BitDtoCalls";
import AddPostBase from "../../../rob-horse/reusable-components/Add-Edit-View-Default-Bases/AddPostBase";
import {INewPostProps} from "../../../rob-horse/contentTypeMapper";
import useSlateEditor from "../../../rob-horse/reusable-components/Slate-Editors/useSlateEditorState";

const AddBit = (props: INewPostProps) => {

    const { contentTypeSlug } = props;
    const [bitDto, setBitDto] = useState<BitDto>(new BitDto());
    const [slateContent, setSlateContent] = useState<any>({
        type: "bit-editor",
        children: [JokeBlockTransforms.defaultJokeEditorBlock()]
    });
    const history = useHistory();
    const [allowSubmission, setAllowSubmission] =  useState<boolean>(false);

    const [editor] = useSlateEditor(BitSlateComponents);

    useEffect(() => {
        setBitDto(new BitDto({contentTypeSlug}));
    }, [contentTypeSlug])

    const onContentChange = (newEditorStateValue: any) => {
        setSlateContent(newEditorStateValue);
    };

    const goToEditPage = useCallback((bitDto:BitDto) =>
            history.push(`/edit/${bitDto.hashId}`),
        [history]
    );

    const handleChange = (e:any) => {
        const { name, value } = e.target;
        setBitDto(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const publishBitButtonClicked = async () => {
        setAllowSubmission(true);
        setBitDto(prevState => ({
            ...prevState,
            jokes: BitDtoUtils.createJokeContentFromSlateContent(slateContent)
        }));
    }

    useEffect(() => {
        if (allowSubmission && bitDto.jokes.length > 0) {
            postBitDto(bitDto,goToEditPage, handleError);
        }
    }, [allowSubmission, bitDto]);

    const handleError = (e: Response) => {
        console.log(e.status);
    }

    return (
        <AddPostBase
            allowSubmission={allowSubmission}
            contentTypeName="Bit"
            handleChange={handleChange}
            postDto={bitDto}
            setPostDto={setBitDto}
            publishPostButtonClicked={publishBitButtonClicked}
        >
            <BitWriter
                content={slateContent}
                editor={editor}
                onContentChange={onContentChange}
                readonly={0}
                slateComponents={BitSlateComponents}
            />
        </AddPostBase>
    );
}

export default AddBit;
