import PostDto from "../../../includes/models/PostDto";
import FileDto from "../../../includes/models/FileDto";
import SetlistDto from "./SetlistDto";

export default class PerformanceDto extends PostDto {
    fileDto: FileDto | null = null;
    setlistDto: SetlistDto | null = null;
    contentTypeSlug: string = "performance"
    location: string = "";
    comments: string = "";

    constructor(setlistDto?: Partial<PerformanceDto>) {
        super();
        Object.assign(this, setlistDto);
    };
}