import React, {useState, useEffect, MouseEvent, useMemo} from 'react';
import GenericPostDto, {GenericPostDtoUtils} from '../../includes/models/GenericPostDto';
import {getGenericPostDtoByHashId, postGenericPostDto, getGenericPostRevisionDtosByHashId} from '../../includes/api-functions/GenericPostDtoCalls';
import SlateRichTextEditor from '../reusable-components/Slate-Editors/SlateRichTextEditor';
import {Transforms} from 'slate';
import {GenericPostSlateComponents} from './GenericPostSlateComponents';
import EditPostBase from '../reusable-components/Add-Edit-View-Default-Bases/EditPostBase';
import {IExistingPageProps} from "../contentTypeMapper";
import {TermDtoUtils} from "../../includes/models/TermDto";
import {onSuccessfulGenericPost, onSuccessfulGetPostRevisions} from "./genericPostHooks";
import useSlateEditor from "../reusable-components/Slate-Editors/useSlateEditorState";

export const EditPostGeneric = (props: IExistingPageProps) => {
  const {hashId} = props;
  const [genericPostDto, setGenericPostDto] = useState<GenericPostDto>(new GenericPostDto());
  const [genericPostDtoRevisions, setGenericPostDtoRevisions] = useState<GenericPostDto[]>([]);
  const [revisionIndex, setRevisionIndex] = useState<number>(0);
  const [allowUpdate, setAllowUpdate] = useState<boolean>(false);

  const [editor] = useSlateEditor(GenericPostSlateComponents);

  useEffect(() => {
    getGenericPostDtoByHashId(hashId, onSuccessfulGetPostHashIdCallback, handleError).then(async () => {
      await getGenericPostRevisionDtosByHashId(hashId, onSuccessfulGetPostRevisionsCallback, handleError);
      setAllowUpdate(true);
    });
  }, []);

  const onSuccessfulGetPostHashIdCallback = onSuccessfulGenericPost(hashId, setGenericPostDto);

  const onSuccessfulGetPostRevisionsCallback = onSuccessfulGetPostRevisions(setGenericPostDtoRevisions, setRevisionIndex);

  const onContentChange = (content: any) => {
    setGenericPostDto((prevState) => ({
      ...prevState,
      slateContent: content,
    }));
  };

  const handleError = (e: Response) => {
    console.log(e.status);
  };

  const handleChange = (e:any) => {
    const {name, value} = e.target;
    setGenericPostDto((prevState) => ({
      ...prevState,
      [name]: value,
    }));
    console.log(genericPostDto);
  };

  const onSuccessfulPostCallback = () => {
    getGenericPostDtoByHashId(hashId, onSuccessfulGetPostHashIdCallback, handleError).then(async () => {
      await getGenericPostRevisionDtosByHashId(hashId, onSuccessfulGetPostRevisionsCallback, handleError);
      setAllowUpdate(true);
    });
  };

  const updateGenericPostButtonClicked = async () => {
    setAllowUpdate(false);
    await postGenericPostDto(genericPostDto, onSuccessfulPostCallback, handleError);
  };

  const onPreviousClicked = (event: MouseEvent) => {
    if (revisionIndex !== 0) {
      const index = revisionIndex - 1;
      setRevisionIndex(index);
      Transforms.deselect(editor);
      onContentChange(genericPostDtoRevisions[index].slateContent);
    }
  };

  const onNextClicked = (event: MouseEvent) => {
    if (revisionIndex !== genericPostDtoRevisions.length-1) {
      const index = revisionIndex + 1;
      setRevisionIndex(index);
      Transforms.deselect(editor);
      onContentChange(genericPostDtoRevisions[index].slateContent);
    }
  };

  const disableUpdate =
            allowUpdate &&
            genericPostDtoRevisions.length > 0 &&
            GenericPostDtoUtils.equals(genericPostDto, genericPostDtoRevisions[genericPostDtoRevisions.length-1]) &&
            TermDtoUtils.equalArrays(genericPostDto.termDtos, genericPostDtoRevisions[0].termDtos);

  return (
    <EditPostBase
      allowUpdate={!disableUpdate}
      contentTypeName="Rich Text"
      handleChange={handleChange}
      hashId={hashId}
      revisionData={{
        onPreviousClicked: onPreviousClicked,
        onNextClicked: onNextClicked,
        revisionIndex: revisionIndex,
        postDtoRevisions: genericPostDtoRevisions,
      }}
      postDto={genericPostDto}
      setPostDto={setGenericPostDto}
      updatePostButtonClicked={updateGenericPostButtonClicked}
    >
      <SlateRichTextEditor
        content={genericPostDto.slateContent}
        editor={editor}
        onContentChange={onContentChange}
        readonly={0}
        slateComponents={GenericPostSlateComponents}
      />
    </EditPostBase>
  );
};
