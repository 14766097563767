import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import {Box} from '@mui/material';
import TermSidebar from '../Term-Components/TermSidebar';
import Button from '@mui/material/Button';
import React, {ChangeEvent, MouseEvent, useCallback, useState} from 'react';
import PostDto from '../../../includes/models/PostDto';
import TabPanel from '../TabPanel/TabPanel';
import IconButton from '@mui/material/IconButton';
import LockIcon from '@mui/icons-material/Lock';
import DateViewer from '../Post-Sidebar-Components/DateViewer';
import styled from '@emotion/styled';
import RevisionBar from '../Post-Sidebar-Components/RevisionBar';
import {useHistory} from 'react-router-dom';
import StickyPaper from "./StickyPaper";

const StyledRevisionBar = styled(RevisionBar)`
  width: 320px;
  margin: 0 auto;
  padding-left: 24px;
  padding-right: 24px;
`;

const a11yProps = (index: number) => {
  return {
    'id': `component-tab-${index}`,
    'aria-controls': `component-tabpanel-${index}`,
  };
};

interface IEditPostBase {
    allowUpdate: boolean,
    contentTypeName: string,
    children?: React.ReactNode,
    handleChange: (event: ChangeEvent) => void,
    hashId: string,
    postDto: PostDto,
    revisionData?: {
        postDtoRevisions: PostDto[],
        revisionIndex: number,
        onNextClicked: (event: MouseEvent) => void,
        onPreviousClicked: (event: MouseEvent) => void,
    }
    setPostDto: (value: React.SetStateAction<any>) => void,
    updatePostButtonClicked: () => void
};

const EditPostBase = (props: IEditPostBase) => {
  const {
    allowUpdate,
    contentTypeName,
    children,
    handleChange,
    postDto,
    hashId,
    revisionData,
    setPostDto,
    updatePostButtonClicked,
  } = props;

  const history = useHistory();
  const [tabPanelValue, setTabPanelValue] = useState(0);

  const handleComponentTabChange = (event: React.ChangeEvent<{}>, newTabPanelValue: number) => {
    setTabPanelValue(newTabPanelValue);
  };

  const clickViewButton = useCallback((event:MouseEvent) =>
    history.push('/view/' + hashId),
  [history],
  );

  return (
    <Grid container spacing={3}>
      <Grid
        item
        xs={12} sm={12} md={6}
        sx={{
          marginBottom: 2,
          minHeight: '70vh',
        }}
      >
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          sx={{
            minHeight: '60px',
            marginBottom: 2,
          }}
        >
          <Grid item xs={10}>
            <Typography variant="h4" component="h2">Edit {contentTypeName}</Typography>
          </Grid>
          <Grid item xs={2}>
            <IconButton
              onClick={clickViewButton}
              aria-label="delete"
              sx={{
                float: 'right',
              }}
              size="large">
              <LockIcon fontSize="large" />
            </IconButton>
          </Grid>
        </Grid>
        <TextField
          variant="outlined"
          label="Title"
          sx={{
            width: '100%',
            fontSize: '36px',
            marginBottom: 2,
          }}
          name="name"
          onChange={handleChange}
          value={postDto.name}
        />
        {children}
      </Grid>
      <Grid item xs={12} sm={12} md={6} lg={4}>
        <StickyPaper>
          <Tabs
            value={tabPanelValue}
            aria-label="simple tabs example"
            onChange={handleComponentTabChange}
            sx={{
              width: '100%',
            }}
          >
            <Tab
              label="Post"
              sx={{
                minWidth: revisionData ? '50%' : '100%',
              }}
              {...a11yProps(0)}
            />
            {revisionData &&
                            <Tab
                              label="Revisions"
                              sx={{
                                minWidth: '50%',
                              }}
                              {...a11yProps(1)}
                            />
            }
          </Tabs>
          <TabPanel value={tabPanelValue} index={0}>
            <Box
              sx={{
                padding: 2,
              }}
            >
              <TermSidebar
                setPostDto={setPostDto}
                termDtos={postDto.termDtos}
              />
              <Button
                disabled={
                  !allowUpdate
                }
                variant="contained"
                color="primary"
                onClick={updatePostButtonClicked}
              >
                                Update
              </Button>
            </Box>
          </TabPanel>
          {revisionData &&
                        <TabPanel value={tabPanelValue} index={1}>
                          <Grid
                            container
                            sx={{
                              padding: 2,
                            }}
                          >
                            <Grid container item xs={12} justifyContent="space-between" alignItems="center">
                              <StyledRevisionBar
                                onPreviousClicked={revisionData.onPreviousClicked}
                                onNextClicked={revisionData.onNextClicked}
                                revisionIndex={revisionData.revisionIndex}
                                revisionsLength={revisionData.postDtoRevisions.length}
                              />
                            </Grid>
                            <Grid
                              item xs={12}
                              sx={{
                                marginTop: 2,
                              }}
                            >
                              <DateViewer
                                date={revisionData.postDtoRevisions[revisionData.revisionIndex]?.date}
                              />
                            </Grid>
                          </Grid>
                        </TabPanel>
          }
        </StickyPaper>
      </Grid>
    </Grid>
  );
};

export default EditPostBase;
