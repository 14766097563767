import React from 'react';
import {IExistingPageProps} from "../contentTypeMapper";
import EditPostBase from "../reusable-components/Add-Edit-View-Default-Bases/EditPostBase";
import UserProfileViewer from "../reusable-components/User-Profile/UserProfileViewer";
import {useEffect, useState} from "react";
import UserProfileDto from "../../includes/models/UserProfileDto";
import userProfileDtoCalls from "../../includes/api-functions/UserProfileDtoCalls";
import * as fileDtoCalls from "../../includes/api-functions/FileDtoCalls";
import CenteredSpinner from "../reusable-components/CenteredSpinner/CenteredSpinner";
import {Box} from "@mui/material";
import FileDto from "../../includes/models/FileDto";
import {format} from "date-fns";

const EditUserProfile = (props: IExistingPageProps) => {
    const {hashId} = props;

    const [userProfileDto, setUserProfileDto] = useState<UserProfileDto | null>(null);
    const [userProfileDtoRevisions, setUserProfileDtoRevisions] = useState<UserProfileDto[]>([]);
    const [errorMessage, setErrorMessage] = useState<boolean>(false);
    const [allowUpdate, setAllowUpdate] = useState<boolean>(false);

    const errorMessageHandler = (error: Response) => {
        setErrorMessage(true);
    }

    const onSuccessfulUserProfileCall = (userProfileDto: UserProfileDto) => {
        setUserProfileDto(new UserProfileDto(userProfileDto));
    }

    useEffect(() => {
        userProfileDtoCalls.getUserProfileByHashId(hashId, onSuccessfulUserProfileCall, errorMessageHandler).then(async () => {
            await userProfileDtoCalls.getUserProfileRevisionsByHashId(hashId, setUserProfileDtoRevisions, errorMessageHandler);
            setAllowUpdate(true);
        })
    }, [hashId]);

    const {
        coverPhotoDto,
        profilePhotoDto
    } = userProfileDto || {};

    useEffect(() => {
        if (!coverPhotoDto || !coverPhotoDto.hashId || coverPhotoDto.file) {
            return;
        }

        fileDtoCalls.getFileDtosFileByHashId(coverPhotoDto.hashId, onCoverPhotoFileChange, errorMessageHandler)
    }, [coverPhotoDto]);

    useEffect(() => {
        if (!profilePhotoDto || !profilePhotoDto.hashId || profilePhotoDto.file) {
            return;
        }

        fileDtoCalls.getFileDtosFileByHashId(profilePhotoDto.hashId, onProfilePhotoFileChange, errorMessageHandler)
    }, [profilePhotoDto]);

    if (!userProfileDto) {
        return <CenteredSpinner />
    }

    const onCoverPhotoFileChange = (newFile: any) => {
        setUserProfileDto(prevState => {
            if (prevState === null || prevState?.coverPhotoDto === null) {
                return prevState;
            }

            return ({
                ...prevState,
                coverPhotoDto: {
                    ...prevState?.coverPhotoDto,
                    file: newFile
                }
            })
        });
    };

    const onProfilePhotoFileChange = (newFile: any) => {
        setUserProfileDto(prevState => {
            if (prevState === null || prevState?.profilePhotoDto === null) {
                return prevState;
            }

            return ({
                ...prevState,
                profilePhotoDto: {
                    ...prevState?.profilePhotoDto,
                    file: newFile
                }
            })
        });
    };

    const onUserProfileContentChanged = (jsonString: string) => {
        let parsedJSON: any = undefined;

        try {
            parsedJSON = JSON.parse(jsonString);
        }
        catch {

        }

        setUserProfileDto(prevState => {
            if (prevState === null || prevState?.profilePhotoDto === null) {
                return prevState;
            }

            return ({
                ...prevState,
                description: parsedJSON || jsonString
            })
        })
    }

    const handleChange = (e:any) => {
        const {name, value} = e.target;

        if (userProfileDto === null) {
            return;
        }

        setUserProfileDto((prevState) => {
            if (prevState === null) {
                return null;
            }

            return ({
                ...prevState,
                [name]: value,
            })
        });
    };

    const onFileChange= (event: React.ChangeEvent<HTMLInputElement>) => {
        const {name, value, files} = event.target;

        if (!name || files?.length !== 1) {
            return;
        }

        setUserProfileDto((prevState) => {
            if (prevState === null) {
                return null;
            }

            return ({
                ...prevState,
                [name]: new FileDto({
                    name: `${name} photo ${format(new Date(), 'MM/dd/yyyy')}`,
                    file: files[0]
                }),
            })
        });
    }

    const onSlateDescriptionChange = (content: any) => {
        setUserProfileDto((prevState) => {
            if (prevState === null) {
                return null;
            }

            return ({
                ...prevState,
                description: content,
            })
        });
    };

    const updateUserProfileButtonClicked = async () => {
        // setAllowUpdate(false);
        await userProfileDtoCalls.postUserProfile(userProfileDto, () => null, errorMessageHandler);
    }

    return (
        <EditPostBase
            allowUpdate={allowUpdate}
            contentTypeName="User Profile"
            handleChange={handleChange}
            hashId={hashId}
            postDto={userProfileDto}
            setPostDto={setUserProfileDto}
            updatePostButtonClicked={updateUserProfileButtonClicked}
        >
            <Box
                sx={{
                    m: 3,
                    position: 'relative'
                }}
            >
                <UserProfileViewer
                    onUserProfileContentChanged={onUserProfileContentChanged}
                    onFileChangeCallback={onFileChange}
                    onSlateDescriptionContentChange={onSlateDescriptionChange}
                    userProfileDto={userProfileDto}
                />
            </Box>
        </EditPostBase>
    )
};

export default EditUserProfile;