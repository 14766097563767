import React, {useCallback} from 'react';
import {Button, Card, Grid, Typography} from "@mui/material";
import {useHistory} from "react-router-dom";

const Error404Page = () => {
  const history = useHistory();

  const goHome = useCallback(() =>
     history.push('/'),
    [history],
  );

  return (
    <Grid
      container
      direction="row"
      justifyContent="center"
      alignItems="center"
    >
      <Card
        sx={{
          position: 'absolute',
          top: '20%',
          height: 'auto',
          '-webkit-transform': 'translateY(-20%)',
          transform: 'translateY(-20%)',
          m: 2,
          p: 2
        }}
      >
        <Typography variant="h1">404.</Typography>
        <Typography variant="h2" sx={{mb: 1}}>Page Not Found.</Typography>

        <Typography>Dear Traveller,</Typography>
        <br/>
        <Typography sx={{mb: 2}}>We want to thank you for your travels. However, you are too far off the beaten path. Shall we go home?</Typography>
        <Button onClick={goHome} variant="contained">Go Home</Button>
      </Card>
    </Grid>
  )
}

export default Error404Page;