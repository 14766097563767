import SlateSetlistComponent from './SetlistComponents/SlateSetlistComponent';
import SlateParagraphBlockComponent
  from '../../../rob-horse/reusable-components/Slate-Editors/Custom-Components/SlateParagraphBlockComponent/SlateParagraphBlockComponent';
import BoldLeafRendererComponent
  from "../../../rob-horse/reusable-components/Slate-Editors/Custom-Components/CoreStyles/BoldLeafRendererComponent";
import ItalicLeafRendererComponent
  from "../../../rob-horse/reusable-components/Slate-Editors/Custom-Components/CoreStyles/ItalicLeafRendererComponent";
import UnderlineLeafRendererComponent
  from "../../../rob-horse/reusable-components/Slate-Editors/Custom-Components/CoreStyles/UnderlineLeafRendererComponent";
import SlateJokeEditorBlockComponent from "./SlateJokeComponents/SlateJokeEditorBlockComponent";
import SlateJokeBlockComponent from "./SlateJokeComponents/SlateJokeBlockComponent";

const SetlistSlateComponents = [
  BoldLeafRendererComponent,
  ItalicLeafRendererComponent,
  UnderlineLeafRendererComponent,
  SlateJokeEditorBlockComponent,
  SlateJokeBlockComponent,
  SlateSetlistComponent,
  SlateParagraphBlockComponent,
];

export default SetlistSlateComponents;
