import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import {Box} from '@mui/material';
import TermSidebar from '../Term-Components/TermSidebar';
import Button from '@mui/material/Button';
import React, {ChangeEvent, useState} from 'react';
import PostDto from '../../../includes/models/PostDto';
import TabPanel from '../TabPanel/TabPanel';
import StickyPaper from './StickyPaper';
import PublishOptions from "./PublishOptions";

const a11yProps = (index: number) => {
  return {
    'id': `component-tab-${index}`,
    'aria-controls': `component-tabpanel-${index}`,
  };
};

interface IAddPostBase {
    allowSubmission: boolean,
    children?: React.ReactNode,
    handleChange: (event: ChangeEvent) => void,
    contentTypeName: string,
    postDto: PostDto,
    setPostDto: (value: React.SetStateAction<any>) => void,
    publishPostButtonClicked: () => void
};

const AddPostBase = (props: IAddPostBase) => {
  const {
    allowSubmission,
    children,
    handleChange,
    contentTypeName,
    postDto,
    setPostDto,
    publishPostButtonClicked,
  } = props;

  const [tabPanelValue, setTabPanelValue] = useState(0);

  const handleComponentTabChange = (event: React.ChangeEvent<{}>, newTabPanelValue: number) => {
    setTabPanelValue(newTabPanelValue);
  };

  return (
    <Grid container spacing={3}>
      <Grid
        item xs={12} sm={12} md={6}
        sx={{
          marginBottom: 2,
          minHeight: '80vh',
        }}>
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          sx={{
            minHeight: '60px',
            marginBottom: 2,
          }}
        >
          <Grid item xs={12}>
            <Typography variant="h4" component="h1">Add {contentTypeName}</Typography>
          </Grid>
        </Grid>
        <TextField
          variant="outlined"
          label="Title"
          sx={{
            width: '100%',
            fontSize: '36px',
            marginBottom: 2,
          }}
          name="name"
          onChange={handleChange}
          value={postDto.name}
        />
        {children}
      </Grid>
      <Grid item xs={12} sm={12} md={4}>
        <StickyPaper>
          <Tabs
            value={tabPanelValue}
            aria-label="simple tabs example"
            onChange={handleComponentTabChange}
            sx={{
              width: '100%',
            }}
          >
            <Tab
              label="Post"
              sx={{
                minWidth: '100%',
              }}
              {...a11yProps(0)}
            />
          </Tabs>
          <TabPanel value={tabPanelValue.toString()} index="0">
            <Box
              sx={{
                padding: 2,
              }}
            >
              <TermSidebar
                setPostDto={setPostDto}
                termDtos={postDto.termDtos}
              />
              <PublishOptions />
              <Button
                disabled={allowSubmission}
                variant="contained"
                color="primary"
                onClick={publishPostButtonClicked}
                sx={{
                    mt: 2
                }}
              >
                Publish
              </Button>
            </Box>
          </TabPanel>
        </StickyPaper>
      </Grid>
    </Grid>
  );
};

export default AddPostBase;
