import PerformanceLaughBlock from "./src/PerformanceLaughBlock";

const SlatePerformanceComponent = {
    author: 'Rob Reinhard',
    block: PerformanceLaughBlock,
    decorators: undefined,
    default: false,
    defaultResetHandler: undefined,
    dependencyTypes: undefined,
    leafRenderers: undefined,
    name: 'Setlist Laugh Block',
    onDrop: (editor, event) => {
        event.preventDefault();
    },
    onKeyDown: undefined,
    plugins: undefined,
    resetToDefault: undefined,
    toolbar: undefined,
    transforms: undefined,
    type: 'setlist-laugh'
};

export default SlatePerformanceComponent;