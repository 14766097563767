import SetlistDto from '../models/SetlistDto';
import { fetchWrapper } from "../../../includes/api-authorization/helpers";
import PerformanceDto from "../models/PerformanceDto";
import performanceService from "../../theme/Performance/performanceService";
import StagingPerformanceDto from "../models/StagingPerformanceDto";

export const postPerformanceDto = async (performanceDto: PerformanceDto, onSuccessToEditCallback: (setlistDto: SetlistDto) => void, onErrorCallback: (error: Response) => void) => {
    const postToPublish = performanceService.convertPerformanceDtoToStagingPerformanceDto(performanceDto);

    await fetchWrapper.postMultiform('api/comedy/performance', postToPublish).then(onSuccessToEditCallback,onErrorCallback);
};

export const getStagingPerformanceDtoByHashId = async (hashId: string, onSuccessCallback: (stagingPerformanceDto: StagingPerformanceDto) => void, onErrorCallback: (error: Response) => void) => {
    await fetchWrapper.get('api/comedy/performance/'+hashId).then(onSuccessCallback,onErrorCallback);
}

export const updatePerformanceDto = async (performanceDto: PerformanceDto, onSuccessToEditCallback: (setlistDto: SetlistDto) => void, onErrorCallback: (error: Response) => void) => {
    const postToPublish = performanceService.convertPerformanceDtoToStagingPerformanceDto(performanceDto);

    postToPublish.file = null;

    await fetchWrapper.put('api/comedy/performance', postToPublish).then(onSuccessToEditCallback,onErrorCallback);
};