import React from 'react';
import { AgGridColumn, AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-material.css';
import styled from "@emotion/styled";
import {useTheme} from "@mui/material/styles";

export const AgMaterialDiv = styled.div`
  .ag-row:hover {
    background-color: ${props => props.theme.palette.action.hover};
  }
  @media (prefers-color-scheme: dark) {
    .ag-row, .ag-header, .ag-paging-panel {
      background-image: linear-gradient(rgba(255, 255, 255, 0.05), rgba(255, 255, 255, 0.05));
    }
  }
  --ag-foreground-color: ${props => props.theme.palette.text.secondary} !important;
  --ag-header-foreground-color: ${props => props.theme.palette.text.secondary} !important;
  --ag-secondary-foreground-color: ${props => props.theme.palette.text.secondary} !important;
  background-color: ${props => props.theme.palette.background.paper};
  --ag-subheader-background-color: ${props => props.theme.palette.background.paper};
  --ag-header-background-color: ${props => props.theme.palette.background.paper};
  --ag-background-color: ${props => props.theme.palette.background.paper};
  --ag-selected-row-background-color: ${props => props.theme.palette.action.selected};
  --ag-checkbox-unchecked-color: ${props => props.theme.palette.text.secondary};
  --ag-modal-overlay-background-color: ${props => props.theme.palette.action.selected};
  .ag-paging-button.ag-disabled span {
    color: ${props => props.theme.palette.text.secondary};
  }

  .ag-paging-panel{
    border-top-color: ${props => props.theme.palette.text.secondary} !important;
    border-top-width: 1px;
    border-top-stype: solid;
  }
  
  .ag-header-cell:hover {
    background-color: ${props => props.theme.palette.action.hover} !important;
  }
`;

export function StandardMaterialTable(props) {
    const {
        onGridReadyCallback,
        columnDefs
    } = props;

    const theme = useTheme();

    const onGridReady = (params) => {
        onGridReadyCallback(params);
    }

    const onFirstDataRendered = (params) => {
        params.api.sizeColumnsToFit();
    }

    return(
        <AgMaterialDiv
            className="ag-theme-material"
            theme={theme}
        >
            <AgGridReact
                onGridReady={onGridReady}
                onFirstDataRendered={onFirstDataRendered}
                {...props}
                style={{ width: '100%' }}
            >
                {columnDefs.map((column) => (
                    <AgGridColumn {...column} field={column.field} key={column.field}></AgGridColumn>
                ))}
            </AgGridReact>
        </AgMaterialDiv>
    );
}