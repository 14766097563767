import {Element, Transforms, Node, Text} from 'slate';
import HighlightedCodeBlockTransforms from "./HighlightedCodeBlockTransforms";
import Device from '../../../Device';
import IOSCharCodeMapper from "../../../IOSCharCodeMapper";
import {ReactEditor} from "slate-react";
import {cloneDeep, isEqual} from "lodash";

const withHighlightedCodeBlockPlugin = (editor) => {
    const { normalizeNode } = editor;

    editor.normalizeNode = (entry) => {
        const [node, path] = entry;

        if (Element.isElement(node) && node.type === 'code-block') {
            node.children.forEach((child, index) => {
                const newNodePath = path.concat(index);
                if (child.type !== 'code-line') {
                    Transforms.removeNodes(editor, {at: newNodePath});
                    Transforms.insertNodes(editor,
                        HighlightedCodeBlockTransforms.defaultHighlightedCodeLine(),
                        {at: newNodePath});
                    Transforms.select(editor, newNodePath);
                }
            });
        }

        if (Element.isElement(node) && node.type === 'code-line') {
            const parent = Node.parent(editor, path);
            if (parent.type !== 'code-block') {
                Transforms.setNodes(editor, {
                    type: 'paragraph',
                    children: node.children
                },
        {at: path})
            }
        }

        if (Text.isText(node) &&
            Node.parent(editor, path).type === 'code-line' &&
            Device.isIOS)
        {
            const charCodedText = IOSCharCodeMapper(node.text || '');

            if (charCodedText !== node.text) {
                Transforms.insertText(editor, charCodedText,
                    {at: path})
            }
        }

        normalizeNode(entry);
    };

    return editor;
};

export default {
 withHighlightedCodeBlockPlugin
};