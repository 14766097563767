import { fetchWrapper } from "../api-authorization/helpers";
import { cloneDeep } from 'lodash';
import FileDto from "../models/FileDto";

export const postFileDto = async (fileDto: FileDto, onSuccessToEditCallback?: (fileDto: FileDto) => void, onErrorCallback?: (error: Response) => void) => {
    const postToPublish = cloneDeep(fileDto);

    await fetchWrapper.postMultiform('api/file', postToPublish).then(onSuccessToEditCallback,onErrorCallback);
};

export const getFileDtoByHashId = async (hashId: string, onSuccessCallback: (fileDto: FileDto) => void, onErrorCallback: (error: Response) => void) => {
    await fetchWrapper.get('api/file/'+hashId).then(onSuccessCallback,onErrorCallback);
}

export const getFileDtosFileByHashId = async (hashId: string, onSuccessCallback: (response: any) => void, onErrorCallback: (error: Response) => void) => {
    await fetchWrapper.getFile('api/file/'+hashId+'/file').then(onSuccessCallback,onErrorCallback);
}