import React from "react";
import styled from "@emotion/styled";

const StyledCodeBlock = styled.code`
  font-size: 14px;
  font-family: Monaco;
`;

const CodeLeafRenderer = ({attributes, children, leaf}, content) => {
    if (leaf.code) {
        return (
            <StyledCodeBlock>
                {content}
            </StyledCodeBlock>);
    }
    else {
        return content;
    }
}

export default CodeLeafRenderer;