import {Node, Transforms} from "slate";

const defaultParagraphBlock = () => {
    return {
        type: "setlist-laugh",
        children: [
            {
                text: "",
            }
        ],
        key: Math.random()
    };
};

const insertParagraphBlock = (editor, event) => {
    event.preventDefault();

    Transforms.insertNodes(editor, defaultParagraphBlock());
}

const resetToParagraphBlock = (editor, event, slateComponents) => {
    if (editor.selection.anchor.offset !== 0
        || !editor.selection.anchor.path.every((node) => node === 0)
    ) {
        return;
    }

    const elementPath = editor.selection.anchor.path.slice(0,-1);
    const selectedElement = Node.get(editor, elementPath);

    const componentThatMayBeReset = slateComponents.find((slateComponent) => slateComponent.type === selectedElement.type);

    if (componentThatMayBeReset) {
        componentThatMayBeReset.resetToDefault(editor, elementPath, defaultParagraphBlock());
    }
}

export default {
    insertParagraphBlock,
    defaultParagraphBlock,
    resetToParagraphBlock
};