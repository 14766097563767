import React from "react";
import {Box} from "@mui/material";
import PostSearch from "../../../../rob-horse/reusable-components/Websocket-Components/PostSearch";
import PerformanceWriter from "../../slate-js/PerformanceWriter";
import PerformanceSlateComponents from "../../slate-js/PerformanceSlateComponents";

interface IDefinePerformanceProps {
  addPost: (hashId: string, contentTypeSlug: string) => void,
  clearPost: () => void,
  content: any,
  editor: any,
  onContentChange: any
}

const DefinePerformance = (props: IDefinePerformanceProps) => {
  const {
    addPost,
    clearPost,
    content,
    editor,
    onContentChange
  } = props;

  return (
    <Box>
      <PostSearch
        addPost={addPost}
        searchLabel={"Search for existing setlists"}
        contentTypeSlugs={["setlist"]}
        sx={{
          mb: 2
        }}
        selectable={{
          clearPost
        }}
      />
      <PerformanceWriter
        content={content}
        editor={editor}
        onContentChange={onContentChange}
        hideToolbar
        slateComponents={PerformanceSlateComponents}
      />
    </Box>
  )
}

export default DefinePerformance