import React from 'react';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Dropzone, {DropzoneProps} from 'react-dropzone';
import {SxProps} from "@mui/system";

interface IMaterialDropzoneProps extends DropzoneProps {
    onDropCallback: (value: React.SetStateAction<any>) => void,
    placeholder?: string,
    sx?: SxProps
}

const MaterialDropzone = (props: IMaterialDropzoneProps) => {
  const {
    sx
  } = props;

  const onDrop = (acceptedFiles: File[]) => {
    props.onDropCallback(acceptedFiles);
  };

  const placeHolderText = props.placeholder || "Drag 'n' drop some files here, or click to select files";

  return (
    <Dropzone
      {...props}
      onDrop={onDrop}
    >
      {({getRootProps, getInputProps}) => (
        <Paper
          {...getRootProps()}
          sx={{
            minHeight: '300px',
            padding: 3,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            borderStyle: 'dashed',
            borderColor: 'divider',
            boxShadow: 0,
            ...sx
          }}
        >
          <input {...getInputProps()} />
          <Typography>{placeHolderText}</Typography>
        </Paper>
      )}
    </Dropzone>
  );
};

export default MaterialDropzone;
