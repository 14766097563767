import React from 'react';
import contentTypeMapper from "./contentTypeMapper";

export const AddPost = (props: any) => {
  const contentTypeSlug = props.match.params.contentTypeSlug;

  if (contentTypeMapper[contentTypeSlug]) {
    const AddPage = contentTypeMapper[contentTypeSlug].add;

    return <AddPage contentTypeSlug={contentTypeSlug} />
  }

  return (
    <p>Sorry, this content type does not exist</p>
  );
};
