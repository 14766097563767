class TermGroupingDto {
    name: string = '';
    slug: string = '';
    color: string = '';
    hashId?: string = undefined;

    constructor(termDto?: Partial<TermGroupingDto>) {
      Object.assign(this, termDto);
    }
}

export default TermGroupingDto;
