import React, {useState, useEffect} from 'react';
import {getContentTypeByPostId} from '../includes/api-functions/PostDtoCalls';
import {ContentTypeDto} from '../includes/models/ContentTypeDto';
import contentTypeMapper from "./contentTypeMapper";
import CenteredSpinner from "./reusable-components/CenteredSpinner/CenteredSpinner";
import {Typography} from "@mui/material";

export const EditPost = (props: any) => {
  const hashId = props.match.params.hashId;

  const [contentType, setContentType] = useState<ContentTypeDto | undefined>();

  const handleError = (e: Response) => {
    console.log(e.status);
  };

  useEffect(() => {
    getContentTypeByPostId(hashId, setContentType, handleError);
  }, []);

  if (!contentType) {
    return (
      <CenteredSpinner />
    );
  }

  if (contentTypeMapper[contentType.slug]) {
    const EditPage = contentTypeMapper[contentType.slug].edit;

    // @ts-ignore
    return <EditPage hashId={hashId} />
  }

  return <Typography>Post Type Not Implemented or Available</Typography>
};
