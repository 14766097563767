import React, {useContext} from 'react';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import {useHistory} from 'react-router-dom';
import {accountService} from '../includes/api-authorization/services';
import {Avatar, Box, ButtonGroup, Divider, ListItemIcon, Menu, Tooltip, Typography} from '@mui/material';
import IconButton from '@mui/material/IconButton';
import {Logout, PersonAdd, Settings} from '@mui/icons-material';
import LightModeIcon from '@mui/icons-material/LightMode';
import SettingsBrightnessIcon from '@mui/icons-material/SettingsBrightness';
import DarkModeIcon from '@mui/icons-material/DarkMode';
import {ColorModeContext} from './App';

export default function AccountMenuPopup() {
  const history = useHistory();

  const colorModeContext = useContext(ColorModeContext);

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = (event: any) => {
    accountService.logout();
    history.push('/Login');
  };

  const onColorModeClicked = (event: any) => {
    colorModeContext.setColorMode(event.currentTarget.value);
  };

  const goToUserPage = (event: any) => {
    history.push(`/@${accountService.userValue.username}`);
  };

  return (
    <Box sx={{display: 'flex'}}>
      <Tooltip title="Account settings">
        <IconButton onClick={handleClick} size="small" sx={{ml: 2}}>
          <Avatar sx={{width: 32, height: 32}}>{accountService.userValue.username[0].toUpperCase()}</Avatar>
        </IconButton>
      </Tooltip>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            'overflow': 'visible',
            'filter': 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            'mt': 1.5,
            '& .MuiAvatar-root': {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            '&:before': {
              content: '""',
              display: 'block',
              position: 'absolute',
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: 'background.paper',
              transform: 'translateY(-50%) rotate(45deg)',
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{horizontal: 'right', vertical: 'top'}}
        anchorOrigin={{horizontal: 'right', vertical: 'bottom'}}
      >
        <MenuItem onClick={goToUserPage}>
          <Avatar /> {accountService.userValue.username}
        </MenuItem>
        <Divider />
        <Box
          sx={{
            padding: 2,
          }}
        >
          <Typography
            display="block"
            sx={{
              marginBottom: 2,
            }}
          >
                        Mode
          </Typography>
          <ButtonGroup
            variant="outlined"
          >
            <Button
              onClick={onColorModeClicked}
              variant={colorModeContext.colorMode === 'light' ? 'contained' : 'outlined'}
              value="light"
            >
              <LightModeIcon />
                            &nbsp;
                            Light
            </Button>
            <Button
              onClick={onColorModeClicked}
              variant={colorModeContext.colorMode === 'system' ? 'contained' : 'outlined'}
              value="system"
            >
              <SettingsBrightnessIcon />
                            &nbsp;
                            System
            </Button>
            <Button
              onClick={onColorModeClicked}
              variant={colorModeContext.colorMode === 'dark' ? 'contained' : 'outlined'}
              value="dark"
            >
              <DarkModeIcon />
                            &nbsp;
                            Dark
            </Button>
          </ButtonGroup>
        </Box>
        <MenuItem>
          <ListItemIcon>
            <Settings fontSize="small" />
          </ListItemIcon>
            Settings
        </MenuItem>
        <MenuItem onClick={handleLogout}>
          <ListItemIcon>
            <Logout fontSize="small" />
          </ListItemIcon>
                    Logout
        </MenuItem>
      </Menu>
    </Box>
  );
}
