import SlateDecoratedTextTypePlugins from "./SlateDecoratedTextTypePlugins";

const SlateDecoratedTextTypeComponent = {
    author: 'Rob Reinhard',
    block: undefined,
    decorators: undefined,
    default: undefined,
    dependencyTypes: undefined,
    leafRenderers: undefined,
    name: 'Decorated Text Type Component',
    onDrop: false,
    onKeyDown: undefined,
    plugins: [SlateDecoratedTextTypePlugins.withDecoratedTextType],
    resetToDefault: undefined,
    toolbar: undefined,
    transforms: undefined,
    type: undefined
};

export default SlateDecoratedTextTypeComponent;