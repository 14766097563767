import React, {useCallback, useEffect, useState, ChangeEvent, SyntheticEvent} from 'react';
import {ColDef, GridApi} from 'ag-grid-community';
import {deletePostsByHashIds, getPostDtos} from '../../../includes/api-functions/PostDtoCalls';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  InputAdornment,
  InputLabel,
  Paper, SelectChangeEvent,
  TextField
} from '@mui/material';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Button from '@mui/material/Button';
import Search from '@mui/icons-material/Search';
import {useHistory} from 'react-router-dom';
import PostDto from '../../../includes/models/PostDto';
import PaginatedMaterialTable from "./PaginatedMaterialTable";
import SearchPostParameters from "../../../includes/data-transfer-objects/SearchPostParameters";
import CenteredSpinner from "../CenteredSpinner/CenteredSpinner";
import {debounce} from "lodash";
import PostRow from "./PostRow";
import {GridReadyEvent} from "ag-grid-community/dist/lib/events";

enum PostBulkActions {
    None,
    Delete
}

const defColDefs: ColDef = {
  flex: 1,
  wrapText: true,
  autoHeight: true,
  suppressMovable: true,
};

const options = {
  rowSelection: 'multiple',
  suppressCellSelection: true,
  suppressRowClickSelection: true,
};

const frameworkComponents = {
  postRenderer: PostRow,
};

export interface PageSearchProps {
  contentTypeSlugs?: string[];
  termSlugs?: string[];
}

interface PostMaterialTableProps {
    addNew?: boolean;
    pageTitle: String,
    children?: React.ReactNode;
    pageSearchProps?: PageSearchProps | null;
}

export const PostMaterialTable = (props: PostMaterialTableProps) => {
  const [userPosts, setUserPosts] = useState<PostDto[]>([]);
  const [recordsCount, setRecordsCount] = useState<number>(0);
  const [bulkChangeSelected, setBulkChangeSelected] = useState<PostBulkActions>(PostBulkActions.None);
  const [gridApi, setGridApi] = useState<GridApi | undefined>();
  const [searchPostParameters, setSearchPostParameters] = useState<SearchPostParameters>();
  const history = useHistory();

  const {
    addNew,
    children,
    pageTitle,
    pageSearchProps
  } = props;

  useEffect(() => {
    setRecordsCount(0);

    if (pageSearchProps === null) {
      return;
    }

    setSearchPostParameters(() => ({
      ...pageSearchProps,
      paginationParameters: {
        pageNumber: 1,
        pageSize: 10
      },
      searchString: "",
      dependent: false
    }))
  }, [pageSearchProps]);

  const onSuccessSetData = (parsedResponse: any) => {
    setUserPosts(parsedResponse.parsedBody);

    setRecordsCount(Number.parseInt(parsedResponse.headers.get('RecordsCount')));
  }

  useEffect(() => {
    if (searchPostParameters === undefined) {
      return;
    }

    gridApi?.showLoadingOverlay();
    getPostDtos(searchPostParameters as SearchPostParameters, onSuccessSetData, handleError).then(async () => {
      gridApi?.hideOverlay();
    });
  }, [searchPostParameters]);

  const setColumns: ColDef[] = [
    {
      cellRenderer: 'postRenderer',
      field: 'name',
      minWidth: 275,
      checkboxSelection: true,
      sortable: false,
    },
    {
      field: 'dependent',
      filter: true,
      hide: true,
    },
  ];


  useEffect(() => {
    const columnDefinitionsWithTaxonomies:ColDef[] = [];

    setColumns.forEach((setColumn) => {
      columnDefinitionsWithTaxonomies.push(setColumn);
    });

    gridApi?.setColumnDefs(columnDefinitionsWithTaxonomies);
  }, [setColumns, gridApi]);

  useEffect(() => {
    if (gridApi) {
      const gridListen = () => {
        resizeListener(gridApi);
      };
      window.addEventListener('resize', gridListen);

      return () => {
        window.removeEventListener('resize', gridListen);
      };
    }
  }, [gridApi]);

  const onDependentChanged = (event: SyntheticEvent, checked: boolean) => {
    if (checked === searchPostParameters?.dependent) {
      return;
    }

    setSearchPostParameters(prevState => {
      if (prevState !== undefined) {
        return ({
          ...prevState,
          dependent: checked,
          paginationParameters: {
            pageSize: prevState?.paginationParameters.pageSize,
            pageNumber: 1
          }
        })
      }

      return prevState;
    });
  };

  const onSearchFieldChanged = (searchString: string) => {
    if (searchString === searchPostParameters?.searchString) {
      return;
    }

    setSearchPostParameters(prevState => {
      if (prevState !== undefined) {
        return ({
          ...prevState,
          searchString: searchString,
          paginationParameters: {
            pageSize: prevState?.paginationParameters.pageSize,
            pageNumber: 1
          }
        })
      }

      return prevState;
    });
  };

  const onSearchDebounced = useCallback(debounce(onSearchFieldChanged, 1000), []);

  const onSearchTextFieldChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
    onSearchDebounced(event.target.value);
  }

  const onBulkChangeSelected = (event: SelectChangeEvent<unknown>) => {
    setBulkChangeSelected(event.target.value as PostBulkActions);
  };

  const resizeListener = (api: GridApi) => {
    api.sizeColumnsToFit();
  };

  const onGridReadyCallback = (params: GridReadyEvent) => {
    setGridApi(params.api);
  };

  const handleError = (e: Response) => {
    console.log(e.status);
  };

  const clickPostColumn = (event: any) => {
    if (setColumns.filter((column:ColDef) => event.column.colId === column.field).length > 0) {
      pushToViewPage(event);
    }
  };

  const pushToViewPage = useCallback((event:any) =>
    history.push('/view/' + event.data.hashId),
  [history],
  );

  const pushToAddPage = useCallback(() =>
    history.push('/add'),
    [history],
  );

  const onBulkChangeApplyClicked = () => {
    if (gridApi) {
      const selectedRowHashIds = gridApi.getSelectedRows().map((row) => row.hashId);
      if (bulkChangeSelected === PostBulkActions.Delete) {
        deletePostsByHashIds(selectedRowHashIds, handleError).then(() => {
          setTimeout(() => {
            getPostDtos(searchPostParameters as SearchPostParameters, setUserPosts, handleError);
          }, 500);
        });
      }
    }
  };

  const onPageSizeChanged = (event: ChangeEvent<HTMLInputElement>) => {
    setSearchPostParameters(prevState => {
      if (prevState !== undefined) {
        return ({
          ...prevState,
          paginationParameters: {
            pageSize: Number.parseInt(event.target.value),
            pageNumber: 1
          }
        })
      }

      return prevState;
    });
  }

  const onFirstButtonClicked = () => {
    setSearchPostParameters(prevState => {
      if (prevState !== undefined) {
        return ({
          ...prevState,
          paginationParameters: {
            pageSize: prevState?.paginationParameters.pageSize,
            pageNumber: 1
          }
        })
      }

      return prevState;
    });
  };

  const onBackButtonClicked = () => {
    if (searchPostParameters?.paginationParameters.pageNumber === 1) {
      return;
    }

    setSearchPostParameters(prevState => {
      if (prevState !== undefined) {
        return ({
          ...prevState,
          paginationParameters: {
            pageSize: prevState?.paginationParameters.pageSize,
            pageNumber:  prevState?.paginationParameters.pageNumber - 1
          }
        })
      }

      return prevState;
    });
  };

  const onNextButtonClicked = () => {
    if (searchPostParameters === undefined || searchPostParameters?.paginationParameters.pageNumber === Math.ceil(recordsCount/searchPostParameters?.paginationParameters.pageSize)) {
      return;
    }

    setSearchPostParameters(prevState => {
      if (prevState !== undefined) {
        return ({
          ...prevState,
          paginationParameters: {
            pageSize: prevState?.paginationParameters.pageSize,
            pageNumber:  prevState?.paginationParameters.pageNumber + 1
          }
        })
      }

      return prevState;
    });
  }

  const onLastButtonClicked = () => {
    setSearchPostParameters(prevState => {
      if (prevState !== undefined) {
        return ({
          ...prevState,
          paginationParameters: {
            pageSize: prevState?.paginationParameters.pageSize,
            pageNumber: Math.ceil(recordsCount/prevState?.paginationParameters.pageSize)
          }
        })
      }

      return prevState;
    });
  }

  if (searchPostParameters?.paginationParameters === undefined) {
    return (
      <CenteredSpinner />
    )
  }

  return (
    <Grid container spacing={3}>
      <Grid container item xs={12} md={12} lg={9}>
        <Grid container item xs={12} alignItems="center">
          <Typography variant="h4" component="h1">{pageTitle}</Typography>
          {addNew &&
            <Button
                variant="contained"
                color="primary"
                onClick={pushToAddPage}
                sx={{
                  ml: 2
                }}
            >
                Add New
            </Button>
          }
        </Grid>
        <Grid
          container
          item xs={12}
          alignItems="center"
          sx={{
            marginTop: 1,
            marginBottom: 1,
          }}
        >
          <Grid item>
            <FormControl
              sx={{
                width: '150px',
                marginRight: 2,
                marginBottom: 2,
              }}
            >
              <InputLabel id="bulk-page-actions-label">Bulk actions</InputLabel>
              <Select
                variant="standard"
                labelId="bulk-page-actions-searchLabel"
                id="bulk-page-actions"
                defaultValue=""
                onChange={onBulkChangeSelected}
              >
                <MenuItem value={PostBulkActions.Delete}>Delete</MenuItem>
              </Select>
            </FormControl>
            <Button
              variant="contained"
              color="secondary"
              sx={{
                verticalAlign: 'bottom',
                marginRight: 2,
                marginBottom: 2,
              }}
              onClick={onBulkChangeApplyClicked}
            >
                            Apply
            </Button>
          </Grid>
          <FormControlLabel
            control={<Checkbox />}
            label="Dependent?"
            onChange={onDependentChanged}
            value={searchPostParameters.dependent}
          />
          <Grid
            item
            sx={{
              flexGrow: 1
            }}
          >
            <TextField
              variant="standard"
              label="Search"
              sx={{
                width: '100%',
                marginBottom: 2,
              }}
              InputProps={{
                endAdornment: <InputAdornment position="end"><Search/></InputAdornment>,
              }}
              onChange={onSearchTextFieldChanged}
            />
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Paper style={{width: 'auto'}}>
              <PaginatedMaterialTable
                animateRows
                columnDefs={setColumns}
                defaultColDef={defColDefs}
                frameworkComponents={frameworkComponents}
                onFirstButtonClicked={onFirstButtonClicked}
                onBackButtonClicked={onBackButtonClicked}
                onNextButtonClicked={onNextButtonClicked}
                onLastButtonClicked={onLastButtonClicked}
                onPageSizeChanged={onPageSizeChanged}
                paginationParameters={searchPostParameters?.paginationParameters}
                recordsCount={recordsCount}
                rowData={userPosts}
                gridOptions={options}
                onCellClicked={clickPostColumn}
                domLayout='autoHeight'
                onGridReady={onGridReadyCallback}
              />
            </Paper>
          </Grid>
        </Grid>
      </Grid>
      {children &&
                <Grid item xs={12} md={12} lg={3}>
                  {children}
                </Grid>
      }
    </Grid>
  );
};
