import React, {useState} from 'react';
import TermGroupingDto from "../includes/models/TermGroupingDto";
import {postTermGroupingDto} from "../includes/api-functions/TermGroupingDtoCalls";
import TermGroupingMaterialTable from "./reusable-components/MaterialTable-Components/TermGroupingMaterialTable";
import AddEditTermGrouping from "./reusable-components/Term-Grouping-Components/AddEditTermGrouping";
import CenteredSpinner from "./reusable-components/CenteredSpinner/CenteredSpinner";

const TermGroupings = () => {
  const [termGroupingDto, setTermGroupingDto] = useState<TermGroupingDto | undefined>(new TermGroupingDto());
  const [refreshTerms, setRefreshTerms] = useState<boolean>(true);

  if (termGroupingDto === undefined) {
    return <CenteredSpinner />
  }

  const handleError = (e: Response) => {
    console.log(e.status);
  };

  const onCreateTermGrouping = async () => {
    setRefreshTerms(false);

    if (termGroupingDto === undefined) {
      return;
    }

    await postTermGroupingDto(termGroupingDto, setTermGroupingDto, handleError).then(async () => {
      setRefreshTerms(true);
      setTermGroupingDto(new TermGroupingDto())
    });
  };

  return (
        <TermGroupingMaterialTable
          pageTitle="Groupings"
          refreshTerms={refreshTerms}
        >
          <AddEditTermGrouping
            label="Add Grouping"
            termGroupingDto={termGroupingDto}
            setTermGroupingDto={setTermGroupingDto}
            onButtonClicked={onCreateTermGrouping}
          />
        </TermGroupingMaterialTable>
  );
}

export default TermGroupings;
