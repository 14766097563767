import {Transforms, Text} from 'slate';

const withTextType = (editor) => {
    const { normalizeNode } = editor;

    editor.normalizeNode = (entry) => {
        const [node, path] = entry;

        if (Text.isText(node) && node.type === undefined)
        {
            Transforms.setNodes(
                editor,
                {
                    type: "text",
                    ...node
                },
                {at: path})
        }

        normalizeNode(entry);
    };

    return editor;
};

export default {
    withTextType
};