import React, {useEffect, useState} from 'react';
import {Box} from '@mui/material';
import DownloadButton from './DownloadButton';
import {SxProps} from "@mui/system";

interface IImageViewerProps {
    file: File,
    hideDownloadButton?: boolean,
    sx?: SxProps,
    imageSx?: SxProps
}

const ImageViewer = (props: IImageViewerProps) => {
  const {
    file,
    hideDownloadButton,
    imageSx,
    sx
  } = props;

  const [base64encoding, setBase64encoding] = useState<string>('');

  useEffect(() => {
    const reader = new FileReader();

    reader.addEventListener('load', async () => {
      if (typeof reader.result === 'string') {
        setBase64encoding(reader.result);
      }
    }, false);

    reader.readAsDataURL(file);
  }, [file]);

  return (
    <Box
      sx={sx}
    >
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Box
          component="img"
          src={base64encoding}
          sx={{
            maxHeight: '100%',
            maxWidth: '100%',
            ...imageSx
          }}
        />
      </Box>
      {!hideDownloadButton &&
        <DownloadButton
          name={file.name}
          href={base64encoding}
        />
      }
    </Box>
  );
};

export default ImageViewer;
