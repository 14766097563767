import React, {useState, useEffect, MouseEvent, useMemo} from 'react';
import {Transforms} from 'slate';
import JokeDto, {JokeDtoUtils} from '../../includes/models/JokeDto';
import EditPostBase from '../../../rob-horse/reusable-components/Add-Edit-View-Default-Bases/EditPostBase';
import SlateRichTextEditor from '../../../rob-horse/reusable-components/Slate-Editors/SlateRichTextEditor';
import SingleJokeComponents from '../slate-js/SingleJokeComponents';
import {getJokeDtoByHashId, getJokeRevisionDtosByHashId, postJokeDto} from '../../includes/api-functions/JokeDtoCalls';
import {Alert} from '@mui/material';
import {TermDtoUtils} from "../../../includes/models/TermDto";
import useSlateEditor from "../../../rob-horse/reusable-components/Slate-Editors/useSlateEditorState";
import {onSuccessfulGetJoke, onSuccessfulGetJokeRevisions} from "./jokeHooks";

interface IEditPostProps {
    hashId: string;
}

export function EditJoke(props: IEditPostProps) {
  const {hashId} = props;
  const [jokeDto, setJokeDto] = useState<JokeDto>(new JokeDto());
  const [jokeDtoRevisions, setJokeDtoRevisions] = useState<JokeDto[]>([]);
  const [revisionIndex, setRevisionIndex] = useState<number>(0);
  const [allowUpdate, setAllowUpdate] = useState<boolean>(false);

  const [editor] = useSlateEditor(SingleJokeComponents);

  useEffect(() => {
    getJokeDtoByHashId(hashId, onSuccessfulGetPostHashIdCallback, handleError).then(async () => {
      await getJokeRevisionDtosByHashId(hashId, onSuccessfulGetPostRevisionsCallback, handleError);
      setAllowUpdate(true);
    });
  }, []);

  const onSuccessfulGetPostHashIdCallback = onSuccessfulGetJoke(hashId, setJokeDto);

  const onSuccessfulGetPostRevisionsCallback = onSuccessfulGetJokeRevisions(setJokeDtoRevisions, setRevisionIndex);

  const onContentChange = (content: any) => {
    setJokeDto((prevState) => ({
      ...prevState,
      content: content.children,
    }));
  };

  const handleError = (e: Response) => {
    console.log(e.status);
  };

  const handleChange = (e:any) => {
    const {name, value} = e.target;
    setJokeDto((prevState) => ({
      ...prevState,
      [name]: value,
    }));
    console.log(jokeDto);
  };

  const onSuccessfulPostCallback = () => {
    getJokeDtoByHashId(hashId, onSuccessfulGetPostHashIdCallback, handleError).then(async () => {
      await getJokeRevisionDtosByHashId(hashId, onSuccessfulGetPostRevisionsCallback, handleError);
      setAllowUpdate(true);
    });
  };

  const updateJokeButtonClicked = async () => {
    setAllowUpdate(false);
    await postJokeDto(jokeDto, onSuccessfulPostCallback, handleError);
  };

  const onPreviousClicked = (event: MouseEvent) => {
    if (revisionIndex !== 0) {
      const index = revisionIndex - 1;
      setRevisionIndex(index);
      Transforms.deselect(editor);
      onContentChange(jokeDtoRevisions[index].slateContent);
    }
  };

  const onNextClicked = (event: MouseEvent) => {
    if (revisionIndex !== jokeDtoRevisions.length-1) {
      const index = revisionIndex + 1;
      setRevisionIndex(index);
      Transforms.deselect(editor);
      onContentChange(jokeDtoRevisions[index].slateContent);
    }
  };

  const disableUpdate =
        allowUpdate &&
        jokeDtoRevisions.length > 0 &&
        JokeDtoUtils.equals(jokeDto, jokeDtoRevisions[jokeDtoRevisions.length-1]) &&
        TermDtoUtils.equalArrays(jokeDto.termDtos, jokeDtoRevisions[0].termDtos);

  return (
    <EditPostBase
      allowUpdate={!disableUpdate}
      contentTypeName="Joke"
      handleChange={handleChange}
      hashId={hashId}
      revisionData={{
        onPreviousClicked: onPreviousClicked,
        onNextClicked: onNextClicked,
        revisionIndex: revisionIndex,
        postDtoRevisions: jokeDtoRevisions,
      }}
      postDto={jokeDto}
      setPostDto={setJokeDto}
      updatePostButtonClicked={updateJokeButtonClicked}
    >
      {jokeDto.dependent &&
                <Alert
                  severity="warning"
                  sx={{
                    marginBottom: 2,
                  }}>
                    This joke is part of a larger bit.
                </Alert>
      }
      <SlateRichTextEditor
        content={jokeDto.slateContent}
        editor={editor}
        onContentChange={onContentChange}
        readOnly={jokeDto.dependent}
        slateComponents={SingleJokeComponents}
      />
    </EditPostBase>
  );
}
