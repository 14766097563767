import {Element, Transforms, Node, Text} from 'slate';
import SlateParagraphBlockComponent from "../../SlateParagraphBlockComponent/SlateParagraphBlockComponent";
import SlateBlockQuoteLineComponent from "../SlateBlockQuoteLineComponent";
import BlockQuoteTransforms from "./BlockQuoteTransforms";
import SlateBlockQuoteComponent from "../SlateBlockQuoteComponent";

const withBlockQuotePlugin = (editor) => {
    const { normalizeNode } = editor;

    editor.normalizeNode = (entry) => {
        const [node, path] = entry;

        if (Element.isElement(node) && node.type === SlateBlockQuoteComponent.type) {
            node.children.forEach((child, index) => {
                const newNodePath = path.concat(index);
                if (child.type !== SlateBlockQuoteLineComponent.type) {
                    Transforms.removeNodes(editor, {at: newNodePath});
                    Transforms.insertNodes(editor,
                        BlockQuoteTransforms.defaultBlockQuoteLine(),
                        {at: newNodePath});
                    Transforms.select(editor, newNodePath);
                }
            });
        }

        if (Element.isElement(node) && node.type === SlateBlockQuoteLineComponent.type) {
            const parent = Node.parent(editor, path);
            if (parent.type !== SlateBlockQuoteComponent.type) {
                Transforms.setNodes(editor, {
                    type: SlateParagraphBlockComponent.type,
                    children: node.children
                },
                {at: path});
            }
        }

        normalizeNode(entry);
    };

    return editor;
};

export default {
    withBlockQuotePlugin
};