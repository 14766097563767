import { accountService } from '../services';
import * as Sentry from '@sentry/react';

const get = (url) => {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };
    return fetch(url, requestOptions).then(handleResponse);
};

const getWithHeaders = (url) => {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };
    return fetch(url, requestOptions).then(handleHeadersResponse);
}

const getFile = (url) => {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };
    return fetch(url, requestOptions).then(handleFileResponse);
}

const post = (url, body, bodyProcessor) => {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json', ...authHeader() },
        credentials: 'include',
        body: bodyProcessor ? bodyProcessor(body) : JSON.stringify(body)
    };
    return fetch(url, requestOptions).then(handleResponse);
};

const createMultipartFormData = (obj, rootName, ignoreList) =>  {
    const formData = new FormData();

    const appendFormData = (data, root) => {
        if (!ignore(root)) {
            root = root || '';
            if (data instanceof File) {
                formData.append(root, data);
            } else if (Array.isArray(data)) {
                for (var i = 0; i < data.length; i++) {
                    appendFormData(data[i], root + '[' + i + ']');
                }
            } else if (typeof data === 'object' && data) {
                for (var key in data) {
                    if (data.hasOwnProperty(key)) {
                        if (root === '') {
                            appendFormData(data[key], key);
                        } else {
                            appendFormData(data[key], root + '.' + key);
                        }
                    }
                }
            } else {
                if (data !== null && typeof data !== 'undefined') {
                    formData.append(root, data);
                }
            }
        }
    }

    const ignore = (root) => {
        return Array.isArray(ignoreList)
            && ignoreList.some(function(x) { return x === root; });
    }

    appendFormData(obj, rootName);

    return formData;
}

const postMultiform = (url, body) => {
    const requestOptions = {
        method: 'POST',
        headers: { ...authHeader() },
        credentials: 'include',
        body: createMultipartFormData(body)
    };
    return fetch(url, requestOptions).then(handleResponse);
};

const put = (url, body) => {
    const requestOptions = {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json', ...authHeader() },
        body: JSON.stringify(body)
    };
    return fetch(url, requestOptions).then(handleResponse);    
};

// prefixed with underscored because delete is a reserved word in javascript
const _delete = (url) => {
    const requestOptions = {
        method: 'DELETE',
        headers: authHeader()
    };
    return fetch(url, requestOptions).then(handleResponse);
};

const bulkDelete = (url, body) => {
    const requestOptions = {
        method: 'DELETE',
        headers: { 'Content-Type': 'application/json', ...authHeader() },
        body: JSON.stringify(body)
    };
    return fetch(url, requestOptions).then(handleResponse);
};

const authHeader = () => {
    // return auth header with jwt if user is logged in and request is to the api url
    const user = accountService.userValue;
    const isLoggedIn = user && user.jwtToken;
    if (isLoggedIn) {
        return { Authorization: `Bearer ${user.jwtToken}` };
    } else {
        return {};
    }
}

export const authHeaderNoUrl = () => {
    // return auth header with jwt if user is logged in and request is to the api url
    const user = accountService.userValue;
    console.log(user.jwtToken);
    const isLoggedIn = user && user.jwtToken;
    if (isLoggedIn) {
        return user.jwtToken;
    } else {
        return null;
    }
}

const handleResponse = (response) => {
    return response.text().then(text => {
        const data = text && JSON.parse(text);
        
        if (!response.ok) {
            if ([401, 403].includes(response.status) && accountService.userValue) {
                // auto logout if 401 Unauthorized or 403 Forbidden response returned from api
                accountService.logout();
            }

            const error = (data && data.message) || response.statusText;

            Sentry.captureException(error);

            return Promise.reject(error);
        }

        return data;
    });
}

const handleHeadersResponse = (response) => {
    return response.text().then(text => {
        const data = text && JSON.parse(text);

        if (!response.ok) {
            if ([401, 403].includes(response.status) && accountService.userValue) {
                // auto logout if 401 Unauthorized or 403 Forbidden response returned from api
                accountService.logout();
            }

            const error = (data && data.message) || response.statusText;

            Sentry.captureException(error);

            return Promise.reject(error);
        }

        return ({
            parsedBody: data,
            headers: response.headers
        });
    });
}

const handleFileResponse = (response) => {
    return response.blob().then((blob) => {
        console.log(blob);

        const theBlob = new Blob([blob], {type: blob.type});

        const data = new File([theBlob], "file", {
            lastModified: 1534584790000,
            type: theBlob.type
        });

        if (!response.ok) {
            if ([401, 403].includes(response.status) && accountService.userValue) {
                // auto logout if 401 Unauthorized or 403 Forbidden response returned from api
                accountService.logout();
            }

            const error = (data && data.message) || response.statusText;

            Sentry.captureException(error);

            return Promise.reject(error);
        }

        return data;
    })
}

export const fetchWrapper = {
    get,
    getWithHeaders,
    getFile,
    post,
    postMultiform,
    put,
    delete: _delete,
    bulkDelete
}