import React from 'react'
import {Box, css, MenuItem, Select} from "@mui/material";
import './prism.css';

import {Transforms} from "slate";
import {useSlate, useReadOnly} from "slate-react";
import styled from "@emotion/styled";

const PreBlock = styled.pre`
    background: #272822;
    border-radius: 0.3em;
    padding: 1em;
    margin: .5em 0;
    overflow-x: scroll !important;
    -webkit-overflow-scrolling: touch;
    counter-reset: syntaxNumberHighlighting;
    code p {
        counter-increment: syntaxNumberHighlighting;
        &::before {
          color: #777;
          padding-right: 10px;
          content: counter(syntaxNumberHighlighting);
        };
        & span[data-slate-placeholder="true"] {
          display: inline-block !important;
        };
        font-family: Monaco;
        font-size: 14px;
    },
`;

const StyledBox = styled(Box)`
  position: relative;
  width: calc(100% - 48px);
  height: auto;
`

const languages = [
    {
        name: "ASP.NET C#",
        className: "aspnet"
    },
    {
        name: "Javascript",
        className: "js"
    },
    {
        name: "SQL",
        className: "sql"
    },
    {
        name: "Bash",
        className: "bash"
    },
    {
        name: "JSX",
        className: "jsx"
    },
    {
        name: "TSX",
        className: "tsx"
    },
    {
        name: "Typescript",
        className: "typescript"
    },
    {
        name: "PHP",
        className: "php"
    },
    {
        name: "Docker",
        className: "docker"
    },
    {
        name: "GraphQL",
        className: "graphql"
    },
    {
        name: "JSON",
        className: "json"
    },
    {
        name: "HTML",
        className: "html"
    },
    {
        name: "CSS",
        className: "css"
    }
];

const CodeBlock = (props) => {
    const editor = useSlate();

    const {
        element: {
            language,
            key
        }
    } = props;

    const onLanguageChange = async (event) => {
        event.preventDefault();
        Transforms.setNodes(
            editor,
            {
                type: 'code-block',
                language: event.target.value,
                key: key
            },
            {
                at: [],
                match: (node, path) => {
                    return node.key && node.key === key;
                }
            }
        )
    };

    return (
        <Box
            {...props.attributes}
            sx={{
                position: "relative",
                width: {
                    xs: 'calc(100vw - 48px)',
                    sm: '100%',
                },
                height: "auto",
                overflow: "scroll"
            }}>
            <div contentEditable={false}>
                <Select
                    value={language}
                    variant="standard"
                    onChange={onLanguageChange}
                    sx={{
                        position: 'relative'
                    }}
                    readOnly={useReadOnly()}
                >
                    {languages.map((language) =>
                        <MenuItem value={language.className} key={language.className}>{language.name}</MenuItem>
                    )}
                </Select>
            </div>
            <PreBlock>
                <code className={"language-"+language}>
                    {props.children}
                </code>
            </PreBlock>
        </Box>
    )
}

export default CodeBlock;