import {TermDtoUtils} from './TermDto';
import PostDto from './PostDto';

export default class FileDto extends PostDto {
    file: File | null = null;
    contentTypeSlug: string = "file";

    constructor(fileDto?: Partial<FileDto>) {
      super();
      Object.assign(this, fileDto);
    };
}

export class FileDtoUtils {
  static equals(x: FileDto, y: FileDto) {
    if (x.name !== y.name ||
            !TermDtoUtils.equalArrays(x.termDtos, y.termDtos)) {
      return false;
    }
    return true;
  }
}

export class FileDtoForGenericPost {
  hashId?: string = undefined;
  file?: File | null = null;

  constructor(fileDto?: Partial<FileDto>) {
    Object.assign(this, fileDto);
  };
}
