import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { accountService } from '../api-authorization/services';

export function PrivatePublicRouteWithLayout({ unauthComponent: UnauthComponent, unauthLayout: UnauthLayout, authComponent: AuthComponent, authLayout: AuthLayout, roles, user, ...rest }) {

    return (
        <Route {...rest} render={props => {
            const user = accountService.userValue;
            console.log({user});

            if (user === 0 || user === null) {
                // not logged in so redirect to login page with the return url
                return (
                    <UnauthLayout>
                        <UnauthComponent {...props} />
                    </UnauthLayout>
                );
            }

            // check if route is restricted by role
            if (user !== 0 && roles && roles.indexOf(user.role) === -1) {
                // role not authorized so redirect to home page
                return <Redirect to={{ pathname: '/'}} />
            }

            // authorized so return component
            if (user) {
                return <AuthLayout><AuthComponent {...props}/></AuthLayout>
            }
        }} />
    );


}