import Typography from "@mui/material/Typography";
import {Box, Card, Grid} from "@mui/material";
import React from "react";
import {ICellRendererParams} from "ag-grid-community";
import TermDto from "../../../includes/models/TermDto";
import {TermGroupingsBlock} from "../Term-Grouping-Components/TermGroupingsBlock";

interface IPostRowProps extends ICellRendererParams {
  data: TermDto,
}


const TermRow = (props : IPostRowProps) => {
  const {
    data: termDto,
  } = props

  return (
    <Box sx={{
      m: 1
    }}>
      <Typography variant="h6" component="h2">{termDto.name}</Typography>
      <Typography variant="subtitle2">{termDto.slug}</Typography>
      {termDto.description &&
        <Typography variant="body1">
          {termDto.description}
        </Typography>
      }
      {termDto.termGroupingDtos &&
        <TermGroupingsBlock
            termGroupingDtos={termDto.termGroupingDtos}
            readOnly
            size="small"
        />
      }
    </Box>
  )
}

export default TermRow;