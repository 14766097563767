const withFile = editor => {
    const { isVoid } = editor

    editor.isVoid = element => {
        return element.type === 'file' ? true : isVoid(element)
    }

    return editor
}

export default {
    withFile
};