import React, {useState} from 'react';
import TermDto from '../includes/models/TermDto';
import {postTermDto} from '../includes/api-functions/TermDtoCalls';
import TermMaterialTable from "./reusable-components/MaterialTable-Components/TermMaterialTable";
import AddEditTerm from "./reusable-components/Term-Components/AddEditTerm";

const Terms = () => {
  const [termDto, setTermDto] = useState<TermDto | undefined>(new TermDto());
  const [refreshTerms, setRefreshTerms] = useState<boolean>(true);

  const handleError = (e: Response) => {
    console.log(e.status);
  };

  const onCreateTerm = async () => {
    setRefreshTerms(false);

    if (termDto === undefined) {
      return;
    }

    await postTermDto(termDto, setTermDto, handleError).then(async () => {
      setRefreshTerms(true);
    });
  };

  return (
        <TermMaterialTable
          pageTitle="Tags"
          refreshTerms={refreshTerms}
        >
          <AddEditTerm
            label="Add Tag"
            termDto={termDto}
            onButtonClick={onCreateTerm}
            setTermDto={setTermDto}
          />
        </TermMaterialTable>
  );
}

export default Terms;
