import React, {useState, useEffect, useCallback} from 'react';
import {PageSearchProps, PostMaterialTable} from './reusable-components/MaterialTable-Components/PostMaterialTable';
import {useHistory, useParams} from 'react-router-dom';
import {getTermDto, putTermDto} from '../includes/api-functions/TermDtoCalls';
import TermDto from '../includes/models/TermDto';
import CenteredSpinner from "./reusable-components/CenteredSpinner/CenteredSpinner";
import AddEditTerm from "./reusable-components/Term-Components/AddEditTerm";

const Term = () => {
  const {
    termSlug
  } = useParams();

  const [termDto, setTermDto] = useState<TermDto | undefined>();
  const [pageTerm, setPageTerm] = useState<string>('');
  const [pageSearchProps, setPageSearchProps] = useState<PageSearchProps | null>(null);
  const history = useHistory();

  const termDtoCallback = (termDto: TermDto) => {
    setTermDto(termDto);
    setPageTerm(termDto.name);
  };

  const handleError = (e: Response) => {
    console.log(e.status);
  };

  useEffect(() => {
    getTermDto(termSlug, termDtoCallback, handleError).then(() => {
      setPageSearchProps(({
        termSlugs: [termSlug]
      }));
    });
  }, [termSlug, setPageSearchProps]);

  const refreshToNewUrl = useCallback((termSlug: string) =>
      history.push(`/tags/${termSlug}`),
    [history],
  );

  if (termDto === undefined) {
    return <CenteredSpinner />
  }

  const onTermUpdated = async () => {
    await putTermDto(termDto, handleError).then(() => {
      setPageTerm(termDto.name);
      refreshToNewUrl(termDto.slug);
    });
  };

  const pageTitle = `Tag: ${pageTerm}`;

  return (
    <PostMaterialTable pageSearchProps={pageSearchProps} pageTitle={pageTitle}>
      <AddEditTerm
        label="Edit Tag"
        termDto={termDto}
        setTermDto={setTermDto}
        onButtonClick={onTermUpdated}
      />
    </PostMaterialTable>
  );
};

export default Term;
