import SlateJokeBlockComponent from "./SlateJokeComponents/SlateJokeBlockComponent";
import SlateSingleLinePluginComponent from "./SingleLinePlugin/SlateSingleLinePluginComponent";
import BoldLeafRendererComponent
    from "../../../rob-horse/reusable-components/Slate-Editors/Custom-Components/CoreStyles/BoldLeafRendererComponent";
import ItalicLeafRendererComponent
    from "../../../rob-horse/reusable-components/Slate-Editors/Custom-Components/CoreStyles/ItalicLeafRendererComponent";
import UnderlineLeafRendererComponent
    from "../../../rob-horse/reusable-components/Slate-Editors/Custom-Components/CoreStyles/UnderlineLeafRendererComponent";
import SlateJokeEditorBlockComponent from "./SlateJokeComponents/SlateJokeEditorBlockComponent";

const SingleJokeComponents = [
    BoldLeafRendererComponent,
    ItalicLeafRendererComponent,
    UnderlineLeafRendererComponent,
    SlateJokeEditorBlockComponent,
    SlateJokeBlockComponent,
    SlateSingleLinePluginComponent
];

export default SingleJokeComponents;