import React, {useState} from 'react';
import {useFormik} from 'formik';
import * as Yup from 'yup';
import { accountService, alertService } from '../includes/api-authorization/services';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import {Paper, TextField} from "@mui/material";
import {LoadingButton} from "@mui/lab";


const Login = ({ history, location }) => {

    const [submitting, setSubmitting] = useState(false);

    const validationSchema = Yup.object().shape({
        email: Yup.string()
            .email('Email is invalid')
            .required('Email is required'),
        password: Yup.string().required('Password is required')
    });

    const formik = useFormik({
        initialValues: {
            email: '',
            password: ''
        },
        onSubmit: ({ email, password }) => {
            setSubmitting(true);
            alertService.clear();
            accountService.login(email, password)
                .then(() => {
                    const { from } = location.state || { from: { pathname: "/" } };
                    history.push(from);
                })
                .catch(error => {
                    setSubmitting(false);
                    alertService.error(error);
                });
        },
        validationSchema: validationSchema
    });

    return (
        <Grid 
            container 
            direction="row"
            justifyContent="center"
            alignItems="center"
            sx={{
                flexGrow: 1,
                padding: 3
            }}
        >
            <Grid item xs={12} sm={6} md={4} lg={3}>
                <form onSubmit={formik.handleSubmit}>
                    <Paper
                        sx={{
                            padding: 2
                        }}>
                        <Typography
                            variant="h4"
                            component="h1"
                            sx={{
                                marginBottom: 2
                            }}
                        >
                            Login
                        </Typography>
                        <TextField
                            sx={{
                                marginBottom: 2
                            }}
                            fullWidth
                            variant="outlined"
                            name="email"
                            type="text"
                            label="Email"
                            onChange={formik.handleChange}
                            value={formik.values.email}
                        />
                        <TextField
                            sx={{
                                marginBottom: 2
                            }}
                            fullWidth
                            variant="outlined"
                            name="password"
                            type="password"
                            label="Password"
                            onChange={formik.handleChange}
                            value={formik.values.password}
                        />
                        <LoadingButton
                            disabled={submitting}
                            loading={submitting}
                            variant="contained"
                            type="submit"
                        >
                            Login
                        </LoadingButton>
                    </Paper>
                </form>
            </Grid>
        </Grid>
    );
}

export { Login };