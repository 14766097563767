import PostDto from "../../../includes/models/PostDto";
import FileDto from "../../../includes/models/FileDto";
import SetlistDto from "./SetlistDto";

export default class JokePerformanceDto {
    rating: number = 0;
    performanceHashId: string = "";

    constructor(setlistDto?: Partial<JokePerformanceDto>) {
        Object.assign(this, setlistDto);
    };
}