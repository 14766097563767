import ListTransforms from "./src/ListTransforms";
import ListPlugins from "./src/ListPlugins";
import UnorderedListBlock from "./src/UnorderedListBlock";
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";

const SlateUnorderedListComponent = {
    author: 'Rob Reinhard',
    block: UnorderedListBlock,
    decorators: undefined,
    default: undefined,
    dependencyTypes: undefined,
    leafRenderers: undefined,
    name: 'Ordered List',
    onDrop: false,
    onKeyDown: undefined,
    plugins: [ListPlugins.withListPlugin],
    toolbar: {
        icon: FormatListBulletedIcon,
        isActive: undefined,
        onClick: (editor) => ListTransforms.insertList(editor, false)
    },
    transforms: ListTransforms,
    type: 'unordered-list'
};

export default SlateUnorderedListComponent;