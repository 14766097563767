import React, {useState, useEffect} from 'react';
import {PostMaterialTable} from './reusable-components/MaterialTable-Components/PostMaterialTable';

import {getContentTypeBySlug} from '../includes/api-functions/ContentTypeCalls';
import {ContentTypeDto} from '../includes/models/ContentTypeDto';

export const ContentType = (props: any) => {
  const contentTypeSlug = props.match.params.contentTypeSlug;

  const [contentType, setContentType] = useState<ContentTypeDto>();

  const handleError = (e: Response) => {
    console.log(e.status);
  };

  useEffect(() => {
    getContentTypeBySlug(contentTypeSlug, setContentType, handleError);
  }, [contentTypeSlug]);

  const pageSearchProps = {
    contentTypeSlugs: [contentTypeSlug]
  };

  return (
    <PostMaterialTable pageSearchProps={pageSearchProps} pageTitle={`${contentType?.name}`} />
  );
};
