import {Node, Text} from "slate";
import Prism from "prismjs";
import 'prismjs/components/prism-aspnet';
import 'prismjs/components/prism-sql';
import 'prismjs/components/prism-markup-templating.js';
import 'prismjs/components/prism-php';
import 'prismjs/components/prism-jsx';
import 'prismjs/components/prism-tsx';
import 'prismjs/components/prism-docker';
import 'prismjs/components/prism-graphql';
import 'prismjs/components/prism-json';
import 'prismjs/components/prism-makefile';
import 'prismjs/components/prism-typescript';
import 'prismjs/components/prism-bash';

const getLength = token => {
    if (typeof token === 'string') {
        return token.length
    } else if (typeof token.content === 'string') {
        return token.content.length
    } else {
        return token.content.reduce((l, t) => l + getLength(t), 0)
    }
}

const prismDecorator = (editor, [node, path]) => {
    let parent, grandparent;
    try {
        parent = Node.parent(editor, path);

        if (parent.type !== 'code-line') {
            return [];
        }

        const grandparentPath = path.slice(0,-1);

        grandparent = Node.parent(editor, grandparentPath);

        if (grandparent.type !== 'code-block') {
            return [];
        }

        const ranges = []
        if (!Text.isText(node)) {
            return ranges
        }

        const tokens = Prism.tokenize(node.text, Prism.languages[grandparent.language])
        let start = 0

        for (const token of tokens) {
            const length = getLength(token)
            const end = start + length

            if (typeof token !== 'string') {
                ranges.push({
                    prismCSS: [token.type],
                    anchor: {path, offset: start},
                    focus: {path, offset: end},
                })
            }

            start = end
        }

        return ranges;
    }
    catch {
        return []
    }
};

export default {
    prismDecorator
};