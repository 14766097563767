import {disableStyle, getActiveStyles, toggleStyle} from "./MarkUtils";
import React from "react";
import CodeLeafRenderer from "./CodeLeafRenderer";
import {Code} from "@mui/icons-material";

const codeLeafRendererType = 'code';

const CodeLeafRendererComponent = {
    author: 'Rob Reinhard',
    block: undefined,
    decorators: undefined,
    default: undefined,
    dependencyTypes: undefined,
    leafRenderers: undefined,
    leafRenderer: CodeLeafRenderer,
    name: 'Code Leaf Renderer',
    onDrop: false,
    onKeyDown: undefined,
    plugins: undefined,
    resetToDefault: undefined,
    toolbar: {
        icon: Code,
        isActive: (editor) => {
            return getActiveStyles(editor).has(codeLeafRendererType);
        },
        disabled: (editor, slateComponents) => {
            return disableStyle(editor, slateComponents, codeLeafRendererType);
        },
        onClick: (editor, event) => {
            event.preventDefault();
            toggleStyle(editor, codeLeafRendererType);
        }
    },
    transforms: undefined,
    type: codeLeafRendererType
};

export default CodeLeafRendererComponent;