import React, {useEffect, useState} from 'react';
import {Box, Paper, Typography} from '@mui/material';
import styled from "@emotion/styled";
import userProfileDtoCalls from "../includes/api-functions/UserProfileDtoCalls";
import UserProfileDto from "../includes/models/UserProfileDto";
import UserProfileViewer from "./reusable-components/User-Profile/UserProfileViewer";
import CenteredSpinner from "./reusable-components/CenteredSpinner/CenteredSpinner";
import * as fileDtoCalls from "../includes/api-functions/FileDtoCalls";

interface IProfilePageProps {
    match: {
        params: {
            username: string
        }
    }
};

const ProfilePage = (props: IProfilePageProps) => {
  const username = props.match.params.username;

  const [userProfileDto, setUserProfileDto] = useState<UserProfileDto | null>(null);
  const [errorMessage, setErrorMessage] = useState<boolean>(false);

  const errorMessageHandler = (error: Response) => {
      setErrorMessage(true);
  }

  useEffect(() => {
      userProfileDtoCalls.getUserProfileByUsername(username, setUserProfileDto, errorMessageHandler)
  }, [username]);

    const {
        coverPhotoDto,
        profilePhotoDto
    } = userProfileDto || {};

    useEffect(() => {
        if (!coverPhotoDto || !coverPhotoDto.hashId || coverPhotoDto.file) {
            return;
        }

        fileDtoCalls.getFileDtosFileByHashId(coverPhotoDto.hashId, onCoverPhotoFileChange, errorMessageHandler)
    }, [coverPhotoDto]);

    useEffect(() => {
        if (!profilePhotoDto || !profilePhotoDto.hashId || profilePhotoDto.file) {
            return;
        }

        fileDtoCalls.getFileDtosFileByHashId(profilePhotoDto.hashId, onProfilePhotoFileChange, errorMessageHandler)
    }, [profilePhotoDto]);

  if (!userProfileDto) {
      return <CenteredSpinner />
  }

    const onCoverPhotoFileChange = (newFile: any) => {
        setUserProfileDto(prevState => {
            if (prevState === null || prevState?.coverPhotoDto === null) {
                return prevState;
            }

            return ({
                ...prevState,
                coverPhotoDto: {
                    ...prevState?.coverPhotoDto,
                    file: newFile
                }
            })
        });
    };

    const onProfilePhotoFileChange = (newFile: any) => {
        setUserProfileDto(prevState => {
            if (prevState === null || prevState?.profilePhotoDto === null) {
                return prevState;
            }

            return ({
                ...prevState,
                profilePhotoDto: {
                    ...prevState?.profilePhotoDto,
                    file: newFile
                }
            })
        });
    };

  return (
      <Box
          sx={{
              position: 'relative',
          }}
      >
          <UserProfileViewer
              readOnly
              userProfileDto={userProfileDto}
          />
      </Box>
  );
};

export default ProfilePage;
