import React, {useState, useEffect, MouseEvent, useMemo} from 'react';
import {createEditor, Transforms} from 'slate';
import {withReact} from 'slate-react';
import {withHistory} from 'slate-history';
import JokeBlockTransforms from '../slate-js/SlateJokeComponents/src/JokeBlockTransforms';
import ViewPostBase from '../../../rob-horse/reusable-components/Add-Edit-View-Default-Bases/ViewPostBase';
import SetlistSlateComponents from '../slate-js/SetlistSlateComponents';
import {getSetlistDtoByHashId, getSetlistRevisionDtosByHashId} from '../../includes/api-functions/SetlistDtoCalls';
import SetlistDto from '../../includes/models/SetlistDto';
import StagingSetlistDto from '../../includes/models/StagingSetlistDto';
import SetlistWriter from '../slate-js/SetlistWriter';
import setlistService from './setlistService';
import useSlateEditor from "../../../rob-horse/reusable-components/Slate-Editors/useSlateEditorState";

interface IViewBitProps {
    hashId: string;
}

export function ViewSetlist(props: IViewBitProps) {
  const {hashId} = props;
  const [setlistDto, setSetlistDto] = React.useState<SetlistDto>(new SetlistDto());
  const [setlistDtoRevisions, setSetlistDtoRevisions] = useState<SetlistDto[]>([]);
  const [revisionIndex, setRevisionIndex] = useState<number>(0);
  const [slateContent, setSlateContent] = useState<any>([JokeBlockTransforms.defaultJokeEditorBlock()]);

  const [editor] = useSlateEditor(SetlistSlateComponents);

  useEffect(() => {
    getSetlistDtoByHashId(hashId, onSuccessfulGetPostHashIdCallback, handleError).then(async () => {
      await getSetlistRevisionDtosByHashId(hashId, onSuccessfulGetPostRevisionsCallback, handleError);
    });
  }, []);

  const onSuccessfulGetPostHashIdCallback = (stagingSetlistDto: StagingSetlistDto) => {
    const setlistDto = setlistService.convertStagingSetlistDtoToSetlistDto(stagingSetlistDto);

    setSetlistDto(setlistDto);
  };

  const onSuccessfulGetPostRevisionsCallback = (revisions: StagingSetlistDto[]) => {
    const processedRevisions = revisions;

    const setlistDtoRevisions: SetlistDto[] = [];

    for (const revision of processedRevisions) {
      const setlistDto = setlistService.convertStagingSetlistDtoToSetlistDto(revision);

      setlistDtoRevisions.push(setlistDto);
    }

    setSetlistDtoRevisions(setlistDtoRevisions);
    setRevisionIndex(processedRevisions.length-1);
  };

  const handleError = (e: Response) => {
    console.log(e.status);
  };

  const onContentChange = (newEditorStateValue: any) => {
    setSlateContent(newEditorStateValue);
  };

  const onPreviousClicked = (event: MouseEvent) => {
    if (revisionIndex !== 0) {
      const index = revisionIndex - 1;
      setRevisionIndex(index);
      Transforms.deselect(editor);
      setSetlistDto(setlistDtoRevisions[index]);
    }
  };

  const onNextClicked = (event: MouseEvent) => {
    if (revisionIndex !== setlistDtoRevisions.length-1) {
      const index = revisionIndex + 1;
      setRevisionIndex(index);
      Transforms.deselect(editor);
      setSetlistDto(setlistDtoRevisions[index]);
    }
  };

  useEffect(() => {
    const content = setlistService.createSetlistContent(setlistDto);

    setSlateContent(content);
  }, [setlistDto]);

  return (
    <ViewPostBase
      hashId={hashId}
      revisionData={{
        onNextClicked: onNextClicked,
        onPreviousClicked: onPreviousClicked,
        postDtoRevisions: setlistDtoRevisions,
        revisionIndex: revisionIndex,
      }}
      postDto={setlistDto}
      setPostDto={setSetlistDto}
    >
      <SetlistWriter
        content={slateContent}
        editor={editor}
        onContentChange={onContentChange}
        readOnly={true}
        slateComponents={SetlistSlateComponents}
      />
    </ViewPostBase>
  );
}
