import React from 'react';
import AppBar from '@mui/material/AppBar';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import Hidden from '@mui/material/Hidden';
import IconButton from '@mui/material/IconButton';
import TagIcon from '@mui/icons-material/Tag';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import DynamicFeedIcon from '@mui/icons-material/DynamicFeed';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Grid from '@mui/material/Grid';
import CategoryIcon from '@mui/icons-material/Category';
import FolderIcon from '@mui/icons-material/Folder';
import PostAddIcon from '@mui/icons-material/PostAdd';
import MenuIcon from '@mui/icons-material/Menu';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import {Link} from 'react-router-dom';
import {useTheme, Theme} from '@mui/material/styles';
import HomeIcon from '@mui/icons-material/Home';
import {LoginMenuButton} from './LoginMenuButton';
import HorseLogo from './assets/favicon-32x32.png';
import {Box} from '@mui/material';
import NoLinkDecoration from './reusable-components/Base-Utility-Components/NoLinkDecoration';
import ModalErrorBoundary from "./reusable-components/Error/ModalErrorBoundary";

const drawerWidth = 240;

interface Props {
    /**
     * Injected by the documentation to work in an iframe.
     * You won't need it on your project.
     */
    window?: () => Window;
    children?: React.ReactNode
}

const DefaultNavMenu = (props: Props) => {
  const {window} = props;
  const theme = useTheme();

  const [mobileOpen, setMobileOpen] = React.useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const drawer = (
    <div>
      <div>
        <List>
          <ListItem button component={Link} to="/">
            <ListItemIcon><HomeIcon /></ListItemIcon>
            <ListItemText primary='Home' />
          </ListItem>
        </List>
      </div>
      <Divider />
      <List>
        <ListItem button component={Link} to="/posts">
          <ListItemIcon><DynamicFeedIcon /></ListItemIcon>
          <ListItemText primary='Posts' />
        </ListItem>
        <ListItem button component={Link} to="/add">
          <ListItemIcon><PostAddIcon /></ListItemIcon>
          <ListItemText primary='Add Post' />
        </ListItem>
        <ListItem button component={Link} to="/groupings">
          <ListItemIcon><FolderIcon /></ListItemIcon>
          <ListItemText primary='Groupings' />
        </ListItem>
        <ListItem button component={Link} to="/tags">
          <ListItemIcon><TagIcon /></ListItemIcon>
          <ListItemText primary='Tags' />
        </ListItem>
        <ListItem button component={Link} to="/content-types">
          <ListItemIcon><CategoryIcon /></ListItemIcon>
          <ListItemText primary='Content Types' />
        </ListItem>
      </List>
    </div>
  );

  const container = window !== undefined ? () => window().document.body : undefined;

  return (
    <Box sx={{display: 'flex'}}>
      <CssBaseline />
      <AppBar
        position="fixed"
        sx={{
          width: {sm: `calc(100% - ${drawerWidth}px)`},
          ml: {sm: `${drawerWidth}px`},
        }}
      >
        <Toolbar>
          <Grid
            justifyContent="space-between"
            container
            direction="row"
            spacing={10}
            alignItems="center"
          >
            <Grid item>
              <Grid
                container
                direction="row"
                alignItems="center"
              >
                <Grid item>
                  <IconButton
                    color="inherit"
                    aria-label="open drawer"
                    edge="start"
                    onClick={handleDrawerToggle}
                    size="large"
                    sx={{mr: 2, display: {sm: 'none'}}}
                  >
                    <MenuIcon />
                  </IconButton>
                </Grid>
                <Grid
                  item
                  sx={{
                    padding: 0,
                  }}
                >
                  <NoLinkDecoration to="/">
                    <Grid container
                      direction="row"
                      alignItems="baseline"
                    >
                      <Grid
                        item
                      >
                        <Typography
                          variant="h5"
                          component="p"
                          noWrap
                          sx={{
                            fontSize: '1.75rem',
                          }}
                        >
                                                    Rob&nbsp;
                        </Typography>
                      </Grid>
                      <Grid item>
                        <img src={HorseLogo}
                          alt="Horse"
                          style={{verticalAlign: 'text-bottom'}}
                        />
                      </Grid>
                    </Grid>
                  </NoLinkDecoration>
                </Grid>
              </Grid>
            </Grid>
            <Grid item>
              <LoginMenuButton />
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
      <Box
        component="nav"
        sx={{width: {sm: drawerWidth}, flexShrink: {sm: 0}}}
        aria-label="mailbox folders"
      >
        <Hidden mdUp implementation="css">
          <Drawer
            container={container}
            variant="temporary"
            anchor={theme.direction === 'rtl' ? 'right' : 'left'}
            open={mobileOpen}
            onClose={handleDrawerToggle}
            sx={{
              'display': {xs: 'block', sm: 'none'},
              '& .MuiDrawer-paper': {boxSizing: 'border-box', width: drawerWidth},
            }}
            ModalProps={{
              keepMounted: true,
            }}
          >
            {drawer}
          </Drawer>
        </Hidden>
        <Hidden smDown implementation="css">
          <Drawer
            sx={{
              'display': {xs: 'none', sm: 'block'},
              '& .MuiDrawer-paper': {boxSizing: 'border-box', width: drawerWidth},
            }}
            variant="permanent"
            open
          >
            {drawer}
          </Drawer>
        </Hidden>
      </Box>
      <Box
        sx={{flexGrow: 1, p: 3, width: {sm: `calc(100% - ${drawerWidth}px)`}}}
      >
        <Toolbar
          sx={{
            zIndex: 2
          }}
        />
        <ModalErrorBoundary>
          {props.children}
        </ModalErrorBoundary>
      </Box>
    </Box>
  );
};

export default DefaultNavMenu;
