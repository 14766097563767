import withSingleLine from "./SingleLinePlugin";
import BoldLeafRendererComponent
    from "../../../../rob-horse/reusable-components/Slate-Editors/Custom-Components/CoreStyles/BoldLeafRendererComponent";
import ItalicLeafRendererComponent
    from "../../../../rob-horse/reusable-components/Slate-Editors/Custom-Components/CoreStyles/ItalicLeafRendererComponent";
import UnderlineLeafRendererComponent
    from "../../../../rob-horse/reusable-components/Slate-Editors/Custom-Components/CoreStyles/UnderlineLeafRendererComponent";

const SlateSingleLinePluginComponent = {
    author: 'Rob Reinhard',
    block: undefined,
    decorators: undefined,
    default: true,
    defaultResetHandler: undefined,
    dependencyTypes: undefined,
    leafRenderers: [BoldLeafRendererComponent.type, ItalicLeafRendererComponent.type, UnderlineLeafRendererComponent.type],
    name: 'Joke Block',
    onDrop: undefined,
    onKeyDown: undefined,
    plugins: [withSingleLine],
    resetToDefault: undefined,
    toolbar: undefined,
    transforms: undefined
};

export default SlateSingleLinePluginComponent;