import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import AccountMenuPopup from "./AccountMenuPopup";
import Button from '@mui/material/Button';
import { accountService } from '../includes/api-authorization/services';

export class LoginMenuButton extends Component {

    constructor(props) {
        super(props);

        this.state = {
            isAuthenticated: false,
            userName: ""
        };
    }

    componentWillMount() {
        this.populateState();
    }
    async populateState() {
        this.setState({
            isAuthenticated: (accountService.userValue),
            userName: accountService.userValue && accountService.userValue.firstName
        });
    }

    render() {
        console.log(accountService.userValue);
        const { userName } = this.state;

        if (!accountService.userValue) {
            const registerPath = '/register';
            const loginPath = '/login';
            return this.anonymousView(registerPath, loginPath);
        } else {
            const profilePath = `/@${userName}/Settings`;
            return this.authenticatedView(userName, profilePath);
        }
    }

    authenticatedView(userName, profilePath) {
        return (
            <AccountMenuPopup accountName={accountService.userValue.firstName} profilePath={profilePath} />
        );
    }

    anonymousView(registerPath, loginPath) {
        return (
            <Button 
                variant="outlined"
                component={Link}
                to={loginPath}
                color="inherit"
                style={{borderColor: 'white', color: 'white'}}
            >
                Login
            </Button>
        );
    }
}