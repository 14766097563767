import GenericPostDto from '../models/GenericPostDto';
import { fetchWrapper } from "../api-authorization/helpers";
import { cloneDeep } from 'lodash';
import genericPostService from "../services/genericPostService";
import SlateJSON from "../../rob-horse/reusable-components/Slate-Editors/Custom-Components/SlateJSON";

export const postGenericPostDto = async (genericPostDto: GenericPostDto, onSuccessToEditCallback: (genericPost: GenericPostDto) => void, onErrorCallback: (error: Response) => void) => {
    let postToPublish = cloneDeep(genericPostDto);

    postToPublish = await genericPostService.postGenericPostEmbeddedPosts(postToPublish);

    postToPublish.legacyJSONSerializedSlateContent = SlateJSON.stringify(postToPublish.slateContent);

    await fetchWrapper.post('api/genericpost', postToPublish, SlateJSON.stringify).then(onSuccessToEditCallback,onErrorCallback);
};

export const getGenericPostDtoByHashId = async (hashId: string, onSuccessCallback: (genericPostDto: GenericPostDto) => void, onErrorCallback: (error: Response) => void) => {
    await fetchWrapper.get('api/genericpost/'+hashId).then(onSuccessCallback,onErrorCallback);
}

export const getGenericPostRevisionDtosByHashId = async (hashId: string, onSuccessCallback: (value: GenericPostDto[]) => void, onErrorCallback: (error: Response) => void) => {
    await fetchWrapper.get('api/genericpost/revisions/'+hashId).then(onSuccessCallback,onErrorCallback);
}