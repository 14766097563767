import React from 'react'
import Typography from "@mui/material/Typography";
import {useReadOnly} from "slate-react";
import styled from "@emotion/styled";

const EmptyLine = styled.div`
  margin-bottom: 24px;
`;

const ParagraphBlock = (props) => {
    const {
        element: {
            children
        }
    } = props;

    const readOnly = useReadOnly();

    if (readOnly && children.length === 1 && children[0].text === '') {
        return <EmptyLine></EmptyLine>
    }

    return <Typography {...props.attributes}>{props.children}</Typography>
};

export default ParagraphBlock;

