import React from "react";
import {ContentTypeDto} from "../models/ContentTypeDto";
import {fetchWrapper} from "../api-authorization/helpers";

export const getContentTypes = async (onSuccessSetData: (value: React.SetStateAction<ContentTypeDto[]>) => void, onErrorCallback: (error: Response) => void) => {
    await fetchWrapper.get('api/contentType').then(onSuccessSetData,onErrorCallback);
}

export const getContentTypeBySlug = async (slug: string, onSuccessSetData: (value: React.SetStateAction<ContentTypeDto | undefined>) => void, onErrorCallback: (error: Response) => void) => {
    await fetchWrapper.get(`api/contentType/${slug}`).then(onSuccessSetData,onErrorCallback);
}