import React, {useEffect, useState} from 'react';
import DownloadIcon from '@mui/icons-material/Download';
import {Box, Button, Typography} from '@mui/material';
import DownloadButton from './DownloadButton';
import {SxProps} from "@mui/system";

interface IDefaultFileViewer {
  file: File,
  initialUpload?: boolean,
  sx?: SxProps
}

const DefaultFileViewer = (props: IDefaultFileViewer) => {
  const {
    file,
    initialUpload,
    sx
  } = props;

  const [base64encoding, setBase64encoding] = useState<string>('');

  const onDownload = () => {
    const link = document.createElement('a');
    link.download = file.name;
    link.href = base64encoding;
    link.click();
  };

  useEffect(() => {
    const reader = new FileReader();

    reader.addEventListener('load', async () => {
      if (typeof reader.result === 'string') {
        setBase64encoding(reader.result);
      }
    }, false);

    reader.readAsDataURL(file);
  }, [file]);

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        height: '400px',
        ...sx
      }}
    >
      <Typography
        sx={{
          marginBottom: 2,
        }}
      >
                File type not yet supported for native viewing
      </Typography>
      {!initialUpload &&
                <DownloadButton
                  name={file.name}
                  href={base64encoding}
                />
      }
    </Box>
  );
};

export default DefaultFileViewer;
