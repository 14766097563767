import React, {useState, useEffect, MouseEvent} from 'react';
import {Transforms} from "slate";
import BitWriter from "../slate-js/BitWriter";
import BitSlateComponents from "../slate-js/BitSlateComponents";
import {getBitDtoByHashId, getBitRevisionDtosByHashId} from "../../includes/api-functions/BitDtoCalls";
import BitDto from "../../includes/models/BitDto";
import JokeBlockTransforms from "../slate-js/SlateJokeComponents/src/JokeBlockTransforms";
import ViewPostBase from "../../../rob-horse/reusable-components/Add-Edit-View-Default-Bases/ViewPostBase";
import useSlateEditor from "../../../rob-horse/reusable-components/Slate-Editors/useSlateEditorState";

interface IViewBitProps {
    hashId: string;
}

export function ViewBit(props: IViewBitProps) {

    const { hashId } = props;
    const [bitDto, setBitDto] = React.useState<BitDto>(new BitDto());
    const [bitDtoRevisions, setBitDtoRevisions] = useState<BitDto[]>([]);
    const [revisionIndex, setRevisionIndex] = useState<number>(0);
    const [slateContent, setSlateContent] = useState<any>({
        type: "bit-editor",
        children: [JokeBlockTransforms.defaultJokeEditorBlock()]
    });

    const [editor] = useSlateEditor(BitSlateComponents);

    useEffect(() => {
        getBitDtoByHashId(hashId, onSuccessfulGetPostHashIdCallback, handleError).then(async () => {
            await getBitRevisionDtosByHashId(hashId, onSuccessfulGetPostRevisionsCallback, handleError);
        });
    }, []);

    const onSuccessfulGetPostHashIdCallback = (bitDto: BitDto) => {
        const processedBitDto = bitDto;

        if (processedBitDto.hashId) {
            processedBitDto.hashedParent = hashId;
            processedBitDto.hashId = "";
        }

        const content = {
            type: "bit-editor",
            children: processedBitDto.jokes.map((joke) => joke.slateContent)
        };

        setBitDto(new BitDto(processedBitDto));
        setSlateContent(content);
    }

    const onSuccessfulGetPostRevisionsCallback = (revisions: BitDto[]) => {
        const processedRevisions = revisions;

        for (let revision of processedRevisions) {
            revision = new BitDto(revision);
        }

        setBitDtoRevisions(processedRevisions);
        setRevisionIndex(processedRevisions.length-1);
    }

    const handleError = (e: Response) => {
        console.log(e.status);
    }

    const handleChange = (newEditorStateValue: any) => {
        setBitDto(prevState => ({
            ...prevState,
            content: newEditorStateValue
        }));
    };

    const onContentChange = (newEditorStateValue: any) => {
        setSlateContent(newEditorStateValue);
    };

    const onPreviousClicked = (event: MouseEvent) => {
        if (revisionIndex !== 0) {
            const index = revisionIndex - 1;
            setRevisionIndex(index);
            Transforms.deselect(editor)
            onContentChange(bitDtoRevisions[index].jokes.map((joke) => joke.slateContent));
        }
    }

    const onNextClicked = (event: MouseEvent) => {
        if (revisionIndex !== bitDtoRevisions.length-1) {
            const index = revisionIndex + 1;
            setRevisionIndex(index);
            Transforms.deselect(editor)
            onContentChange(bitDtoRevisions[index].jokes.map((joke) => joke.slateContent));
        }
    }

    return (
        <ViewPostBase
            hashId={hashId}
            revisionData={{
                onNextClicked:onNextClicked,
                onPreviousClicked:onPreviousClicked,
                postDtoRevisions:bitDtoRevisions,
                revisionIndex:revisionIndex
            }}
            postDto={bitDto}
            setPostDto={setBitDto}
        >
                <BitWriter
                    content={slateContent}
                    editor={editor}
                    onContentChange={onContentChange}
                    readOnly={true}
                    slateComponents={BitSlateComponents}
                />
        </ViewPostBase>
    );


}
