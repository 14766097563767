import React from 'react';
import MaterialDropzone from '../Material-Drag-And-Drop/MaterialDropzone';
import {DropzoneProps} from 'react-dropzone';
import AudioViewer from './AudioViewer';
import CenteredSpinner from '../CenteredSpinner/CenteredSpinner';
import ImageViewer from './ImageViewer';
import PDFViewer from './PDFViewer';
import DefaultFileViewer from './DefaultFileViewer';
import {SxProps} from "@mui/system";
import {Box, Fab} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";

interface IFileUploaderViewerProps extends DropzoneProps {
    file?: File | null,
    onDropCallback: (value: React.SetStateAction<any>) => void,
    placeholder?: string
    initialUpload?: boolean
    readOnly?: boolean
    sx?: SxProps
    hideDeleteIcon?: boolean
    onDeleteCallback?: () => void
}


const FileUploaderViewer = (props: IFileUploaderViewerProps) => {
  let component;

  const {
    hideDeleteIcon,
    onDeleteCallback
  } = props;

  if (props.file === null && !props.readOnly) {
    component = (
      <MaterialDropzone
        {...props}
      />
    );
  } else if (!props.file || !props.file.type) {
    return (
      <CenteredSpinner />
    );
  } else if (props.file.type.match('image.*')) {
    component = <ImageViewer
      file={props.file}
      hideDownloadButton={props.initialUpload}
      sx={props.sx}
    />;
  } else if (props.file.type.match('audio.*')) {
    component = <AudioViewer
      file={props.file}
      initialUpload={props.initialUpload}
      sx={props.sx}
    />;
  } else if (props.file.type.match('.pdf*')) {
    component = <PDFViewer
      file={props.file}
      initialUpload={props.initialUpload}
      sx={props.sx}
    />;
  } else {
    component = <DefaultFileViewer
      file={props.file}
      initialUpload={props.initialUpload}
      sx={props.sx}
    />;
  }

  return (
    <Box
      sx={{
        position: "relative",
      }}
    >
      {!hideDeleteIcon &&
      <Fab
          size="small"
          sx={{
            position: "absolute",
            right: "-20px",
            top: "-20px",
            zIndex: 2
          }}
          onClick={onDeleteCallback}
      >
          <DeleteIcon />
      </Fab>
      }
      {component}
    </Box>
  )
};

export default FileUploaderViewer;
