import React, {useState, useEffect, useCallback} from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import {useHistory} from 'react-router-dom';
import {ContentTypeDto} from '../includes/models/ContentTypeDto';
import {getContentTypes} from "../includes/api-functions/ContentTypeCalls";
import CenteredSpinner from "./reusable-components/CenteredSpinner/CenteredSpinner";
import ContentTypeCard from "./reusable-components/Cards/ContentTypeCard";

export const Add = () => {
  const [contentTypes, setContentTypes] = useState<ContentTypeDto[]>([]);

  const history = useHistory();

  useEffect(() => {
    getContentTypes(setContentTypes, handleError);
  }, []);

  const handleError = (e: Response) => {
    console.log(e.status);
  };

  const handleClick = useCallback((slug: string) =>
    history.push('/add/' + slug),
  [history],
  );

  if (!contentTypes) {
    return <CenteredSpinner />;
  }

  return (
    <Grid
      container
      spacing={3}
      direction="row"
      justifyContent="center"
      alignItems="center"
    >
      <Grid item xs={12}>
        <Typography variant="h4" component="h1" align="center">
          Select Content Type
        </Typography>
      </Grid>
      <Grid item xs={12} sm={12} md={6}>
        <Grid
          container
          spacing={3}
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
        >
          {contentTypes.map((contentType: any) => {
            return <Grid item xs={12} sm={12} md={6} key={contentType.slug}>
                <ContentTypeCard
                  name={contentType.name}
                  slug={contentType.slug}
                  onClick={handleClick}
                />
            </Grid>;
          })}
        </Grid>
      </Grid>
    </Grid>
  );
}
