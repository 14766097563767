import React, {useState, useRef, useEffect} from "react";
import Typography from "@mui/material/Typography";
import {Box, Fab, IconButton, Slider} from "@mui/material";
import Replay10Icon from "@mui/icons-material/Replay10";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import PauseIcon from "@mui/icons-material/Pause";
import Forward10Icon from "@mui/icons-material/Forward10";
import Paper from "@mui/material/Paper";
import useInterval from "../../../includes/api-authorization/helpers/useInterval";
import Peaks from 'peaks.js';
import useTheme from "@mui/material/styles/useTheme";
import {format} from 'date-fns';

const AudioPlayer = (props) => {
    const {
        audioContext,
        audioElement
    } = props;

    const [playing, setPlaying] = useState();
    const [currentTime, setCurrentTime] = useState(0);
    const overviewWaveformRef = useRef();
    const [peaks, setPeaks] = useState();
    const theme = useTheme();

    useEffect(() => {
        if (audioElement) {
            initPeaks();
        }
    }, [audioElement, audioContext, theme]);

    useInterval(() => {
        if (audioElement) {
            setCurrentTime(audioElement.currentTime / audioElement.duration * 100);
        }
    }, 500);

    const togglePlayPause = () => {
        if (audioContext.state === 'suspended') {
            audioContext.resume();
        }

        if (audioElement) {
            playing ? audioElement.pause() : audioElement.play().then((resolve, reject) => {
                console.log({resolve});
                console.log({reject});
            });
        }

        setPlaying(!playing);
    }

    const rewindTenSeconds = () => {
        let rewindTime = audioElement.currentTime - 10;
        if (rewindTime < 0) {
            rewindTime = 0;
        }

        audioElement.currentTime = rewindTime;
        setCurrentTime(audioElement.currentTime);
    };

    const forwardTenSeconds = () => {
        let forwardTime = audioElement.currentTime + 10;
        if (forwardTime > audioElement.duration) {
            forwardTime = audioElement.duration;
        }

        audioElement.currentTime = forwardTime;
        setCurrentTime(audioElement.currentTime);
    }

    const onSliderChange = (event: any) => {
        if (audioElement) {
            audioElement.currentTime = (event.target.value / 100) * audioElement.duration;
            setCurrentTime(audioElement.currentTime);
        }
    }

    const initPeaks = () => {
        const options = {
            overview: {
                playheadColor: 'rgba(0, 0, 0, 0)',
                axisGridlineColor: 'rgba(0, 0, 0, 0)',
                axisLabelColor: 'rgba(0, 0, 0, 0)',
            },
            containers: {
                overview: overviewWaveformRef.current,
            },
            keyboard: true,
            mediaElement: audioElement,
            waveformColor: `${theme.palette.secondary.light}`,
            webAudio: {
                audioContext: audioContext,
                scale: 128,
                multiChannel: false
            }
        };

        Peaks.init(options, (err, peaks) => {
            if (peaks) {
                setPeaks(peaks);
            }
        });
    }

    const sliderProgress = audioElement?.currentTime / audioElement?.duration * 100;
    let currentTimeText = '00:00', durationText = '00:00';

    if (audioElement?.duration) {
        currentTimeText= format(audioElement.currentTime * 1000, 'mm:ss');
        durationText = format(audioElement.duration * 1000, 'mm:ss');
    }

    return (
        <Paper
            sx={{
                minHeight: "300px",
                borderWidth: "1px",
                borderStyle: "solid",
                borderColor: 'divider',
                boxShadow: 0,
                position: "relative",
                overflow: "hidden"
            }}
        >
            <Box
                className="overview-container"
                ref={overviewWaveformRef}
                sx={{
                    height: '200px',
                    position: "relative",
                    '.konvajs-content': {
                        width: 'auto !important'
                    },
                    pointerEvents: 'none'
                }}
            ></Box>
            <Box
                sx={{
                    position: "absolute",
                    bottom: 0,
                    left: 0,
                    right: 0,
                    overflow: "hidden"
                }}
            >
                <Box
                    sx={{
                        width: "100%",
                        display:"flex",
                        alignItems:"center",
                        paddingLeft: 3,
                        paddingRight: 3
                    }}
                >
                    <IconButton
                        sx={{
                            marginLeft: "auto",
                            marginBottom: 2
                        }}
                        onClick={rewindTenSeconds}
                        size="small"
                    >
                        <Replay10Icon />
                    </IconButton>
                    <Fab
                        size="small"
                        color="primary"
                        sx={{
                            marginLeft: 2,
                            marginRight: 2,
                            marginBottom: 2
                        }}
                        onClick={togglePlayPause}
                    >
                        {!playing &&
                        <PlayArrowIcon />
                        }
                        {playing &&
                        <PauseIcon />
                        }
                    </Fab>
                    <IconButton
                        sx={{
                            marginRight: "auto",
                            marginBottom: 2
                        }}
                        onClick={forwardTenSeconds}
                        size="small"
                    >
                        <Forward10Icon />
                    </IconButton>
                </Box>
                <Box
                    sx={{
                        marginLeft: 2,
                        marginRight: 2,
                        display:"flex",
                        alignItems:"center"
                    }}
                >
                    <Typography
                        sx={{
                            display: 'inline-block',
                            marginRight: 3
                        }}
                    >
                        {currentTimeText}
                    </Typography>
                    <Slider
                        onChange={onSliderChange}
                        value={sliderProgress}
                    />
                    <Typography
                        sx={{
                            display: 'inline-block',
                            marginLeft: 3
                        }}
                    >
                        {durationText}
                    </Typography>
                </Box>
            </Box>
        </Paper>
    )
};

export default AudioPlayer;