import SetlistDto from '../models/SetlistDto';
import { fetchWrapper } from "../../../includes/api-authorization/helpers";
import { cloneDeep } from 'lodash';
import SetlistJokeDto from "../models/SetlistJokeDto";
import SetlistBitDto from "../models/SetlistBitDto";
import StagingSetlistDto from "../models/StagingSetlistDto";
import setlistService from "../../theme/Setlist/setlistService";

export const postSetlistDto = async (setlistDto: SetlistDto, onSuccessToEditCallback: (setlistDto: SetlistDto) => void, onErrorCallback: (error: Response) => void) => {
    const postToPublish = setlistService.convertSetlistDtoToStagingSetlistDto(setlistDto);

    await fetchWrapper.post('api/comedy/setlist', postToPublish).then(onSuccessToEditCallback,onErrorCallback);
};

export const getSetlistDtoByHashId = async (hashId: string, onSuccessCallback: (setlistDto: StagingSetlistDto) => void, onErrorCallback: (error: Response) => void) => {
    await fetchWrapper.get('api/comedy/setlist/'+hashId).then(onSuccessCallback,onErrorCallback);
}

export const getSetlistRevisionDtosByHashId = async (hashId: string, onSuccessCallback: (value: StagingSetlistDto[]) => void, onErrorCallback: (error: Response) => void) => {
    await fetchWrapper.get('api/comedy/setlist/revisions/'+hashId).then(onSuccessCallback,onErrorCallback);
}