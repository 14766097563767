import React, {ChangeEvent} from 'react';
import {Box, TextField} from "@mui/material";
import {SxProps} from "@mui/system";

interface PerformanceAdditionalPropertiesProps {
  sx?: SxProps,
  handleChange: (event: ChangeEvent) => void,
  location: string,
  comments: string
}

const PerformanceAdditionalProperties = (props: PerformanceAdditionalPropertiesProps) => {
  const {
    sx,
    handleChange,
    location,
    comments
  } = props;

  return (
    <Box
      sx={sx}
    >
      <TextField
        fullWidth
        name="location"
        label="Location"
        onChange={handleChange}
        sx={{
          mb: 2
        }}
        value={location}
      />
      <TextField
        fullWidth
        name="comments"
        label="Comments"
        onChange={handleChange}
        multiline
        rows={4}
        value={comments}
      />
    </Box>
  )
}

export default PerformanceAdditionalProperties;