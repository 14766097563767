import {useState} from "react";
import {createEditor} from "slate";
import {withReact} from "slate-react";
import {withHistory} from "slate-history";

const useSlateEditor = (SlateComponents) => useState(() => {
    let editor = createEditor();

    let plugins = [];
    SlateComponents
        .filter((slateComponent) => slateComponent.plugins)
        .forEach((slateComponent) => {
            plugins = plugins.concat(slateComponent.plugins);
        });

    for (const plugin of plugins) {
        editor = plugin(editor);
    }

    // @ts-ignore
    return withReact(withHistory(editor));
});

export default useSlateEditor;