import GenericPostDto from "../models/GenericPostDto";
import FileDto from "../models/FileDto";
import {postFileDto} from "../api-functions/FileDtoCalls";

const postGenericPostEmbeddedPosts = async (genericPostDto: GenericPostDto) => {
  const postEmbeddedPostsPromises = [];

  for (const [index, genericPostContent] of genericPostDto.slateContent.children.entries()) {
    if (genericPostContent.type === 'file') {
      const file = new FileDto({
        name: `File in ${genericPostDto.name}`,
        file: genericPostContent.fileDto.file,
        dependent: true
      });

      if (!file.hashId) {
        postEmbeddedPostsPromises.push(postFileDto(file, (stagingFile) => {
          genericPostContent.hashId = stagingFile.hashId;
          delete genericPostContent.fileDto;
        }));
      }
    }
  }

  await Promise.all(postEmbeddedPostsPromises);

  return genericPostDto;
}

export default {
  postGenericPostEmbeddedPosts
};