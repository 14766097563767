import React from 'react';
import InputAdornment from '@mui/material/InputAdornment';
import {IconButton, Typography} from '@mui/material';
import KeyboardReturnIcon from '@mui/icons-material/KeyboardReturn';
import {TermGroupingsBlock} from "./TermGroupingsBlock";
import TermGroupingDto from "../../../includes/models/TermGroupingDto";
import TermGroupingSearch from "../Websocket-Components/TermGroupingSearch";

interface TermGroupingSidebarProps {
    preventTermGroupingEdit?: boolean,
    readOnly?: boolean,
    onAddTermGrouping: (termGroupingDto: TermGroupingDto) => void,
    onTermDeleted?: (termGroupingDto: TermGroupingDto) => void,
    termGroupingDtos: TermGroupingDto[],
}

export const TermGroupingSidebar = (props: TermGroupingSidebarProps) => {
  const {
    preventTermGroupingEdit,
    readOnly,
    termGroupingDtos,
    onAddTermGrouping,
    onTermDeleted
  } = props;

  return (
    <div key="placeholder-key">
      {!readOnly && !preventTermGroupingEdit &&
        <TermGroupingSearch
          onAddTermGrouping={onAddTermGrouping}
          searchLabel="Add Term Grouping"
          textFieldProps={{
            InputProps: {
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    size="large">
                    <KeyboardReturnIcon/>
                  </IconButton>
                </InputAdornment>),
            }
          }}
        />
      }
      {preventTermGroupingEdit &&
        <Typography>Term Grouping: </Typography>
      }
      <TermGroupingsBlock
        readOnly={readOnly || preventTermGroupingEdit}
        onTermDeleted={onTermDeleted}
        termGroupingDtos={termGroupingDtos}
        sx={{
          width: '100%',
          marginTop: '10px',
          marginBottom: '10px',
          minHeight: !readOnly && !preventTermGroupingEdit ? '50px' : 'auto'
        }}
      />
    </div>
  );
};

export default TermGroupingSidebar;
