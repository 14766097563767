import React from 'react'
import {Box} from "@mui/material";

const JokeEditorBlock = (props) => {
    return (
        <Box
            {...props.attributes}
        >
            {props.children}
        </Box>
    )
};

export default JokeEditorBlock;
