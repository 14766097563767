import SetlistLaughDto from "./SetlistLaughDto";
import BitDto from "./BitDto";

export default class SetlistBitDto extends SetlistLaughDto {
    bitDto: BitDto = new BitDto();

    constructor(setlistDto?: Partial<SetlistBitDto>) {
        super();
        Object.assign(this, setlistDto);
    };
}